import React, { useState } from 'react';
import styles from './_styles/WordFilterOption.module.scss';
import arrowBtn from '../../../../public/Images/icon-chevron-right.svg';
import checkImg from '../../../../public/Images/FCcheck.svg';
import useViewport from '../../../customhooks/useViewport';

interface Props {
    setParam: any;
    value: string;
    quantity?: number;
    filterParam: string;
    blockedWords?: boolean;
    topStyle?: boolean;
    bottomStyle?: boolean;
    modalStyle?: boolean;
}

const WordFilterOption = (props: Props): JSX.Element => {
    const {
        setParam,
        value,
        quantity,
        filterParam,
        topStyle,
        bottomStyle,
        blockedWords = false,
        modalStyle = false,
    } = props;

    const { viewportWidth, mediaQuery } = useViewport();
    const [showBlocked, setShowBlocked] = useState<boolean>(false);

    const activeFilter = (filter) => {
        return filterParam == filter
            ? styles.activeOption
            : styles.unactiveOption;
    };

    const topList = topStyle && styles.listTop;
    const bottomList = bottomStyle && styles.listBottom;

    return (
        <>
            {viewportWidth > mediaQuery.mobile &&
                (!blockedWords ? (
                    <option
                        onClick={setParam}
                        className={
                            modalStyle
                                ? styles.option +
                                  ' ' +
                                  styles.modalStyle +
                                  ' ' +
                                  activeFilter(value)
                                : styles.option + ' ' + activeFilter(value)
                        }
                        value={value}
                    >
                        {value} {quantity && `(${quantity})`}
                    </option>
                ) : (
                    <div
                        className={
                            !showBlocked
                                ? styles.blockedClosed
                                : styles.blockedOpen + ' ' + activeFilter(value)
                        }
                    >
                        {showBlocked ? (
                            <option
                                onClick={setParam}
                                className={
                                    styles.option + ' ' + activeFilter(value)
                                }
                                value={value}
                            >
                                {value}
                            </option>
                        ) : (
                            ''
                        )}
                        <div
                            className={styles.blockedImg}
                            onClick={() => setShowBlocked(!showBlocked)}
                        >
                            <img
                                src={arrowBtn}
                                className={showBlocked && styles.blockedImgOpen}
                            />
                        </div>
                    </div>
                ))}
            {viewportWidth <= mediaQuery.mobile && (
                <div
                    className={
                        styles.listOption + ' ' + topList + ' ' + bottomList
                    }
                    onClick={() => setParam(value)}
                >
                    {filterParam === value && <img src={checkImg} />}
                    <p>
                        {value} {quantity && `(${quantity})`}
                    </p>
                </div>
            )}
        </>
    );
};

export default WordFilterOption;
