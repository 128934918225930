import React, { useState, useRef, useEffect, useContext } from 'react';
import UserContext from '../../../context/UserContext';
import useOutsideClick from '../../../customhooks/onOutsideClick';
import AudioInterface from './AudioInterface';
import BlurButton from '../../../../public/Images/AudioIcons/eye-crossed.svg';
import ForwardBtutton from '../../../../public/Images/AudioIcons/btn-forward.svg';
import PauseButton from '../../../../public/Images/AudioIcons/btn-pause.svg';
import PlayBtutton from '../../../../public/Images/AudioIcons/btn-play.svg';
import PreferencesIcon from '../../../../public/Images/AudioIcons/preferences.svg';
import ProgressBar from '../../UI-Components/ProgressBar';
import RewindButton from '../../../../public/Images/AudioIcons/btn-rewind.svg';
import SpeedSettingsModal from './SpeedSettingsModal';
import TranscriptImg from '../../../../public/Images/Transcript.svg';
import UnBlurButton from '../../../../public/Images/AudioIcons/eye-open.svg';
import WordSettingsModal from '../../GlobalComponents/WordSettingsModal';
import styles from './_styles/AudioBar.module.scss';

interface Props {
    audioPlayer: AudioInterface;
    audioControls: boolean;
    isBlurred: boolean;
    handleBlur?: CallableFunction;
    handlePlay: CallableFunction;
    handleOpenTranscript?: CallableFunction;
    progressBarValue?: number;
    transcript?: boolean;
}

/**
 *
 * @fileOverview
 * The Audio bar for lesson mode. It recieves the audio player interface to control lesson playback
 *
 * @param {Props} props
 * - audioPlayer: Audiointerface for controlling playback
 * - audioControls: boolean for displaying forward and backwards controls
 * - isBlurred: boolean for blurring words and switching the blurred button styling
 * - handleBlur: handles the logic for switching the words blurring on or off
 * - handlePlay: handles the start and stop playback
 * - handleOpenTranscript: handles the logic for opening and closing the transcript
 * - progressBarValue: number that displays the current time for the audio
 * - transcript: boolean for displaying the transcript button styling
 *
 * @return {JSX.Element} - Rendered audio bar component
 */
const AudioBar = ({ ...props }: Props): JSX.Element => {
    const {
        audioPlayer,
        audioControls,
        isBlurred,
        handleBlur,
        handlePlay,
        handleOpenTranscript,
        progressBarValue,
        transcript,
    } = props;
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
    const [isSpeedOpen, setIsSpeedOpen] = useState<boolean>(false);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const { userSpeed, setUserSpeed } = useContext(UserContext);
    const showRef = useRef(null);
    const audio = audioPlayer.audioElement;

    // Used for setting the users saved speed
    useEffect(() => {
        audioPlayer.setSpeed(userSpeed);

        return () => {
            showRef.current = null;
        };
    }, []);

    // Used for insuring the audio stops and the audio button is reset when completed
    useEffect(() => {
        if (audio.currentTime === audio.duration) {
            audio.pause();
            audio.currentTime = audio.duration;
        }
        if (audio.paused) {
            setIsPlaying(false);
        } else {
            setIsPlaying(true);
        }
    }, [audio.paused || audio.play() || audio.currentTime === audio.duration]);

    // used for closing the setting and speed modal
    useOutsideClick(showRef, () => {
        if (isSettingsOpen) setIsSettingsOpen(false);
        if (isSpeedOpen) setIsSpeedOpen(false);
    });

    // handles updating the audioplayback speed
    const updatePlaySpeed = (value) => {
        setUserSpeed(value);
        audioPlayer.setSpeed(value);
    };

    return (
        <section className={styles.audioControlContainer}>
            <div className={styles.progressBarValue}>
                <ProgressBar
                    height={10}
                    color={'#E98B2A'}
                    percentage={progressBarValue}
                />
            </div>

            <div className={styles.audioControls}>
                <div>
                    {/* BLUR BUTTON */}
                    {handleBlur && (
                        <AudioBarButton
                            image={isBlurred ? BlurButton : UnBlurButton}
                            style={styles.btn}
                            handleClick={handleBlur}
                        />
                    )}
                    {/* SPEED BUTTON */}
                    <div className={styles.speedControls}>
                        <AudioBarButton
                            handleClick={() => setIsSpeedOpen(!isSpeedOpen)}
                            Children={
                                <>
                                    <h3>{userSpeed}x</h3>
                                    <p>Speed</p>
                                </>
                            }
                        />
                        {/* Speed modal */}
                        {isSpeedOpen && (
                            <SpeedSettingsModal
                                audioPlayer={audioPlayer}
                                playSpeed={userSpeed}
                                updateSpeed={updatePlaySpeed}
                                toggleClose={() => setIsSpeedOpen(false)}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.controls}>
                    {/* REWIND BUTTON */}
                    {audioControls && (
                        <AudioBarButton
                            image={RewindButton}
                            style={styles.btn}
                            handleClick={() => audioPlayer.skip(-2)}
                        />
                    )}

                    {/* PLAY BUTTON */}
                    <AudioBarButton
                        image={!isPlaying ? PlayBtutton : PauseButton}
                        style={styles.playBtn}
                        handleClick={
                            !isPlaying
                                ? () => handlePlay()
                                : () => audioPlayer.pause()
                        }
                    />

                    {/* FORWARD BUTTON */}
                    {audioControls && (
                        <AudioBarButton
                            image={ForwardBtutton}
                            style={styles.btn}
                            handleClick={() => audioPlayer.skip(3)}
                        />
                    )}
                </div>

                {/* SETTINGS BUTTON */}
                <div className={styles.settings}>
                    {handleOpenTranscript && (
                        <AudioBarButton
                            image={TranscriptImg}
                            style={styles.btn}
                            imgStyle={transcript && styles.transcriptActive}
                            handleClick={handleOpenTranscript}
                        />
                    )}
                    <AudioBarButton
                        image={PreferencesIcon}
                        style={`${styles.btn} ${styles.preferences}`}
                        handleClick={() => setIsSettingsOpen(!isSettingsOpen)}
                    />
                    {isSettingsOpen && (
                        <WordSettingsModal
                            toggleClose={() => setIsSettingsOpen(false)}
                            right={0}
                            bottom={90}
                            fcMode={false}
                        />
                    )}
                </div>
            </div>
        </section>
    );
};
export default AudioBar;

interface ButtonProps {
    image?: string;
    style?: string;
    imgStyle?: string;
    handleClick: CallableFunction;
    Children?: any;
}

const AudioBarButton: React.FC<ButtonProps> = ({
    image,
    style,
    imgStyle,
    handleClick,
    Children,
}) => (
    <button
        className={`${styles.clickStyle} ${style}`}
        onClick={() => handleClick()}
    >
        <img className={imgStyle} src={image} />
        {Children}
    </button>
);
