import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import keyDownEvent from '../../../utils/KeyDownEvent';
import useAutoPlay from '../../../customhooks/useAutoPlay';
import useFunction from '../../../useFunction';
import useOutsideClick from '../../../customhooks/onOutsideClick';
import useViewport from '../../../customhooks/useViewport';
import AudioBar from './AudioBar';
import AudioInterface from './AudioInterface';
import ButtonColor from '../../UI-Components/ButtonColor';
import CloseButton from '../../../../public/Images/ClearButtonX.svg';
import LessonInterface from './LessonInterface';
import LessonResultsList from './LessonResultsList';
import MobileTopBar from '../../GlobalComponents/MobileTopBar';
import PageNavModal from '../../GlobalComponents/PageNavModal';
import PhraseBlock from './PhraseBlock';
import ResultsPage from '../../GlobalComponents/ResultsPage';
import WordCard from './WordCard';
import styles from './_styles/TestMode.module.scss';
import { Phrase } from '../../../types/api_response';

interface Props {
    lesson: LessonInterface;
    audioPlayer: AudioInterface;
}

interface UserAnswer {
    userAnswer: string;
    isCorrect: boolean;
}

/**
 * @fileoverview
 * Test mode component
 *
 * @param {Props} props
 * - lesson: This is the Lesson Interface that handles all the lesson data
 * - audioPlayer: handles the audio interface and playback
 *
 * @return {JSX.Element} - Rendered Test mode page component
 */
const TestMode = (props: Props): JSX.Element => {
    const { lesson, audioPlayer } = props;

    const { lessonUrlName, percentageBar } = useFunction();
    const [testResults, setTestResults] = useState<UserAnswer[]>([]);
    const [checkAnswer, setCheckAnswer] = useState<boolean>(false);
    const [phraseIndex, setPhraseIndex] = useState<number>(0);
    const [userInput, setUserInput] = useState<string>('');
    const [focused, setFocused] = useState<boolean>(false);
    const lessonName = lessonUrlName(lesson.name);
    const lessonId = useParams();
    const inputRef = useRef(null);
    const { viewportWidth, mediaQuery } = useViewport();

    // prevents phraseIndex from going out of bounds
    const inProgress = phraseIndex < lesson.phrases.length;

    const { isFinished } = useAutoPlay({
        playDependency: phraseIndex != lesson.phrases.length,
        playAudio: () => playAudio(),
        autoPlayVar: [phraseIndex],
        audioPlayer: audioPlayer,
        lessonFinish: testResults.length === lesson.phrases.length,
        lessonFinishVar: [testResults],
    });

    useOutsideClick(inputRef, () => {
        if (focused) setFocused(false);
    });

    /* Handle play audio */
    const playAudio = () => {
        const [startTime, endTime] = lesson.getPhrasePlayTime(phraseIndex);
        if (phraseIndex < lesson.phrases.length) {
            audioPlayer.playSection(startTime, endTime);
        }
    };

    /* Returns a list of the users answers for the results page */
    const findCorrect = (testResults: UserAnswer[]): UserAnswer[] => {
        const results: UserAnswer[] = [];
        testResults.forEach((value) => {
            if (value.isCorrect) {
                results.push(value);
            }
        });
        return results;
    };

    /* Handles the input for submitting answers */
    const answerHandler = (isCorrect: boolean) => {
        if (inProgress) {
            setPhraseIndex((p) => p + 1);
            setTestResults([
                ...testResults,
                {
                    userAnswer: userInput,
                    isCorrect: isCorrect && userInput !== '',
                },
            ]);
            setUserInput('');
            setCheckAnswer(false);
        }
    };

    /* Handles hot key presses */
    const { handleKeyPress } = keyDownEvent({
        upArrow: playAudio,
        leftArrow: () => !focused && answerHandler(false),
        rightArrow: () =>
            !focused &&
            (!checkAnswer ? setCheckAnswer(true) : answerHandler(true)),
    });

    const instructionMessage = (): string =>
        !checkAnswer
            ? 'Listen and write down the meaning below'
            : 'Did you answer correctly?';

    const currentPhrase: Phrase = lesson.phrases[phraseIndex];
    const buttonHeight: string = '56px';
    const progressBar: number = percentageBar(
        phraseIndex,
        lesson.phrases.length,
    );

    return (
        <>
            {!isFinished ? (
                <div
                    className={styles.pageGrid}
                    tabIndex={0}
                    onKeyDown={handleKeyPress}
                >
                    <MobileTopBar
                        lessonName={lesson.name}
                        studyData={lesson.studyData}
                        lessonId={lessonId.lessonId}
                        audioPlayer={audioPlayer}
                    />

                    {inProgress && (
                        <div className={styles.lessonContent}>
                            <div className={styles.phraseContainer}>
                                <div className={styles.instructionMessage}>
                                    <h2>{instructionMessage()}</h2>
                                    {/* Phrase Counter */}
                                    <h4>
                                        {phraseIndex + 1} /{' '}
                                        {lesson.phrases.length}
                                    </h4>
                                </div>
                                {/* Display the current phrase and words */}
                                <PhraseBlock
                                    playButton={true}
                                    togglePlayAudio={() => playAudio()}
                                >
                                    {currentPhrase.words.map((PhraseWord) => {
                                        return (
                                            <div
                                                key={PhraseWord.uuid}
                                                className={styles.wordCard}
                                            >
                                                <WordCard
                                                    PhraseWord={PhraseWord}
                                                    blurWords={!checkAnswer}
                                                    wordType={'test'}
                                                />
                                                {checkAnswer}
                                            </div>
                                        );
                                    })}
                                </PhraseBlock>
                                {/* Phrase translation */}
                                <div className={styles.transContainer}>
                                    <p className={styles.correctTag}>
                                        {checkAnswer && 'Answer'}
                                    </p>

                                    <p
                                        className={
                                            checkAnswer
                                                ? styles.transVisible
                                                : styles.transHidden
                                        }
                                    >
                                        {currentPhrase.translation}
                                    </p>
                                </div>
                            </div>

                            <div className={styles.controlsContainer}>
                                {/* User text input */}
                                {!checkAnswer ? (
                                    <>
                                        <textarea
                                            ref={inputRef}
                                            className={styles.inputDiv}
                                            placeholder="Enter translation"
                                            value={userInput}
                                            onClick={() => setFocused(true)}
                                            onChange={(e) => {
                                                setUserInput(e.target.value);
                                            }}
                                        />
                                        <img
                                            src={CloseButton}
                                            className={styles.closeButton}
                                            onClick={() => setUserInput('')}
                                        />
                                    </>
                                ) : (
                                    <div className={styles.userInput}>
                                        {/* after 'check', display user's answer below translation*/}
                                        <p>{userInput}</p>
                                    </div>
                                )}
                                {/*  Check your answer then submit if you got it correct */}
                                <div className={styles.testbuttons}>
                                    <ButtonColor
                                        color={'orange'}
                                        height={buttonHeight}
                                        onClick={() => {
                                            !checkAnswer
                                                ? setCheckAnswer(true)
                                                : answerHandler(true);
                                        }}
                                    >
                                        {!checkAnswer ? 'Check' : 'Correct'}
                                    </ButtonColor>
                                    <ButtonColor
                                        color={'orangeClear'}
                                        height={buttonHeight}
                                        onClick={() => {
                                            !checkAnswer
                                                ? answerHandler(false)
                                                : answerHandler(false);
                                        }}
                                    >
                                        {!checkAnswer ? 'Skip' : 'Incorrect'}
                                    </ButtonColor>
                                </div>
                            </div>
                        </div>
                    )}
                    <PageNavModal
                        openButton={true}
                        studyData={lesson.studyData}
                        lessonId={lessonId.lessonId}
                        lessonName={lessonName}
                    />

                    {viewportWidth > mediaQuery.mobile && (
                        <AudioBar
                            audioPlayer={audioPlayer}
                            audioControls={false}
                            isBlurred={false}
                            handlePlay={() => playAudio()}
                            progressBarValue={progressBar}
                        />
                    )}
                </div>
            ) : (
                <>
                    <ResultsPage
                        correctResults={findCorrect(testResults).length}
                        totalResults={lesson.phrases.length}
                        lessonId={lessonId.lessonId}
                        lessonName={lesson.name}
                        lessonType={'test'}
                        studyData={lesson.studyData}
                    >
                        <LessonResultsList
                            audioPlayer={audioPlayer}
                            testResults={testResults}
                            lesson={lesson}
                        />
                    </ResultsPage>
                </>
            )}
        </>
    );
};

export default TestMode;
