import * as React from 'react';
import { render } from 'react-dom';
import App from './components/App/App';
import { Auth0ProviderWithHistory } from './components/Auth/auth0-provider-with-history';

import './index.css';
// import to guarantee these files become available in /dist post build for pwa
import '../public/manifest.json';

const root = document.getElementById('root');

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/service-worker.js')
            .then((registration: ServiceWorkerRegistration) => {
                console.log(
                    'Service Worker registration successful with scope: ',
                    registration.scope,
                );
            })
            .catch((registrationError) => {
                console.log(
                    'Service Worker registration failed: ',
                    registrationError,
                );
            });
    });
}

render(
    <Auth0ProviderWithHistory>
        <App />
    </Auth0ProviderWithHistory>,
    root,
);

/* 
ONCE REACT 18 GETS THEIR SHIT TOGETHER, 
UPDATE THE ABOVE WITH THE FOLLOWING LINES OF CODE:

import * as React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';

import App from './components/App/App';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);

*/
