import React, { useState, useEffect } from 'react';
import styles from './_styles/FCPrefSelector.module.scss';
import { UserWord } from '../../../types/api_response';

interface OptionProps {
    name: string;
    style: string;
    value: number;
    handleChange: CallableFunction;
}

const PrefOption: React.FC<OptionProps> = ({
    name,
    style,
    value,
    handleChange,
}) => (
    <label className={`${styles.prefOption} ${style}`}>
        <h4>{name}</h4>
        <p>{value} words</p>
        <input
            type="radio"
            name="words"
            value={value}
            onChange={(e) => handleChange(e)}
        />
    </label>
);

interface Props {
    pref: number;
    setPref: CallableFunction;
    words: UserWord[];
}

const FCPrefSelector: React.FC<Props> = ({ pref, setPref, words }) => {
    const [firstRender, setFirstRender] = useState<boolean>(false);

    const total: number = words.length;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        setPref(value);
    };

    const activeStyle = (number: number) =>
        pref === number ? styles.activeSelect : styles.inactiveSelect;

    useEffect(() => {
        if (firstRender && words !== null) {
            if (total <= 5) {
                setPref(total);
            }
        }
        setFirstRender(true);
    }, [firstRender, setFirstRender, setPref, words]);

    const renderOptions = (value: number, name: string) => (
        <PrefOption
            name={name}
            value={value}
            handleChange={handleChange}
            style={activeStyle(value)}
        />
    );

    return (
        <div className={styles.options}>
            {words && (
                <>
                    {total >= 10
                        ? renderOptions(10, 'light')
                        : total < 10 && renderOptions(total, 'All')}
                    {total >= 20
                        ? renderOptions(20, 'Regular')
                        : total > 10 && renderOptions(total, 'All')}
                    {total >= 30
                        ? renderOptions(30, 'Fasttrack')
                        : total > 20 && renderOptions(total, 'All')}
                </>
            )}
        </div>
    );
};

export default FCPrefSelector;
