import React, { useState } from 'react';
import { UserWord, WordType, fcStudyData } from '../../../types/api_response';
import { useHistory } from 'react-router-dom';
import useViewport from '../../../customhooks/useViewport';
import useFunction from '../../../useFunction';
import useGetWords from '../../../customhooks/useGetWords';
import useSetUserWeek from '../../../customhooks/useSetUserWeek';
import ButtonColor from '../../UI-Components/ButtonColor';
import CloseButton from '../../../../public/Images/ClearButtonX.svg';
import FCPrefSelector from './FCPrefSelector';
import LessonDropdown from '../Dashboard/LessonDropdown';
import WordListModal from '../../GlobalComponents/WordList/WordListModal';
import styles from './_styles/FCPreferences.module.scss';

interface Props {
    /* userWordsFetched determines if the word hook will
    request words or use the already requested words  */
    userWordsFetched: boolean;
    weeklywordsFetched: boolean;
    /* Already fetched wordList */
    fetchedUserWords?: UserWord[];
    fetchedWeeklyWords?: UserWord[];
    toggleClose: () => void;
}

/**
 * @fileoverview
 * This is a modal for selecting flashcard study options. If words have already been fetched then it can recieve those words. When a flashcard study has finished or words have not been fetched the modal will fetch User due words and weekly vocab study words
 *
 * @param {Props} props
 * - userWordsFetched: if user words have been fetched we will use those instead of fetching again
 * - weeklywordsFetched: if user weekly words have been fetched we will use those instead of fetching again
 * - fetchedUserWords: An array of users due words if they have already been fetched
 * - fetchedWeeklyWords: An array of weekly words if they have already been fetched
 * - toggleClose: close the modal
 *
 * @dataOrigin
 * - the data can be passed in as props. If you know the data will not come from the current page you are on you can set userWordsFetched or weeklywordsFetched and that will fetch words for the user to select
 *
 * @return {JSX.Element} - Rendered FC preferences modal
 */
const FCPreferences = (props: Props): JSX.Element => {
    const {
        toggleClose,
        userWordsFetched,
        weeklywordsFetched,
        fetchedUserWords = [],
        fetchedWeeklyWords = [],
    } = props;

    /* This hook is used here for selecting and filtering weekly vocab words */
    const {
        selectedWeek,
        setSelectedWeek,
        selectedQuarter,
        setSelectedQuarter,
        quarterArr,
        weekArr,
    } = useSetUserWeek({ prefMode: true });

    const [preferences, setPreferences] = useState<number>(10);
    /* Toggle between user due words and weekly words */
    const [fcTypeToggle, setFcTypeToggle] = useState<boolean>(true);
    /* These two are for the dropdown menus for selecting the quarter/week */
    const [isQuarterOpen, setIsQuarterOpen] = useState<boolean>(false);
    const [isWeekOpen, setIsWeekOpen] = useState<boolean>(false);

    const [isWordListOpen, setIsWordListOpen] = useState<boolean>(false);
    const [wordTypeToggle, setWordTypeToggle] =
        useState<WordType['wordType']>('pinyin');

    const history = useHistory();
    const { checkDueWords, sortByDueDate } = useFunction();
    const { viewportWidth, mediaQuery } = useViewport();

    /* The hook for fetching user due words and weekly vocab IF userwords hasn't been fetched already */
    const { userWords, weeklyVocabList } = useGetWords({
        userWordsFetched: userWordsFetched,
        getWeeklyWords: true,
    });

    /* if weekly words have been passed as props used those words, else fetch words. Check for due words */
    const fcWeeklyWords = weeklywordsFetched
        ? checkDueWords(fetchedWeeklyWords, false)
        : checkDueWords(weeklyVocabList, false);

    /* if user words have been passed as props used those words, else fetch words. Check for due words */
    const fcUserWords = userWordsFetched
        ? checkDueWords(fetchedUserWords, true)
        : checkDueWords(userWords, true);

    const fcWords: UserWord[] = fcTypeToggle ? fcUserWords : fcWeeklyWords;
    const sortedWords: UserWord[] = sortByDueDate(fcWords, false, preferences);

    // object that gets sent through the link
    // const params = window.location.href;
    const fcStudyData: fcStudyData = {
        fcWordType: fcTypeToggle ? 'userwords' : 'weeklywords',
        fcWords: sortedWords,
    };

    /* Start flashcards session. Push the data through history to the flashcard component */
    const handleSubmit = () => {
        if (preferences === 0) {
            return alert('Please select an amount');
        } else {
            const newPath = '/Study';
            const newData = fcStudyData;
            history.push(newPath, newData);
        }
    };

    const buttonStyle = {
        height: '56px',
        width: viewportWidth <= mediaQuery.mobile ? '45%' : '200px',
    };

    return (
        <>
            <aside className={styles.backdrop} onClick={toggleClose}>
                {!isWordListOpen && (
                    <aside
                        className={styles.prefContainer}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <img
                            src={CloseButton}
                            className={styles.closeButton}
                            onClick={toggleClose}
                        />
                        <h3 className={styles.prefHeader}>
                            Select your words to review
                        </h3>
                        {/* Select between user words and weekly words */}
                        <div className={styles.prefTypeToggle}>
                            <p
                                className={fcTypeToggle && styles.activeSelect}
                                onClick={() => setFcTypeToggle(true)}
                            >
                                SRS Due Words
                            </p>
                            <p
                                className={!fcTypeToggle && styles.activeSelect}
                                onClick={() => setFcTypeToggle(false)}
                            >
                                Weekly Vocab
                            </p>
                        </div>

                        <div className={styles.prefBody}>
                            <div className={styles.prefBodyTop}>
                                {/* User due words srs explanations */}
                                {fcTypeToggle && (
                                    <p className={styles.prefMessage}>
                                        SRS optimizes memory by timing reviews
                                        to enhance long-term retention,
                                        promoting active recall, and revisiting
                                        content when it&apos;s on the verge of
                                        being forgotten.
                                    </p>
                                )}
                                {/* Quarter/week selection drop down menus for selecting what weekly word the user would like to study */}
                                {!fcTypeToggle && (
                                    <>
                                        <div className={styles.dropdownMenus}>
                                            <LessonDropdown
                                                prefMode={true}
                                                optionArr={quarterArr.slice(
                                                    0,
                                                    3,
                                                )}
                                                currentOption={selectedQuarter}
                                                updateOption={
                                                    setSelectedQuarter
                                                }
                                                isDropdownOpen={isQuarterOpen}
                                                setIsDropdownOpen={() => {
                                                    setIsQuarterOpen(
                                                        !isQuarterOpen,
                                                    );
                                                    setIsWeekOpen(false);
                                                }}
                                            />
                                            <LessonDropdown
                                                prefMode={true}
                                                optionArr={weekArr}
                                                currentOption={selectedWeek}
                                                updateOption={setSelectedWeek}
                                                isDropdownOpen={isWeekOpen}
                                                setIsDropdownOpen={() => {
                                                    setIsWeekOpen(!isWeekOpen);
                                                    setIsQuarterOpen(false);
                                                }}
                                            />
                                        </div>
                                        <div className={styles.wordList}>
                                            {fcWeeklyWords !== null ? (
                                                <>
                                                    <p>
                                                        {fcWeeklyWords.length}{' '}
                                                        Words
                                                    </p>
                                                    <ButtonColor
                                                        color={'orange'}
                                                        width="108px"
                                                        height="32px"
                                                        fontweight="500"
                                                        disable={
                                                            fcWeeklyWords.length <
                                                            1
                                                        }
                                                        onClick={() =>
                                                            setIsWordListOpen(
                                                                true,
                                                            )
                                                        }
                                                    >
                                                        See Words
                                                    </ButtonColor>
                                                </>
                                            ) : (
                                                <p>No Words</p>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            {/* Select the number of words to study */}
                            <FCPrefSelector
                                pref={preferences}
                                setPref={setPreferences}
                                words={
                                    fcTypeToggle ? fcUserWords : fcWeeklyWords
                                }
                            />
                            <ButtonColor
                                color={'orange'}
                                width={'100%'}
                                height={buttonStyle.height}
                                onClick={() => handleSubmit()}
                            >
                                Review now
                            </ButtonColor>
                        </div>
                    </aside>
                )}
            </aside>
            {/* Show the weekly word study list */}
            <WordListModal
                isOpen={!fcTypeToggle && isWordListOpen}
                userWords={fcWeeklyWords}
                toggleClose={() => setIsWordListOpen(false)}
                setWordType={setWordTypeToggle}
                wordType={wordTypeToggle}
            />
        </>
    );
};

export default FCPreferences;
