import React, { useState } from 'react';
import { LessonObj } from '../../../types/api_response';
import { useHistory } from 'react-router-dom';
import useFunction from '../../../useFunction';
import useFetch from '../../../useFetch';
import ButtonColor from '../../UI-Components/ButtonColor';
import EditForm from './EditForm';
import WarningModal from './WarningModal';
import styles from './_styles/InitializeLesson.module.scss';

/**
 * @fileoverview
 * Component for creating new lessons
 *
 * @return {JSX.Element} - Rendered create lesson page component
 *
 */
const CreateLesson = (): JSX.Element => {
    const originalObj: LessonObj = {
        name: '',
        description: '',
        tags: '',
        lessonImage: null,
        lessonAudio: null,
    };

    const history = useHistory();
    const [lessonData, setLessonData] = useState<LessonObj>(originalObj);
    const [status, setStatus] = useState<string>('idle');
    const [isSuccessOpen, setIsSuccessOpen] = useState<boolean>(false);
    const [isMissingFieldsOpen, setIsMissingFieldsOpen] =
        useState<boolean>(false);
    const { initializeLesson } = useFetch();
    const { addPipeToWords } = useFunction();

    const fieldMissing =
        lessonData.name === '' ||
        lessonData.description === '' ||
        lessonData.tags === '' ||
        lessonData.lessonImage === null ||
        lessonData.lessonAudio === null;

    const handleSubmit = (event) => {
        event.preventDefault();

        // Display a warning if required fields are missing
        if (fieldMissing) {
            setIsMissingFieldsOpen(true);
            return;
        }

        // Modify lesson data before submission
        setLessonData((prev) => ({
            ...prev,
            tags: addPipeToWords(lessonData.tags),
        }));

        // Set status to loading
        setStatus('loading');

        // Handle response status after initializing the lesson
        const responseStatus = (response) => {
            if (response.status === 'success') {
                // Display success modal and reset status
                setIsSuccessOpen(true);
                setStatus('idle');
            }
            if (response.status === 'fail') {
                // Handle failure and show an alert
                setStatus('idle');
                window.alert('Something went wrong');
            }
        };

        // Initialize lesson and check response status
        initializeLesson('/admin/lessons', lessonData).then((res) => {
            responseStatus(res);
        });
    };

    return (
        <>
            {/* Render the EditForm component with props */}
            <EditForm
                lessonObj={lessonData}
                setLessonObj={setLessonData}
                handleSubmit={handleSubmit}
            >
                {/* Buttons for canceling and submitting the lesson */}
                <div className={styles.buttons}>
                    <ButtonColor
                        width={'49%'}
                        height={'56px'}
                        color={'orangeClear'}
                        fontsize={'20px'}
                        disable={status === 'loading'}
                        // Link to edit-lesson page if fields are missing
                        link={fieldMissing ? '/admin/edit-lesson' : ''}
                        onClick={() => setLessonData(originalObj)}
                    >
                        Cancel
                    </ButtonColor>
                    <ButtonColor
                        width={'49%'}
                        height={'56px'}
                        color={'orange'}
                        fontsize={'20px'}
                        type={'submit'}
                        disable={status === 'loading'}
                    >
                        {/* Display 'Submitting' during loading, 'Submit' otherwise */}
                        {status === 'loading' ? 'Submitting' : 'Submit'}
                    </ButtonColor>
                </div>
            </EditForm>

            {/* Warning modals for missing fields and successful lesson creation */}
            <WarningModal
                show={isMissingFieldsOpen}
                messageType={'missingfields'}
                singleButton={true}
                toggleClose={() => {
                    setIsMissingFieldsOpen(false);
                }}
            />
            <WarningModal
                show={isSuccessOpen}
                messageType={'uploadsuccess'}
                singleButton={true}
                toggleClose={() => {}}
                // Continue to edit-lesson page on success
                toggleContinue={() => {
                    history.push('/admin/edit-lesson');
                }}
            />
        </>
    );
};

export default CreateLesson;
