/* eslint-disable require-jsdoc */
import React, { useState, useEffect } from 'react';
import { Word } from '../../../types/api_response';
import useFetch from '../../../useFetch';
import useSearch from '../../../customhooks/useSearch';
import useFunction from '../../../useFunction';
import AddTag from './AddTag';
import AlertMessage from '../../GlobalComponents/AlertMessage';
import ButtonColor from '../../UI-Components/ButtonColor';
import DeleteButton from '../../../../public/Images/trash.svg';
import FormInput from './FormInput';
import LoadingSpinner from '../../UI-Components/LoadingSpinner';
import SearchBar from '../../UI-Components/SearchBar';
import WarningModal from './WarningModal';
import styles from './_styles/EditWords.module.scss';

type DeleteModal = {
    isOpen: boolean;
    param: string;
};

/**
 *
 * @fileoverview
 * Component for displaying all words in database and editing them
 *
 * @return {JSX.Element} - Rendered word editor page component
 */
const EditWords = (): JSX.Element => {
    // Initial state for delete modal
    const deleteInitialObj: DeleteModal = {
        isOpen: false,
        param: '',
    };

    // State variables
    const [deleteWord, setDeleteWord] = useState<DeleteModal>(deleteInitialObj);
    const [allWords, setAllWords] = useState<Word[]>([]);
    const [words, setWords] = useState<Word[]>([]);
    const { getReq, deleteReq } = useFetch();
    const { searchWords, setSearchQuery, searchQueary } = useSearch({
        words: allWords,
    });

    // Fetching words on component mount
    useEffect(() => {
        getReq('/admin/words?missing=true&limit=100').then((res: any) => {
            if (res.status === 'success') {
                const words: Word[] = res.data;
                setWords(words);
            } else {
                console.log(res, 'fail');
            }
        });
        getReq('/admin/words').then((res: any) => {
            if (res.status === 'success') {
                setAllWords(res.data);
            }
        });
    }, []);

    // Function to add a word to the list
    const addWord = (word: Word) => {
        const currentWords = [...words];
        currentWords.unshift(word);
        setWords(currentWords);
    };

    // Function to delete a word
    const deleteWordFunk = (wordUUID: string) => {
        deleteReq(`/admin/words/${wordUUID}`).then((res: any) => {
            if (res.status === 'success') {
                console.log('Word Deleted Successfully');
            } else {
                alert(res.status);
            }
        });
        setWords((prev) => {
            return prev.filter((word) => {
                return word.uuid !== wordUUID;
            });
        });
    };

    // Render loading spinner if there are no words
    if (allWords.length == 0) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <div className={styles.wordEditorPage}>
                {/* Search input and results */}
                <div className={styles.searchInput}>
                    <SearchBar
                        value={searchQueary}
                        name={'word-search'}
                        placeholder={
                            'List of words separated by commas: 你，的，了'
                        }
                        handleChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                    />

                    {searchQueary !== '' && (
                        <div className={styles.searchResults}>
                            {searchWords(allWords).map((word, i) => {
                                if (i >= 100) {
                                    return;
                                } else {
                                    return (
                                        <div
                                            key={word.uuid}
                                            className={styles.wordListItem}
                                            onClick={() => {
                                                setSearchQuery('');
                                                addWord(word);
                                            }}
                                        >
                                            <p className={styles.kaiti}>
                                                {word.simplified}
                                            </p>
                                            <p className={styles.kaiti}>
                                                {word.traditional}
                                            </p>
                                            <p className={styles.pinyin}>
                                                {word.pinyin}
                                            </p>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    )}
                </div>

                {/* Word list */}
                <div className={styles.wordList}>
                    {words.length > 0 ? (
                        words.map((word, index) => {
                            if (word.simplified !== '') {
                                return (
                                    <WordListItem
                                        key={word.uuid}
                                        wordsData={words}
                                        word={word}
                                        wordIndex={index}
                                        setWords={setWords}
                                        deleteWord={() =>
                                            setDeleteWord((prev) => ({
                                                ...prev,
                                                isOpen: true,
                                                param: word.uuid,
                                            }))
                                        }
                                    />
                                );
                            }
                        })
                    ) : (
                        <AlertMessage header={'There are no new words'} />
                    )}
                </div>
            </div>
            {/* Delete modal */}
            <WarningModal
                messageType={'delete'}
                show={deleteWord.isOpen}
                toggleClose={() => setDeleteWord(deleteInitialObj)}
                toggleContinue={() => {
                    deleteWordFunk(deleteWord.param);
                    setDeleteWord(deleteInitialObj);
                }}
            />
        </>
    );
};

interface WordListItemProps {
    word: Word;
    wordIndex: number;
    wordsData: Word[];
    setWords: CallableFunction;
    deleteWord: CallableFunction;
}

const WordListItem = (props: WordListItemProps): JSX.Element => {
    // Destructuring props
    const { word, wordIndex, wordsData, setWords, deleteWord } = props;
    const { cleanTrans } = useFunction();
    const { putReq } = useFetch();

    // Function to get translations from the translation field
    const getTranslations = (translation): string[] => {
        if (translation !== undefined) {
            return cleanTrans(word.translation);
        }
        return [''];
    };

    // Extracting tags and translations
    const wordTags: string[] = cleanTrans(word.tags).join(' ').split(' ');
    const [tagsArr, setTagsArr] = useState<string[]>(
        wordTags[0] == '' ? ['EM', ''] : wordTags,
    );
    const [transArr, setTransArr] = useState<string[]>(
        getTranslations(word.translation),
    );

    // Function to format an array into a string
    const formatArray = (arr: string[]): string => {
        return arr.map((word) => `|${word}|`).join('');
    };

    // Handler for submitting changes to a word
    const submitHandler = (word: Word, index: number) => {
        const currentWords = [...wordsData];
        currentWords.splice(index, 1);

        // Checking if required fields are present
        if (
            word.traditional === undefined ||
            word.pinyin === undefined ||
            transArr[0] === ''
        ) {
            alert('A field is missing');
            return;
        }

        // Updating word fields
        word.translation = formatArray(transArr);
        word.tags = `|${tagsArr.join(' ')}|`;

        // Making a PUT request to update the word
        putReq(`/admin/words`, word).then((res: any) => {
            setWords(currentWords);
        });
    };

    return (
        <div key={word.uuid} className={styles.wordItem}>
            <div className={styles.wordItemFields}>
                <p className={styles.wordPreview}>{word.simplified}</p>
                {/* Form inputs for traditional, pinyin, translations, and tags */}
                <FormInput
                    id={'traditional'}
                    value={word.traditional}
                    placeHolder={'繁體'}
                    maxWidth={60}
                    // Updating traditional field
                    updateValue={(e) => {
                        setWords((words) => {
                            const w = [...words];
                            w[wordIndex].traditional = e.target.value;
                            return w;
                        });
                    }}
                />
                <FormInput
                    id={'pinyin'}
                    value={word.pinyin}
                    placeHolder={'Pinyin'}
                    maxWidth={120}
                    // Updating pinyin field
                    updateValue={(e) => {
                        setWords((words) => {
                            const w = [...words];
                            w[wordIndex].pinyin = e.target.value;
                            return w;
                        });
                    }}
                />

                <div className={styles.translations}>
                    {/* Component for adding and managing translations */}
                    <AddTag
                        tagsArr={transArr}
                        setTagsArr={setTransArr}
                        lessonTag={false}
                        label={false}
                        width={200}
                        maxTags={10}
                        placeHolder={'Translations'}
                    />
                </div>
                {/* Component for adding and managing tags */}
                <AddTag
                    tagsArr={tagsArr}
                    setTagsArr={setTagsArr}
                    label={false}
                    placeHolder={'Tags'}
                />
            </div>
            {/* Buttons for submitting changes and deleting the word */}
            <div className={styles.buttons}>
                {/* Submit button */}
                <ButtonColor
                    width={'86px'}
                    height={'32px'}
                    color={'orangeClear'}
                    onClick={() => submitHandler(word, wordIndex)}
                >
                    Submit
                </ButtonColor>
                {/* Delete button */}
                <img
                    className={styles.deleteButton}
                    src={DeleteButton}
                    alt="Delete Button"
                    onClick={() => deleteWord()}
                />
            </div>
        </div>
    );
};

export default EditWords;
