import React, { useState } from 'react';
import { Phrase } from '../../../types/api_response';
import useFunction from '../../../useFunction';
import AudioInterface from '../Lesson/AudioInterface';
import ButtonColor from '../../UI-Components/ButtonColor';
import PlayButton from '../../../../public/Images/AudioIcons/btn-play.svg';
import WarningModal from './WarningModal';
import styles from './_styles/PhraseReviewModal.module.scss';

interface Props {
    override: number;
    show: boolean;
    newWords?: any;
    phrases?: Phrase[];
    toggleClose: CallableFunction;
    toggleUpdate: CallableFunction;
    audioPlayer: AudioInterface | null;
}

// eslint-disable-next-line valid-jsdoc
/**
 *
 * @fileoverview
 * PhraseReviewModal component for displaying and reviewing phrases or new words before submitting
 *
 * @return {JSX.Element} - Rendered phrase review modal
 */
const PhraseReviewModal = (props: Props): JSX.Element => {
    const {
        override,
        show,
        toggleClose,
        toggleUpdate,
        audioPlayer,
        phrases,
        newWords,
    } = props;

    // State to track whether there are missing fields in phrases
    const [missingFields, setMissingFields] = useState<boolean>(false);

    // Custom hook for hanziPreview function
    const { hanziPreview } = useFunction();

    // Function to handle the submission of phrases
    const handleSubmit = phrases?.some(
        (phrase) =>
            phrase.translation === undefined || phrase.translation === '',
    )
        ? () => setMissingFields(true)
        : () => {
              toggleUpdate();
          };

    return (
        <>
            {/* Modal backdrop */}
            {show && (
                <aside
                    className={styles.backdrop}
                    onClick={() => toggleClose()}
                >
                    {/* Modal content */}
                    <aside
                        className={styles.previewModal}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Display phrases or new words based on the override value */}
                        {override === 0 ? (
                            <div>
                                <h2 className={styles.phraseHeader}>Phrases</h2>
                                {phrases.map((phrase, index) => {
                                    const firstWord = parseFloat(
                                        phrase.words[0].time,
                                    );
                                    const lastWord =
                                        phrase.words.length > 1
                                            ? parseFloat(
                                                  phrase.words[
                                                      phrase.words.length - 2
                                                  ].time,
                                              ) + 0.5
                                            : parseFloat(phrase.words[0].time) +
                                              0.5;
                                    return (
                                        <>
                                            <div className={styles.phraseLine}>
                                                {/* Phrase preview section */}
                                                <div
                                                    className={
                                                        styles.phrasePreview
                                                    }
                                                >
                                                    <p>Phase {index + 1}</p>
                                                    <div>
                                                        <img
                                                            src={PlayButton}
                                                            onClick={() =>
                                                                audioPlayer?.playSection(
                                                                    firstWord,
                                                                    lastWord,
                                                                )
                                                            }
                                                        />
                                                        <p>
                                                            {hanziPreview(
                                                                phrase,
                                                            )}
                                                        </p>
                                                    </div>
                                                </div>

                                                {/* Translation preview section */}
                                                <div
                                                    className={
                                                        styles.translationPreview
                                                    }
                                                >
                                                    <p>Translation</p>
                                                    <div>
                                                        <p>
                                                            {phrase.translation}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        ) : (
                            <div>
                                {/* Display new words section */}
                                <h2 className={styles.phraseHeader}>
                                    New words
                                </h2>
                                <p className={styles.message}>
                                    The following words do not exist in our
                                    words database <br /> Please verify these
                                    words are correct before continuing
                                </p>
                                <div className={styles.wordlist}>
                                    {newWords &&
                                        newWords.map((word) => {
                                            return (
                                                <>
                                                    <p>{word}</p>
                                                </>
                                            );
                                        })}
                                </div>
                            </div>
                        )}
                        {/* Buttons for returning to edit or submitting updates */}
                        <div className={styles.buttons}>
                            <ButtonColor
                                width={'49%'}
                                height={'56px'}
                                fontsize={'20px'}
                                color={'orangeClear'}
                                onClick={() => toggleClose()}
                            >
                                Return to edit
                            </ButtonColor>
                            <ButtonColor
                                height={'56px'}
                                width={'49%'}
                                color={'orange'}
                                fontsize={'20px'}
                                onClick={handleSubmit}
                            >
                                Submit Updates
                            </ButtonColor>
                        </div>
                    </aside>
                </aside>
            )}
            {/* WarningModal for handling missing fields */}
            <WarningModal
                show={missingFields}
                messageType={'missingfields'}
                singleButton={true}
                toggleClose={() => {
                    setMissingFields(false);
                }}
            />
        </>
    );
};

export default PhraseReviewModal;
