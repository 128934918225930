import React from 'react';
import styles from './_styles/FormInput.module.scss';

// Props interface defines the expected properties for the FormInput component
interface Props {
    id: string;
    label: string;
    value: string;
    maxWidth?: number;
    maxHeight?: number;
    maxLength?: number;
    updateValue: any;
    placeHolder?: string;
    changeText?: boolean;
    tags?: boolean;
    type?: 'string' | 'number';
}

/* normal | bold | orange | purple | teal */

// eslint-disable-next-line valid-jsdoc
/**
 *
 * @fileoverview
 * FormInput component for rendering input fields with various options
 *
 * @return {JSX.Element} - Rendered form input component
 */
const FormInput = (props: Props): JSX.Element => {
    const {
        id,
        label,
        value,
        maxLength,
        placeHolder,
        updateValue,
        changeText = false,
        maxHeight = 36,
        maxWidth,
        tags = false,
        type = 'string',
    } = props;

    // Function to toggle text types (e.g., bold, color, etc.) in a textarea
    const toggleTextType = (type) => {
        // Get the textarea element
        const textarea = document.getElementById(id) as HTMLTextAreaElement;
        const selectionStart = textarea.selectionStart;
        const selectionEnd = textarea.selectionEnd;

        if (selectionStart !== undefined && selectionEnd !== undefined) {
            // Create a new text based on the selected type
            let newText = value.substring(0, selectionStart);

            switch (type) {
                case 'bold':
                    newText += `[b]${value.substring(
                        selectionStart,
                        selectionEnd,
                    )}[/b]`;
                    break;
                case 'orange':
                    newText += `[o]${value.substring(
                        selectionStart,
                        selectionEnd,
                    )}[/o]`;
                    break;
                case 'purple':
                    newText += `[p]${value.substring(
                        selectionStart,
                        selectionEnd,
                    )}[/p]`;
                    break;
                case 'teal':
                    newText += `[t]${value.substring(
                        selectionStart,
                        selectionEnd,
                    )}[/t]`;
                    break;
                case 'newLine':
                    newText += `[/n]${value.substring(
                        selectionStart,
                        selectionEnd,
                    )}`;
                    break;
                // Add more cases as needed for other text types
                default:
                    break;
            }

            newText += value.substring(selectionEnd);

            // Call the updateValue function to update the value of the textarea
            updateValue({
                target: {
                    value: newText,
                },
            });
        }
    };

    // Define the size of the input field based on provided maxHeight and maxWidth
    const inputSize = {
        maxHeight: `${maxHeight}px`,
        minHeight: `${maxHeight}px`,
        maxWidth: `${maxWidth}px`,
        minWidth: `${maxWidth}px`,
    };

    return (
        <div className={`${styles.defaultStyle}`}>
            {/* Render label if tags are not enabled */}
            <label htmlFor={id}>{!tags && label}</label>

            {/* Render changeText options if changeText is true */}
            {changeText && (
                <div className={styles.changeText}>
                    {/* Options for bold, orange, purple, teal, and new line */}
                    <option
                        className={styles.boldText}
                        onClick={() => toggleTextType('bold')}
                    >
                        B
                    </option>
                    <option
                        className={styles.orangeText}
                        onClick={() => toggleTextType('orange')}
                    >
                        O
                    </option>
                    <option
                        className={styles.purpleText}
                        onClick={() => toggleTextType('purple')}
                    >
                        P
                    </option>
                    <option
                        className={styles.tealText}
                        onClick={() => toggleTextType('teal')}
                    >
                        T
                    </option>
                    <option onClick={() => toggleTextType('newLine')}>
                        New Line
                    </option>
                </div>
            )}

            {/* Render textarea or input based on the specified type */}
            {type === 'string' ? (
                <textarea
                    style={inputSize}
                    maxLength={maxLength}
                    className={styles.inputBox}
                    id={id}
                    name={id}
                    placeholder={placeHolder}
                    value={value}
                    onChange={updateValue}
                />
            ) : (
                <input
                    style={inputSize}
                    maxLength={maxLength}
                    className={styles.inputBox}
                    id={id}
                    name={id}
                    placeholder={placeHolder}
                    value={value}
                    onChange={updateValue}
                    type="number"
                />
            )}
        </div>
    );
};

export default FormInput;
