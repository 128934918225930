import React, { useState } from 'react';
import useGetWords from '../../../customhooks/useGetWords';
import ButtonColor from '../../UI-Components/ButtonColor';
import CheveronLeft from '../../../../public/Images/chevron-left-black.svg';
import FCPreferences from '../FlashCards/FCPreferences';
import LoadingSpinner from '../../UI-Components/LoadingSpinner';
import MobileHeader from '../../GlobalComponents/MobileHeader';
import Navbar from '../../BaseLayout/Navbar';
import WordStats from './WordStats';
import styles from './_styles/UserWordsPage.module.scss';

/**
 *
 * @fileoverview
 * Displays the users word list
 *
 * @return {JSX.Element} - Rendered User words page
 */
const UserWordsPage = () => {
    // State to manage whether to show the word list
    const [showList, setShowList] = useState<boolean>(false);

    // State to manage whether to toggle preferences
    const [togglePreferences, setTogglePreferences] = useState<boolean>(false);

    // Custom hook to fetch user words
    const { userWords } = useGetWords({ userWordsFetched: false });

    return (
        <>
            <LoadingSpinner />
            {/* Main content */}
            <div className={styles.pageGrid}>
                <MobileHeader>
                    {/* Header breadcrumb */}
                    <div className={styles.headerBreadCrumb}>
                        {!showList ? (
                            <h3>Flashcards</h3>
                        ) : (
                            <div onClick={() => setShowList(false)}>
                                <img src={CheveronLeft} />
                                <h3>Vocab Library</h3>
                            </div>
                        )}
                    </div>

                    {/* Review Now button */}
                    <ButtonColor
                        color={'blue'}
                        height={'44px'}
                        onClick={() => setTogglePreferences(true)}
                    >
                        Review Now
                    </ButtonColor>
                </MobileHeader>

                {/* WordStats component */}
                <main className={styles.fcListContent}>
                    <WordStats userWords={userWords} />
                </main>

                {/* Navbar component */}
                <Navbar navType={'gridNavMobile'} />
            </div>

            {/* FCPreferences component, conditional rendering based on togglePreferences */}
            {togglePreferences && (
                <FCPreferences
                    toggleClose={() => setTogglePreferences(false)}
                    fetchedUserWords={userWords}
                    userWordsFetched={true}
                    weeklywordsFetched={false}
                />
            )}
        </>
    );
};

export default UserWordsPage;
