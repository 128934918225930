import { useState } from 'react';
import { Lesson, Resource, Word } from '../types/api_response';

interface Props {
    words?: Word[];
    lessons?: Lesson[];
    resources?: Resource[];
}

const useSearch = (props: Props) => {
    const { words, lessons, resources } = props;
    const [searchQueary, setSearchQuery] = useState<string>('');
    const searchParam: string[] = ['title', 'name', 'tags'];
    const searchWordParam: string[] = ['simplified', 'traditional', 'pinyin'];

    const searchLessons = (lessons: Lesson[]) => {
        if (lessons === null) {
            return [];
        }
        return lessons.filter((lesson) => {
            return searchParam.some((newLesson) => {
                if (lesson.lessonData[newLesson] === undefined) {
                    return '';
                }

                return (
                    lesson.lessonData[newLesson]
                        .replace(/[^a-zA-Z0-9\s]/g, '')
                        .toString()
                        .toLowerCase()
                        .indexOf(searchQueary?.toLowerCase()) > -1
                );
            });
        });
    };

    const searchResources = (resources: Resource[] = []) => {
        if (resources === null) {
            return [];
        }
        return resources.filter((resource) => {
            return searchParam.some((newLesson) => {
                if (resource[newLesson] === undefined) {
                    return '';
                }

                return (
                    resource[newLesson]
                        .replace(/[^a-zA-Z0-9\s]/g, '')
                        .toString()
                        .toLowerCase()
                        .indexOf(searchQueary?.toLowerCase()) > -1
                );
            });
        });
    };

    const searchWords = (words: Word[]) => {
        if (words === null) {
            return [];
        }
        return words.filter((word) => {
            return searchWordParam.some((newWord) => {
                if (word[newWord] === undefined) {
                    return '';
                }

                return (
                    word[newWord]
                        .toString()
                        .toLowerCase()
                        .indexOf(searchQueary?.toLowerCase()) > -1
                );
            });
        });
    };

    const wordsTotal: number = words ? searchWords(words).length : 0;
    const lessonTotal: number = lessons ? searchLessons(lessons).length : 0;
    const resourceTotal: number = resources
        ? searchResources(resources).length
        : 0;

    return {
        searchResources,
        searchLessons,
        searchWords,
        setSearchQuery,
        searchQueary,
        wordsTotal,
        lessonTotal,
        resourceTotal,
    };
};

export default useSearch;
