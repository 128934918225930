import React from 'react';
import downArrow from '../../../../public/Images/chevron-down.svg';
import styles from './_styles/LessonDropdown.module.scss';

interface Props {
    optionArr: string[];
    currentOption: string;
    updateOption: CallableFunction;
    isDropdownOpen: boolean;
    setIsDropdownOpen: () => void;
    prefMode: boolean;
}

/**
 * @fileoverview
 * Drop down component to display a list of selectable items. Used for selecting user's current week or in the flashcards preferences
 *
 * @param {Props} props
 * - optionArr: list of selectable items
 * - currentOption: the current or selected option
 * - updateOption: A function that will handle updating the user's selected lesson week
 * - isDropDownOpen: lesson list boolean
 * - setIsDropDownOpen: toggle the list open/closed
 * - prefMode: styling changes for lessons or flashcard study
 *
 * @return {JSX.Element} - Rendered lesson dropdown component
 */
const LessonDropdown = (props: Props): JSX.Element => {
    const {
        optionArr,
        currentOption,
        updateOption,
        isDropdownOpen,
        setIsDropdownOpen,
        prefMode,
    } = props;
    const lastOption = optionArr[optionArr.length - 1];

    const outLineSelected = () => {
        if (prefMode && isDropdownOpen) {
            return styles.outlineSelected;
        } else {
            return styles.outline;
        }
    };

    return (
        <>
            <div
                className={
                    !prefMode
                        ? styles.selector
                        : styles.selectorPref + ' ' + outLineSelected()
                }
                onClick={setIsDropdownOpen}
            >
                <div className={styles.dropdownArrow}>
                    <p>
                        {currentOption != 'Quarter 0'
                            ? currentOption
                            : 'Intro Course'}
                    </p>
                    <img src={downArrow} />
                </div>
                {isDropdownOpen && (
                    <menu className={!prefMode ? styles.list : styles.listPref}>
                        {optionArr.map((quarter, i) => {
                            /* show every element but the last for styling purposes */
                            if (i < optionArr.length - 1) {
                                return (
                                    <p
                                        key={i}
                                        className={styles.listBorder}
                                        onClick={() => updateOption(quarter)}
                                    >
                                        {quarter != 'Quarter 0'
                                            ? quarter
                                            : 'Intro Course'}
                                    </p>
                                );
                            }
                        })}
                        {/* Shows the last element for styling purposes */}
                        {optionArr.length > 1 && (
                            <p
                                className={styles.noborder}
                                onClick={() => updateOption(lastOption)}
                            >
                                {lastOption}
                            </p>
                        )}
                    </menu>
                )}
            </div>
        </>
    );
};

export default LessonDropdown;
