import React, { useState } from 'react';
import VideoModal from '../../GlobalComponents/VideoModal';
import styles from './_styles/MultiMediaResource.module.scss';

interface Props {
    title: string;
    description: string;
    resourceURI: string;
    tagsArr: string[];
    coverImage?: string;
    imageFile?: File | null;
}

/**
 * @fileoverview
 * This component displays the multimedia resources which would include a video
 *
 * @param {Props} props
 * - title: resource title
 * - description: resource description
 * - resoureURI: link to the video
 * - tagsArr: an array of tags for filtering resources
 *
 * @return {JSX.Element} - Rendered multimedia resource
 */
const MultiMediaResource = (props: Props): JSX.Element => {
    const { title, description, resourceURI, coverImage, tagsArr, imageFile } =
        props;

    // State to manage the video modal visibility
    const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);
    // State to manage the preview of the image file
    const [imagePreview, setImagePreview] = useState<string | undefined>(
        undefined,
    );

    // Function to preview image file
    const previewImg = (imgFile: File | undefined): string | undefined => {
        try {
            if (imgFile) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImagePreview(reader.result as string);
                };
                reader.readAsDataURL(imgFile);
            }
        } catch (error) {
            console.error('Error previewing image:', error);
        }
        return imagePreview;
    };

    return (
        <>
            {/* Clickable multimedia resource container */}
            <div
                className={styles.multimediaResource}
                onClick={() => setIsVideoOpen(true)}
            >
                {/* Display tags associated with the resource */}
                <div className={styles.tags}>
                    {tagsArr.map((tag, i) => {
                        return <p key={i}>{tag}</p>;
                    })}
                </div>

                {/* Main content container for the resource */}
                <div className={styles.resourceContainer}>
                    {/* Body content including title and description */}
                    <div className={styles.resrouceBody}>
                        <p className={styles.title}>{title}</p>
                        <p className={styles.description}>{description}</p>
                    </div>

                    {/* Display cover image or placeholder if not available */}
                    {imageFile?.name || coverImage ? (
                        imageFile?.name ? (
                            <img
                                className={styles.coverImage}
                                src={previewImg(imageFile)}
                                alt="preview image"
                            />
                        ) : (
                            <img
                                className={styles.coverImage}
                                src={coverImage}
                                alt="resource-cover-img"
                            />
                        )
                    ) : (
                        <div className={styles.noCoverImage}>No Image</div>
                    )}
                </div>
            </div>

            {/* Video modal component */}
            <VideoModal
                isOpen={isVideoOpen}
                toggleClose={() => setIsVideoOpen(false)}
                isTutorial={false}
                src={resourceURI}
            />
        </>
    );
};

export default MultiMediaResource;
