import React, { useContext } from 'react';
import UserContext from '../../context/UserContext';
import { Word } from '../../types/api_response';
import styles from './_styles/HanziWord.module.scss';

interface Props {
    word: Word;
    size?: 'small' | 'medium' | 'large';
    delegatedProps?: any;
}

/**
 * Component for rendering a Hanzi (Chinese character) word.
 * @param {Props} props - The component props.
 * @return {JSX.Element} - The rendered component.
 */
const HanziWord = (props: Props): JSX.Element => {
    // Access mandarinType from the user context
    const { mandarinType } = useContext(UserContext);
    const { word, size = 'small', delegatedProps } = props;

    return (
        // Render a paragraph element with a specified size and delegated props
        <p className={styles[size]} {...delegatedProps}>
            {/* Conditionally render the simplified or traditional form based on mandarinType */}
            {mandarinType ? <>{word.simplified}</> : <>{word.traditional} </>}
        </p>
    );
};

export default HanziWord;
