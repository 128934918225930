/* eslint-disable guard-for-in */
import React, { useEffect, useState } from 'react';
import {
    Lesson,
    Phrase,
    PhraseWord,
    Word,
    updateLessonObj,
} from '../../../types/api_response';
import { useParams, useHistory } from 'react-router-dom';
import useFetch from '../../../useFetch';
import AudioInterface from '../Lesson/AudioInterface';
import ButtonColor from '../../UI-Components/ButtonColor';
import EditForm from './EditForm';
import EditLessonMetaEditor from './EditLessonMetaEditor';
import EditPhraseWordList from './EditPhraseWordList';
import LoadingSpinner from '../../UI-Components/LoadingSpinner';
import PhraseReviewModal from './PhraseReviewModal';
import WarningModal from './WarningModal';
import styles from './_styles/EditLessonTool.module.scss';
/* 
------Notes
-Addphrase handler breaks the code
-if you try to delete the first phrase it breaks the code (created fix to prevent error)
*/

/**
 *
 * @fileoverview
 * This component handles all the logic for editing lessons and saving them
 *
 * @return {JSX.Element} - Rendered lesson editor page
 */
const EditLessons = (): JSX.Element => {
    const originalObj: updateLessonObj = {
        lessonData: undefined,
        name: '',
        description: '',
        tags: '',
        image: null,
        audio: null,
        override: 0,
    };
    const [phrases, setPhrases] = useState<Phrase[]>([]);
    const [newWords, setNewWords] = useState<any>([]);
    const [lessonObj, setLessonObj] = useState<updateLessonObj>(originalObj);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [audioPlayer, setAudioPlayer] = useState<AudioInterface | null>(null);
    const [audioPhrase, setAudioPhrase] = useState<AudioInterface | null>(null);
    const [activePhrase, setActivePhrase] = useState<Phrase | null>(null);
    const [isSaveWarningOpen, setIsSaveWarningOpen] = useState<boolean>(false);
    const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);
    const [isSuccessModalOpen, setisSuccessModalOpen] =
        useState<boolean>(false);
    const [isDeleteWarningOpen, setIsDeleteWarningOpen] =
        useState<boolean>(false);

    const { getReq, updateLesson } = useFetch();
    const history = useHistory();
    const params = useParams();

    console.log(params, 'lessonid');

    const newWord = {
        word: {
            frequency: 0,
            hskv2_level: 0,
            hskv3_level: 0,
            pinyin: '',
            simplified: 'kong',
            traditional: '',
            translation: '',
            uuid: '',
        },
        markdown: '',
        time: '',
    };

    /* This use effect will update the general phrases whenever a change to the activePhrase occurs -- effectively persisting all changes immediately */
    useEffect(() => {
        if (phrases == undefined || phrases[activeIndex] == activePhrase) {
            return;
        }

        setPhrases((prev) => {
            prev[activeIndex] = activePhrase;
            return prev;
        });

        resetAudioPlayer();

        return () => {
            audioPhrase?.stop();
            audioPlayer?.stop();
        };
    }, [activePhrase]);

    // set lesson editor data and audio elements
    useEffect(() => {
        getReq(`/lessons/${params.lessonName}`).then((data: any) => {
            const lesson: Lesson = data.data;
            setLessonObj({
                lessonData: lesson,
                name: lesson.lessonData.name,
                description: lesson.lessonData.description,
                tags: lesson.lessonData.tags,
                image: null,
                audio: null,
                override: lessonObj.override,
            });
            setActivePhrase(lesson.phrases[activeIndex]);
            const audioElement = new Audio(lesson.lessonData.audio);
            const audioPhrase = new Audio(lesson.lessonData.audio);
            setAudioPlayer(new AudioInterface(audioElement));
            setAudioPhrase(new AudioInterface(audioPhrase));
            setPhrases(lesson.phrases);
        });
    }, []);

    // Function to update lesson data
    const updateLessonData = (phrases: Phrase[]) => {
        // Mapping through phrases array
        const reqPhrases = phrases
            .map((phrase, i) => {
                // Checking if translation is empty
                if (phrase.translation === '') {
                    // Logging an error message if translation is missing
                    console.log(
                        `Phrase ${
                            i + 1
                        } is missing a translation -- please add the translation and resubmit`,
                    );
                    return; // Skipping this phrase if translation is missing
                }

                // Mapping through words array in each phrase
                phrase.words = phrase.words.map((word) => {
                    // Checking if the simplified word contains specific characters
                    if (
                        word.word.simplified &&
                        word.word.simplified.search(
                            /([\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5])+/,
                        ) !== -1
                    ) {
                        // Assigning simplified word to markdown if it contains specific characters
                        word.markdown = word.word.simplified;
                    }

                    // Checking if markdown is not empty
                    if (word.markdown !== '') {
                        // Resetting time and word properties
                        word.time = '';
                        word.word = {} as Word;
                    }

                    return word; // Returning modified word
                });

                return phrase; // Returning modified phrase
            })
            .filter(Boolean); // Filtering out undefined elements from the phrases array

        // Creating request body
        const reqBody = {
            name: lessonObj.name,
            description: lessonObj.description,
            tags: lessonObj.tags,
            phrases: JSON.stringify([...reqPhrases]),
            image: lessonObj.image,
            audio: lessonObj.audio,
            override: lessonObj.override,
        };

        // Updating lesson using API call
        updateLesson(
            `/admin/lessons/${lessonObj.lessonData?.lessonData.uuid}`,
            reqBody,
        ).then((data: any) => {
            // Logging data received from the API
            console.log(data, 'data');
            // Handling different statuses from the API response
            if (data.status === 'fail') {
                // Updating lesson object and setting new words if status is 'fail'
                setLessonObj((prev) => ({
                    ...prev,
                    override: 1,
                }));
                setNewWords(data.data);
            } else if (data.status === 'error') {
                // Logging an error message and showing an alert if status is 'error'
                console.log('Error posting lesson edits: ', data.message);
                alert('Error posting lesson');
            } else if (data.status === 'success') {
                // Setting modal open if status is 'success'
                setisSuccessModalOpen(true);
                // location.reload(); // Optionally reload the page
            }
        });
    };

    // Function to handle adding a word to the active phrase
    const addWordHandler = (
        activePhrase: Phrase, // The active phrase object
        wordIndex: number, // Index of the word in the active phrase
        word: PhraseWord, // The word to be added
    ) => {
        // Creating a temporary variable to hold the new word
        const tmp = newWord;
        // Generating a new UUID for the word (appending '1' to the existing UUID)
        tmp.word.uuid = word.word.uuid + '1';

        // Checking if it's the last word or if wordIndex is -1 (indicating a new word at the end)
        if (wordIndex === -1 || activePhrase.words.length === wordIndex + 1) {
            const len: number = activePhrase.words.length - 1;
            // Borrowing the time value from the last word or the second last word if the last one is empty
            tmp.time =
                activePhrase.words[len].time ||
                activePhrase.words[len - 1].time;

            // Updating the active phrase with the new word at the end
            setActivePhrase({
                words: [...activePhrase.words, tmp],
                translation: activePhrase.translation,
                uuid: activePhrase.uuid,
            });
            return; // Exiting the function after updating the active phrase
        }

        // Checking if the time of the current word is not empty
        if (activePhrase.words[wordIndex].time !== '') {
            tmp.time = activePhrase.words[wordIndex].time;
        } else {
            // If the current word's time is empty, borrowing the time from the previous word
            tmp.time = activePhrase.words[wordIndex - 1].time;
        }

        // Updating the active phrase with the new word at the specified index
        setActivePhrase({
            words: [
                ...activePhrase.words.slice(0, wordIndex),
                tmp,
                ...activePhrase.words.slice(wordIndex),
            ],
            translation: activePhrase.translation,
            uuid: activePhrase.uuid,
        });
    };

    // Function to handle deleting a word from the active phrase
    const deleteWordHandler = (activePhrase: Phrase, wordIndex: number) => {
        // Creating a copy of the words array in the active phrase
        const words = activePhrase.words.slice();
        // Removing the word at the specified index
        words.splice(wordIndex, 1);

        // Updating the active phrase with the modified words array
        setActivePhrase({
            ...activePhrase, // Copying existing properties of the active phrase
            words, // Updating the words property with the modified array
        });
    };

    // Function to handle splitting a phrase into two at a specific word index
    const splitPhraseHandler = (phraseIndex: number, wordIndex: number) => {
        // Updating the state using the previous state (functional update)
        setPhrases((prev) => {
            // Extracting the current phrase at the specified index
            const currentPhrase = prev[phraseIndex];
            // Extracting the translation from the current phrase or using an empty string if it's undefined
            const translation = currentPhrase.translation || '';

            // Creating a new phrase starting from the specified word index
            const newPhrase: Phrase = {
                uuid: 'newPhrase ' + currentPhrase.uuid, // Generating a new UUID for the new phrase
                translation: translation ? translation : '', // Setting the translation for the new phrase
                words: currentPhrase.words.slice(wordIndex), // Copying words starting from the specified index
            };

            // Creating an old phrase with words before the specified index
            const oldPhrase: Phrase = {
                ...currentPhrase, // Copying existing properties of the current phrase
                words: currentPhrase.words.slice(0, wordIndex), // Copying words before the specified index
            };

            // Creating a new array with the modified old and new phrases
            const newPhrases: Phrase[] = [...prev];
            newPhrases.splice(phraseIndex, 1, oldPhrase, newPhrase);

            // Setting the active phrase to the new phrase
            setActivePhrase(newPhrase);
            // Setting the active index to the index after the split
            setActiveIndex(phraseIndex + 1);

            return newPhrases; // Returning the updated phrases array
        });
    };

    // Function to handle deleting a phrase
    const deletePhraseHandler = (phraseIndex: number) => {
        /* Added this if statement to stop code from breaking */
        if (phrases[0] === phrases[activeIndex]) {
            return; // Preventing deletion of the first phrase to avoid breaking the code
        }

        // Updating the state using the previous state (functional update)
        setPhrases((prev) => {
            if (prev === undefined) {
                return prev; // Returning undefined if the previous state is undefined
            }

            // Creating a new array with the modified phrases
            const newPhrases = [...prev];

            // Concatenating words and translation of the deleted phrase to the previous phrase
            const newWords = newPhrases[phraseIndex - 1].words.concat(
                newPhrases[phraseIndex].words,
            );
            const newTranslation =
                newPhrases[phraseIndex - 1].translation +
                newPhrases[phraseIndex].translation;

            // Replacing the two phrases with a new one that includes the concatenated data
            newPhrases.splice(phraseIndex - 1, 2, {
                uuid: newPhrases[phraseIndex - 1].uuid,
                words: newWords,
                translation: newTranslation,
            });

            // Updating the active index to the index before the deleted phrase
            setActiveIndex(activeIndex - 1);
            // Setting the active phrase to the new phrase
            setActivePhrase(newPhrases[activeIndex - 1]);

            return newPhrases; // Returning the updated phrases array
        });
    };

    // Function to reset the audio player
    const resetAudioPlayer = () => {
        // Checking if lesson data exists in the lesson object
        if (lessonObj.lessonData) {
            // Creating a new Audio element with the audio URL from lesson data
            const audioElement = new Audio(
                lessonObj.lessonData.lessonData.audio,
            );

            // Creating a new AudioInterface and setting it as the audio player
            setAudioPlayer(new AudioInterface(audioElement));
        }
    };

    return (
        <>
            <LoadingSpinner />
            {lessonObj.lessonData && phrases && (
                // Phrase Form
                <EditForm
                    lesson={lessonObj.lessonData}
                    lessonObj={lessonObj}
                    setLessonObj={setLessonObj}
                >
                    <div className={styles.phraseEditorContainer}>
                        {/* Phrase editor */}
                        <EditLessonMetaEditor
                            audioPhrase={audioPhrase}
                            audioPlayer={audioPlayer}
                            phrases={phrases}
                            activePhrase={activePhrase}
                            activeIndex={activeIndex}
                            setPhrases={setPhrases}
                            setActiveIndex={setActiveIndex}
                            setActivePhrase={setActivePhrase}
                            setIsDeleteWarningOpen={setIsDeleteWarningOpen}
                        />

                        {/* Word editor */}
                        <EditPhraseWordList
                            audioPlayer={audioPlayer}
                            activePhrase={activePhrase}
                            activeIndex={activeIndex}
                            addWordHandler={addWordHandler}
                            splitPhraseHandler={splitPhraseHandler}
                            deleteWordHandler={deleteWordHandler}
                            deletePhraseHandler={deletePhraseHandler}
                            setActivePhrase={setActivePhrase}
                        />
                    </div>

                    {/* Submit buttonss */}
                    <div className={styles.submitbutton}>
                        <ButtonColor
                            height={'56px'}
                            width={'49%'}
                            color={'orangeClear'}
                            onClick={() => setIsSaveWarningOpen(true)}
                        >
                            Return to lesson list
                        </ButtonColor>

                        <ButtonColor
                            height={'56px'}
                            width={'49%'}
                            color={'orange'}
                            onClick={() => setIsReviewModalOpen(true)}
                        >
                            Submit Lesson & Phrase Timing Update
                        </ButtonColor>
                    </div>
                </EditForm>
            )}
            {/* Delete Phrase modal */}
            <WarningModal
                show={isDeleteWarningOpen}
                messageType={'delete'}
                toggleClose={() => setIsDeleteWarningOpen(false)}
                toggleContinue={() => deletePhraseHandler(activeIndex)}
            />

            {/* Progress unsaved Modal */}
            <WarningModal
                show={isSaveWarningOpen}
                messageType={'warning'}
                toggleContinue={() => history.goBack()}
                toggleClose={() => {
                    setIsSaveWarningOpen(false);
                }}
            />
            <WarningModal
                show={isSuccessModalOpen}
                singleButton={true}
                messageType={'uploadsuccess'}
                toggleClose={() => {}}
                toggleContinue={() => setisSuccessModalOpen(false)}
            />
            <PhraseReviewModal
                show={isReviewModalOpen}
                phrases={phrases}
                override={lessonObj.override}
                newWords={newWords}
                audioPlayer={audioPlayer}
                toggleUpdate={() => {
                    setIsReviewModalOpen(false);
                    updateLessonData(phrases);
                }}
                toggleClose={() => {
                    setIsReviewModalOpen(false);
                    setLessonObj((prev) => ({
                        ...prev,
                        override: 0,
                    }));
                }}
            />
        </>
    );
};

export default EditLessons;
