import React, { useState } from 'react';
import { Phrase, PhraseWord } from '../../../types/api_response';
import AudioInterface from '../Lesson/AudioInterface';
import DeleteModal from './WarningModal';
import play from '../../../../public/Images/AudioIcons/btn-play.svg';
import Selector from '../../UI-Components/Selector';
import trash from '../../../../public/Images/trash.svg';
import styles from './_styles/TimingEditorLine.module.scss';

// Type definition for props passed to TimingEditorLine component
type Props = {
    word: PhraseWord;
    phrase: Phrase;
    audioPlayer: AudioInterface;
    activeIndex: number;
    wordIndex: number;
    cbUpdate: CallableFunction;
    cbDelete: CallableFunction;
};

// Function to sum two floating-point numbers and round the result to one decimal place
// eslint-disable-next-line require-jsdoc
function sumFloats(a: number, b: number): number {
    return Math.round((a + b) * 10) / 10;
}

// eslint-disable-next-line valid-jsdoc
/**
 *
 * @fileoverview
 * TimingEditorLine component for editing timing and other controls for individual words in a phrase
 *
 * @return {JSX.Element} - Rendered Timing editor line for lesson editing
 */
const TimingEditorLine = (props: Props): JSX.Element => {
    const { word, phrase, audioPlayer, wordIndex, cbUpdate, cbDelete } = props;
    const time = Math.round(parseFloat(word.time) * 10) / 10; // Round time to one decimal place
    const [toggleDelete, setToggleDelete] = useState<boolean>(false);

    // Function to handle changes in the time input
    const handleChange = (e) => {
        const updatedWords = [...phrase.words]; // Create a copy of the array
        e.preventDefault(); // Prevent the default action
        const regex = /[^0-9.]+|\.\d*\./;
        if (regex.test(e.target.value)) {
            // Warn the user about formatting logic
            return;
        }

        updatedWords[wordIndex].time = e.target.value;
        cbUpdate(updatedWords);
    };

    // Function to adjust the timing up or down
    const selectTime = (select: 'up' | 'down') => {
        const updatedWords = [...phrase.words];

        if (!phrase.words[wordIndex].time) {
            return;
        }

        const updatedTime =
            select === 'up' ? sumFloats(time, 0.1) : sumFloats(time, -0.1);

        updatedWords[wordIndex].time = updatedTime.toFixed(1);
        cbUpdate(updatedWords);
        audioPlayer?.playSection(time, time + 1);
    };

    return (
        <>
            {/* Word editor and controls */}
            <div className={styles.wordDiv}>
                <div className={styles.simplifiedInput}>
                    {/* Input to change markdown */}
                    {word.markdown !== '' ? (
                        <input
                            className={styles.inputBox}
                            value={word.markdown}
                            onChange={(e) => {
                                const updatedWords = [...phrase.words];
                                updatedWords[wordIndex].markdown =
                                    e.target.value;
                                cbUpdate(updatedWords);
                            }}
                        />
                    ) : (
                        <>
                            {/* Word editor inputs */}
                            <input
                                className={styles.inputBox}
                                value={word.word.simplified}
                                onChange={(e) => {
                                    const updatedWords = [...phrase.words];
                                    updatedWords[wordIndex].word.simplified =
                                        e.target.value;
                                    cbUpdate(updatedWords);
                                }}
                            />

                            {/* Time Editor */}
                            <div className={styles.timeEditor}>
                                <input
                                    className={styles.inputBox}
                                    value={word.time}
                                    onChange={handleChange}
                                />
                                <Selector
                                    key={wordIndex}
                                    top={'2px'}
                                    right={'4px'}
                                    toggleUp={() => selectTime('up')}
                                    toggleDown={() => selectTime('down')}
                                />
                            </div>
                        </>
                    )}
                </div>
                {/* Additional controls (delete and play buttons) */}
                <div className={styles.moreControls}>
                    <img
                        className={styles.trashButton}
                        src={trash}
                        onClick={() => setToggleDelete(true)}
                    />
                    {word.markdown === '' && (
                        <img
                            className={styles.playButton}
                            src={play}
                            onClick={() => {
                                phrase.words[wordIndex].time &&
                                    audioPlayer?.playSection(time, time + 1);
                            }}
                        />
                    )}
                </div>
            </div>
            {/* Delete confirmation modal */}
            <DeleteModal
                show={toggleDelete}
                messageType={'delete'}
                toggleContinue={() => cbDelete()}
                toggleClose={() => {
                    setToggleDelete(false);
                }}
            />
        </>
    );
};

export default TimingEditorLine;
