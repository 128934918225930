import React, { useContext, useRef, useEffect } from 'react';
import styles from './_styles/WordSettingsModal.module.scss'; // Styles for the WordSettingsModal component
import UserContext from '../../context/UserContext'; // User context for managing user settings
import useOutsideClick from '../../customhooks/onOutsideClick'; // Hook for handling outside clicks
import SwitchButton from '../UI-Components/SwitchButton'; // Switch button component for toggling settings
import MasterButton from '../UI-Components/MasterButton'; // Master button component for additional settings

interface Props {
    toggleClose: CallableFunction; // Function to toggle the modal closed
    fcMode: boolean; // Flag indicating whether in flashcard mode
    top?: number; // Top position of the modal
    bottom?: number; // Bottom position of the modal
    left?: number; // Left position of the modal
    right?: number; // Right position of the modal
    mobileStyle?: boolean; // Flag indicating mobile-style display
    fcSettings?: CallableFunction; // Function for flashcard settings
}

/* 
   if you don't need a position variable like left or right, just enter NaN 
*/

/**
 * @fileoverview - interface to change user word settings
 * @param {Props} props
 * @return {JSX.Element} - Rendered word settings modal
 */
const WordSettingsModal = (props: Props) => {
    const {
        toggleClose,
        fcMode,
        top,
        bottom,
        left,
        right,
        mobileStyle,
        fcSettings,
    } = props;
    const { autoPlay, setAutoPlay, setUserObject, userObject } =
        useContext(UserContext); // Access user context for managing user settings
    const showRef = useRef(null); // Reference for detecting outside clicks

    // Cleanup function when the component unmounts
    useEffect(() => {
        return () => {
            showRef.current = null;
        };
    }, []);

    // Hook to handle outside clicks and close the modal
    useOutsideClick(showRef, () => {
        toggleClose();
    });

    // Position style for the modal
    const position = {
        top: top,
        bottom: bottom,
        left: left,
        right: right,
    };

    // Additional styles for flashcard mode
    const fcStyles = fcMode ? styles.fcStyles : '';
    const isSimplified: boolean = userObject.characterType == 'Simplified';

    return (
        <>
            {/* Modal content */}
            <div
                ref={showRef}
                style={position}
                className={
                    !mobileStyle
                        ? styles.menu + ' ' + fcStyles
                        : styles.mobileStyle
                }
            >
                {/* Display title in mobile style */}
                {mobileStyle && (
                    <p className={styles.modalTitle}>Display Options</p>
                )}

                {/* Toggle for Traditional Chinese */}
                <div>
                    <p>Traditional Chinese</p>
                    <SwitchButton
                        name={'charactertype'}
                        isOn={!isSimplified}
                        handleToggle={() => {
                            !isSimplified
                                ? setUserObject((prev) => ({
                                      ...prev,
                                      characterType: 'Simplified',
                                  }))
                                : setUserObject((prev) => ({
                                      ...prev,
                                      characterType: 'Traditional',
                                  }));
                        }}
                    />
                </div>

                {/* Toggle for Hanzi only */}
                <div>
                    <p>Hanzi only</p>
                    <SwitchButton
                        name={'hanzionly'}
                        isOn={userObject.textMode == 'Hanzi'}
                        handleToggle={() => {
                            setUserObject((prev) => ({
                                ...prev,
                                textMode: 'Hanzi',
                            }));
                        }}
                    />
                </div>

                {/* Toggle for Pinyin only */}
                <div>
                    <p>Pinyin only</p>
                    <SwitchButton
                        name={'pinyinonly'}
                        isOn={userObject.textMode == 'Pinyin'}
                        handleToggle={() => {
                            setUserObject((prev) => ({
                                ...prev,
                                textMode: 'Pinyin',
                            }));
                        }}
                    />
                </div>

                {/* Toggle for Hanzi and Pinyin */}
                <div>
                    <p>Hanzi and Pinyin</p>
                    <SwitchButton
                        name={'HanziandPinyin'}
                        isOn={userObject.textMode == 'Both'}
                        handleToggle={() => {
                            setUserObject((prev) => ({
                                ...prev,
                                textMode: 'Both',
                            }));
                        }}
                    />
                </div>

                {/* Toggle for Autoplay */}
                <div>
                    <p>Autoplay</p>
                    <SwitchButton
                        name={'Autoplay'}
                        isOn={autoPlay}
                        handleToggle={() => setAutoPlay(!autoPlay)}
                    />
                </div>

                {/* Additional flashcard settings in mobile style */}
                {mobileStyle && fcSettings && (
                    <aside className={styles.blockButtons}>
                        <MasterButton
                            isBlock={false}
                            toggleOpen={() =>
                                fcSettings((prev) => ({
                                    ...prev,
                                    mastered: true,
                                }))
                            }
                        />
                        <MasterButton
                            isBlock={true}
                            toggleOpen={() =>
                                fcSettings((prev) => ({
                                    ...prev,
                                    block: true,
                                }))
                            }
                        />
                    </aside>
                )}
            </div>
        </>
    );
};

export default WordSettingsModal;
