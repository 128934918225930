import { PopupConfigOptions, useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ButtonColor from '../UI-Components/ButtonColor';

import useFetch from '../../useFetch';

interface Props {
    Auth0: boolean;
    oldCredentials?: { username: string; password: string };
}

/**
 * 1. Handles login logic.
 * 2. Saves initial auth0Token to localStorage
 * 3. Posts subsequent requests to server to save || update userDB
 * @param {Object} props -- contains the user's username & password from the previous login system
 * @return {JSX.Element}
 * */
export const LoginButton = (props: Props) => {
    const oldCredentials = props.oldCredentials;
    const { loginWithPopup, getAccessTokenSilently } = useAuth0();
    const { postReq, loginUser } = useFetch();
    const history: History = useHistory();

    const handleLogin = async () => {
        const config: PopupConfigOptions = {};
        config.timeoutInSeconds = 120;
        await loginWithPopup({}, config);
        const token = await getAccessTokenSilently();
        token && localStorage.setItem('auth0Token', token);

        if (oldCredentials) {
            await loginUser('/login', {
                ...oldCredentials,
            });
        } else {
            await postReq('/login/auth0', {});
        }

        history.push('/dashboard');
    };

    return (
        <ButtonColor
            color={'blue'}
            width={'100%'}
            height={'56px'}
            onClick={() => handleLogin()}
        >
            {oldCredentials ? 'submit' : 'Login'}
        </ButtonColor>
    );
};
