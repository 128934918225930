import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import flashcardIcon from '../../../public/Images/NavIcons/nav-flashcard.svg';
import homeIcon from '../../../public/Images/NavIcons/nav-home.svg';
import lessonIcon from '../../../public/Images/NavIcons/nav-book-open.svg';
import styles from './_styles/BreadCrumbs.module.scss';

/**
 * Component for rendering breadcrumbs based on the current location in the app.
 * @return {JSX.Element} - The rendered BreadCrumbs component.
 */
const BreadCrumbs = (): JSX.Element => {
    const [crumb, setCrumb] = useState<Array<string>>(['Dashboard']);
    const location: any = useLocation();

    useEffect(() => {
        // Extract and clean the path segments from the current location
        const locationArray: Array<string> = location.pathname.split('/');
        const filteredArray = locationArray.filter((item) => {
            if (item === '' || item.length === 36) {
                return false;
            }
            return true;
        });

        // Capitalize the first letter of each path segment
        if (filteredArray.length > 0) {
            const cleanedArray = filteredArray.map((item) => {
                return item[0].toUpperCase() + item.slice(1);
            });
            setCrumb(cleanedArray);
        } else {
            setCrumb(['Dashboard']);
        }
    }, [location]);

    /**
     * Function to render the appropriate icon based on the first breadcrumb.
     * @param {string} crumb - The first breadcrumb in the array.
     * @return {JSX.Element} - The rendered icon element.
     */
    const iconFunk = (crumb: string): JSX.Element => {
        if (crumb === 'Dashboard') {
            return <img src={homeIcon} alt="Home Icon" />;
        } else if (crumb === 'Userwords') {
            return <img src={flashcardIcon} alt="Flashcard Icon" />;
        } else if (crumb === 'Flashcards') {
            return <img src={flashcardIcon} alt="Flashcard Icon" />;
        } else {
            return <img src={lessonIcon} alt="Lesson Icon" />;
        }
    };

    return (
        <div className={styles.breadCrumbs}>
            {iconFunk(crumb[0])}
            <h4>{crumb?.join('  >  ')}</h4>
        </div>
    );
};

export default BreadCrumbs;
