import React, { useState, useEffect } from 'react';
import closebutton from '../../../public/Images/ClearButtonX.svg';
import share from '../../../public/Images/AppleShare.png';
import styles from './_styles/InstallPWAModal.module.scss';
import useIsIOS from '../../customhooks/useIsIOS';

/**
 * Component for rendering a modal prompting the user to install the Progressive Web App (PWA) on iOS.
 * @return {JSX.Element} - The rendered component.
 */
const InstallPWAModal = (): JSX.Element => {
    // Check if the PWA installation prompt is available on iOS
    const { prompt } = useIsIOS();
    // State to control the visibility of the modal
    const [show, setShow] = useState<boolean>(false);

    // Update the visibility state based on the PWA installation prompt availability
    useEffect(() => {
        setShow(prompt);
    }, [prompt]);

    return (
        // Render the modal if the PWA installation prompt is available
        <>
            {show && (
                <aside className={styles.backdrop}>
                    <aside
                        className={styles.modalBody}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {/* Close button to dismiss the modal */}
                        <img
                            className={styles.closebutton}
                            src={closebutton}
                            alt="Close Button"
                            onClick={() => setShow(false)}
                        />
                        {/* Modal content with installation instructions */}
                        <h3>Install EM App</h3>
                        <p>
                            Install this application on your homescreen for a
                            better experience.
                        </p>
                        <p>
                            Tap
                            {/* Share icon for additional guidance */}
                            <img src={share} alt="Share Icon" />
                            then &quot;Add to Home Screen&quot;
                        </p>
                    </aside>
                </aside>
            )}
        </>
    );
};

export default InstallPWAModal;
