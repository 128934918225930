import React, { useEffect, useRef } from 'react';
import useOutsideClick from '../../../customhooks/onOutsideClick';
import AudioInterface from './AudioInterface';
import checkImg from '../../../../public/Images/FCcheck.svg';
import styles from './_styles/SpeedSettingsModal.module.scss';

interface Props {
    toggleClose: CallableFunction;
    updateSpeed: CallableFunction;
    audioPlayer?: AudioInterface;
    mobileStyle?: boolean;
    playSpeed: number;
}

/**
 * @fileoverview
 * Used for selecting audioplayback speed in lesson mode
 *
 * @param {Props} props
 * - toggleClose: close modal
 * - updateSpeed: handles updating the speed
 * - audioPlayer: used for updating the current audio interface
 * - playSpeed: display the current playback speed
 *
 * @return {JSX.Element} - Rendered speed setttings modal
 */
const SpeedSettingsModal = (props: Props) => {
    const { audioPlayer, playSpeed, updateSpeed, mobileStyle = false } = props;
    const showRef = useRef(null);

    useEffect(() => {
        return () => {
            showRef.current = null;
        };
    }, []);

    useOutsideClick(showRef, () => {
        props.toggleClose();
    });

    const speedHandler = (speed: number) => {
        audioPlayer?.setSpeed(speed);
        updateSpeed(speed);
    };

    return (
        <div
            ref={showRef}
            onClick={() => {
                props.toggleClose();
            }}
            className={
                !mobileStyle ? styles.speedModal : styles.speedModalMobile
            }
        >
            {mobileStyle && <p className={styles.modalTitle}>Speed</p>}
            <div
                className={!mobileStyle && styles.buttonSlow}
                onClick={() => speedHandler(0.5)}
            >
                {playSpeed === 0.5 && !mobileStyle && (
                    <img src={checkImg} className={styles.check} />
                )}
                <p>0.50x</p>
                {playSpeed === 0.5 && mobileStyle && (
                    <img src={checkImg} className={styles.check} />
                )}
            </div>

            <div
                className={!mobileStyle && styles.buttonNorm}
                onClick={() => speedHandler(0.75)}
            >
                {playSpeed === 0.75 && !mobileStyle && (
                    <img src={checkImg} className={styles.check} />
                )}
                <p>0.75x</p>
                {playSpeed === 0.75 && mobileStyle && (
                    <img src={checkImg} className={styles.check} />
                )}
            </div>

            <div
                className={!mobileStyle && styles.buttonFast}
                onClick={() => speedHandler(1)}
            >
                {playSpeed === 1.0 && !mobileStyle && (
                    <img src={checkImg} className={styles.check} />
                )}
                <p>1.00x (normal)</p>
                {playSpeed === 1.0 && mobileStyle && (
                    <img src={checkImg} className={styles.check} />
                )}
            </div>
        </div>
    );
};

export default SpeedSettingsModal;
