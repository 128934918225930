import React from 'react';
import styles from './_styles/AlertMessage.module.scss';

interface Props {
    header: string;
    message?: string;
}

/**
 *
 * @param {Props} props
 * - header: header meassage h4 element
 * - message: message body p element
 *
 * @return {JSX.Element} - Rendered alert message
 */
const AlertMessage = (props: Props): JSX.Element => {
    return (
        <aside className={styles.noWordMessage}>
            <div>
                <h4>{props.header}</h4>

                {props.message && <p>{props.message}</p>}
            </div>
        </aside>
    );
};

export default AlertMessage;
