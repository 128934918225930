/* eslint-disable require-jsdoc */

class AudioInterface {
    audioElement: HTMLAudioElement;
    cbUpdateTime: CallableFunction | undefined;
    timer: any;

    constructor(
        audioElement: HTMLAudioElement,
        cbUpdateTime?: CallableFunction,
    ) {
        this.audioElement = audioElement;
        this.cbUpdateTime = cbUpdateTime;
    }

    // Gives back total audio time
    totalTime = () => {
        return this.audioElement.duration;
    };

    // Changes the audio source
    changeSource = (url: string) => {
        this.audioElement.src = url;
        this.audioElement.load();
    };

    // Plays audio
    play = () => {
        const audioElement = this.audioElement;

        if (audioElement.paused) {
            audioElement.play();

            this.timer = setInterval(() => {
                this.cbUpdateTime &&
                    this.cbUpdateTime(audioElement.currentTime);
            }, 100);
        } else {
            clearInterval(this.timer);
            audioElement.pause();
        }
    };

    // Pauses audio
    pause = () => {
        this.audioElement.pause();
    };

    // Sets playback speed
    setSpeed = (speed: number) => {
        this.audioElement.playbackRate = speed;
    };

    // Used for skipping forward and back ward in the audio
    skip = (timeOffset: number) => {
        this.audioElement.currentTime += timeOffset;
    };

    // Used in learn and test mode to play sections of audio
    playSection = (startTime: number, endTime: number) => {
        const audioElement = this.audioElement;
        clearInterval(this.timer);

        const updateCurrentTime = () => {
            const currentTime = audioElement.currentTime;
            if (this.cbUpdateTime) {
                this.cbUpdateTime(currentTime);
            }

            if (currentTime >= endTime - 0.05) {
                audioElement.pause();
                cancelAnimationFrame(animationFrameId);
            } else {
                animationFrameId = requestAnimationFrame(updateCurrentTime);
            }
        };

        audioElement.currentTime = startTime;
        audioElement.play();

        let animationFrameId = requestAnimationFrame(updateCurrentTime);
    };

    // Used in listen mode to play from the start of a section of audio
    playFrom = (startTime: number) => {
        const audioElement = this.audioElement;

        audioElement.pause();
        audioElement.currentTime = startTime;
        this.timer = setTimeout(() => {
            audioElement.play();
        }, 500);
    };

    // Completely stop audio and reset
    stop = () => {
        const audioElement = this.audioElement;

        audioElement.pause();
        audioElement.currentTime = 0;
    };

    // Returns boolean if the audio is paused
    getIsPaused = () => {
        return this.audioElement.paused;
    };

    // Returns current playback speed
    getPlaySpeed = () => {
        return this.audioElement.playbackRate;
    };
}

export default AudioInterface;
