import React from 'react';
import { Phrase, Word } from '../../types/api_response';
import useViewport from '../../customhooks/useViewport';
import ExampleSentence from './ExampleSentence';

interface Props {
    word: Word;
    exampleSentences: Phrase[];
    phraseNum: number;
    fcMode?: boolean;
    fcCheck?: boolean;
}

/**
 * Component for rendering a list of example sentences.
 * @param {Props} props - The component props.
 * @return {JSX.Element} - The rendered component.
 */
const ExampleSentences = (props: Props): JSX.Element => {
    const {
        word,
        exampleSentences,
        phraseNum,
        fcCheck,
        fcMode = false,
    } = props;
    const { viewportHeight } = useViewport();

    // Return an empty fragment if exampleSentences is undefined or null
    if (exampleSentences === undefined || exampleSentences === null) {
        return <></>;
    }

    return (
        <>
            {viewportHeight <= 700 && fcMode
                ? // Render ExampleSentence if fcCheck is true and viewportHeight is below or equal to 700
                  fcCheck && (
                      <ExampleSentence
                          word={word}
                          phrase={exampleSentences[0]}
                      />
                  )
                : // Map through exampleSentences and render ExampleSentence components based on conditions
                  exampleSentences.map((phrase, i) => {
                      const checkPhrase = phrase.words.length;
                      // Skip rendering if the phrase has more than 16 words
                      if (checkPhrase > 16) {
                          return undefined;
                      }
                      // Skip rendering if the index is greater than or equal to phraseNum
                      if (i >= phraseNum) {
                          return;
                      } else {
                          return (
                              <>
                                  {fcMode ? (
                                      // Render ExampleSentence if fcMode is true and fcCheck is true
                                      fcCheck && (
                                          <ExampleSentence
                                              key={phrase.uuid}
                                              word={word}
                                              phrase={phrase}
                                          />
                                      )
                                  ) : (
                                      // Render ExampleSentence for regular mode
                                      <ExampleSentence
                                          key={phrase.uuid}
                                          word={word}
                                          phrase={phrase}
                                      />
                                  )}
                              </>
                          );
                      }
                  })}
        </>
    );
};

export default ExampleSentences;
