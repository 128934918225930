import { useState, useEffect, useContext } from 'react';
import UserContext from '../context/UserContext';
import tagParser from '../TagParser';

interface Props {
    prefMode: boolean;
}

// eslint-disable-next-line valid-jsdoc
/**
 * @fileoverview
 * This hook handles the parsing and displaying the options for selecting quarter and weeks. The component returns the formatted quarter/week option for filtering the lessons based on the users selections
 *
 * @props
 * preMode: This is for when the user is in the flashcard preferences. When the user selects weekly study and changes the options, it will update the week they are on
 *
 * @return
 * - newWeek: parsed week for setting the user's current week ex. '|EM Q1 W1|
 * - selectedWeek: current week ex. Week 1
 * - selectedQuarter: current quarter ex. Quarter 1
 * - weekArr: list of selectable options for weeks
 * - quarterArr: list of selectable options for quarters
 * - setSelectedWeek
 * - setSelectedQuarter
 */
const useSetUserWeek = (props: Props) => {
    const { currentWeek, setUserObject } = useContext(UserContext);
    const { prefMode } = props;
    const cw = currentWeek;
    const {
        getTagString,
        currentWeekSubTag,
        getSubTagLetter,
        getSubTagString,
        quarters,
        weeks,
        weeksIC,
    } = tagParser();

    const [firstRender, setFirstRender] = useState(true);
    const [selectedWeek, setSelectedWeek] = useState<string>(
        getTagString(cw, 'W', 'full'),
    );
    const [selectedQuarter, setSelectedQuarter] = useState<string>(
        getTagString(cw, currentWeekSubTag(cw), 'full'),
    );
    const parsedQuarter: boolean = getSubTagLetter(selectedQuarter) === 'Q';
    const quarterArr: string[] = quarters;

    /* 
    -weeks displays the standard week list. 
    -weeksIC is for intermediate class weeks 
    */
    const weekArr: string[] = parsedQuarter
        ? selectedQuarter != 'Quarter 0'
            ? weeks
            : weeksIC.slice(0, 2)
        : weeksIC;

    /* Substring for quarters */
    const parsedQ: string = getSubTagString(selectedQuarter);

    /* Substring for weeks */
    const parsedW: string = getSubTagString(selectedWeek);

    /* Parsed week for setting the users current week and filtering lessons */
    const newWeek: string = parsedQuarter
        ? `|EM ${parsedQ} ${parsedW}|`
        : `|EM ZJ ${parsedQ} ${parsedW}|`;

    /* This resets the selected week to the first when you change quarters */
    useEffect(() => {
        if (!firstRender) {
            setSelectedWeek(weekArr[0]);
        }

        setFirstRender(false);
    }, [selectedQuarter]);

    /* automatically update the users current quarter/week only when in fc preferences modal */
    useEffect(() => {
        if (!firstRender && prefMode) {
            setUserObject((prev) => ({
                ...prev,
                currentClassAndUnit: newWeek,
            }));
        }
    }, [selectedQuarter, selectedWeek]);

    return {
        newWeek,
        selectedWeek,
        setSelectedWeek,
        selectedQuarter,
        setSelectedQuarter,
        quarterArr,
        weekArr,
    };
};
export default useSetUserWeek;
