import React, { useState, useRef, useContext, useEffect } from 'react';
import useFunction from '../../../useFunction';
import UserContext from '../../../context/UserContext';
import { PhraseWord } from '../../../types/api_response';
import styles from './_styles/WordCard.module.scss';
import HanziWord from '../../GlobalComponents/HanziWord';
import useViewport from '../../../customhooks/useViewport';
import WordDefModal from '../../GlobalComponents/WordDefModal';

interface Props {
    PhraseWord: PhraseWord;
    wordType: 'listen' | 'learn' | 'test';
    /* dimmed = false ? you cannot click to see the defition */
    dimmed?: boolean;
    /* blur = true ? blurs word during listenMode */
    blurWords?: boolean;
    /* Used for listenmode only */
    audioTime?: number;
    wordEndTime?: number;
    /* -LearnHighlight controls highlightstyles, iterIndex & hasListended for covering ("blur") as well*/
    iterIndex?: number;
    wordIndex?: number;
    hasListened?: boolean;
    learnHighlight?: boolean;
    resultsPage?: boolean;
    isLastWord?: boolean;
}

// eslint-disable-next-line valid-jsdoc
/**
 * @fileoverview
 *
 * @param {Props} props
 * - PhraseWord: Word data
 * - wordType: Define which mode the word is being used in
 * - dimmed: Boolean for dimming words
 * - blurWords: Boolean for blurring words
 * - audioTime: Used for tracking when a word should be highlighted in listenmode
 * - wordEndTime: Listenmode prop for tracking word highlighting
 * - iterIndex: Learn mode prop for blurring words in learn mode
 * - wordIndex: Used for tracking blur state
 * - hasListened: Used for tracking if words in a phrase have been listened too. Learn mode only
 * - learnHighlight: Used for highlighting words in learn mode
 * - resultsPage: specific for results page styling
 * - isLastWord: Used for tracking if it is the last word in the phrase
 *
 * @return {JSX.Element} - Rendered word card component
 */
const WordCard = (props: Props): JSX.Element => {
    const {
        PhraseWord,
        dimmed = false,
        blurWords = false,
        audioTime,
        wordEndTime,
        wordType,
        wordIndex,
        iterIndex,
        hasListened,
        learnHighlight = false,
        resultsPage = false,
        isLastWord,
    } = {
        ...props,
    };

    const { pinyin, bothTypes } = useContext(UserContext);
    const { cleanTrans } = useFunction();
    const { isMobile } = useViewport();

    const ref = useRef(null);
    const [isWordModalOpen, setIsWordModalOpen] = useState(false);
    const [blurState, setBlurState] = useState(false);

    const listenMode = wordType == 'listen';
    const learnMode = wordType == 'learn';
    const testMode = wordType == 'test';

    useEffect(() => {
        return () => {
            if (ref) {
                ref.current = null;
            }
        };
    }, []);

    /* Set Blur state for words depending on the mode they are in */
    useEffect(() => {
        /* This if is to control the blur on learnMode */
        if (listenMode) {
            if (blurWords) {
                setBlurState(PhraseWord.word.srsLevel >= 3);
            } else {
                setBlurState(false);
            }
        } else if (learnMode) {
            setBlurState(
                iterIndex === wordIndex ? !hasListened : iterIndex > wordIndex,
            );
        } else if (testMode && blurWords) {
            setBlurState(true);
        }
    }, [blurWords]);

    /**
     * Determines the highlight style for the current word based on audio time and word timing.
     * @return {string} - The CSS class for highlighting (empty string if no highlighting).
     */
    const highlightWords = (): string => {
        // Return an empty string if either audioTime or wordEndTime is falsy
        if (!(audioTime && wordEndTime)) {
            return '';
        }

        // Convert PhraseWord.time to a number
        const wordTime = Number(PhraseWord.time);

        // Check if the current word is the last special word
        const isSpecialLastWord =
            isLastWord &&
            audioTime >= wordTime &&
            audioTime <= wordEndTime + 0.5;

        // Check if the current audio time is within the word timing range
        const isInRange = audioTime >= wordTime && audioTime <= wordEndTime;

        // Determine if the word should be blurred based on blurState and blurWords
        const shouldBlur = blurState && blurWords;

        // Determine the highlight style based on conditions
        if (isSpecialLastWord || isInRange) {
            return shouldBlur ? styles.highlightBlur : styles.highlight;
        }

        // Return an empty string if no highlighting is needed
        return '';
    };

    const hidePinyin = blurWords && blurState && styles.pinyinHide;
    const blurStyle = blurWords && blurState && styles.blur;
    const blurStylePinyin = blurWords && blurState && styles.pinyinBlur;
    const learnModeStyle = learnHighlight && styles.highlightLearnMode;
    const learnModeStylePinyin =
        learnHighlight && styles.highlightLearnModePinyin;

    const pinyinStyles = bothTypes
        ? `${styles.pinyinSmall} 
        ${hidePinyin}
        ${highlightWords()}`
        : `${styles.pinyinBig}
        ${blurStylePinyin}
        ${learnModeStylePinyin}
        ${highlightWords()}`;

    const hanziStyles = `
        ${styles.hanzi}
        ${blurStyle}
        ${learnModeStyle}
        ${highlightWords()}`;

    const translationsStyles =
        blurWords && blurState ? styles.translationBlur : styles.translation;

    if (PhraseWord.markdown) {
        return (
            <>
                <div className={styles.markdownContainer}>
                    {bothTypes && (
                        <div className={styles.pinyinSpacerTop}></div>
                    )}
                    <p
                        className={
                            pinyin ? styles.markdownPinyin : styles.markdown
                        }
                    >
                        {PhraseWord.markdown}
                    </p>
                    {learnMode && wordIndex >= 0 && (
                        <div className={styles.markdownSpacer}></div>
                    )}
                </div>
            </>
        );
    }

    return (
        <>
            <div
                ref={ref}
                className={`
                ${styles.wordContainer} ${resultsPage && styles.resultsPage}`}
                onClick={() => {
                    setIsWordModalOpen(true);
                }}
            >
                {/* Word Pinyin */}
                {(pinyin || bothTypes) &&
                !(bothTypes && blurState && blurWords) ? (
                    <p className={pinyinStyles}>{PhraseWord.word.pinyin}</p>
                ) : (
                    /* Spacer for both setting when word is blurred */
                    <div
                        className={bothTypes ? styles.pinyinSpacerTop : ''}
                    ></div>
                )}

                {/* Word Hanzi Character */}
                {(!pinyin || bothTypes) && (
                    <HanziWord
                        word={PhraseWord.word}
                        delegatedProps={{ className: hanziStyles }}
                    />
                )}

                {/* Word Translation */}
                {learnMode && PhraseWord.word.translation && (
                    <p className={translationsStyles}>
                        {cleanTrans(PhraseWord.word.translation)[0]}
                    </p>
                )}
                {/* Simple definition. If more is clicked it will expand with more definitions */}
            </div>
            {isWordModalOpen && !dimmed && (
                <WordDefModal
                    word={PhraseWord.word}
                    isOpen={isWordModalOpen}
                    toggleClose={() => setIsWordModalOpen(false)}
                    bottom={!isMobile ? 80 : 90}
                />
            )}
        </>
    );
};

export default WordCard;
