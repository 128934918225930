import React from 'react';
import styles from './_styles/LessonTag.module.scss';
import useFunction from '../../useFunction';

interface Props {
    tag: string;
}

/**
 * LessonTag component for rendering a cleaned and translated lesson tag
 * @param {Props} props - Component props including the tag to be displayed
 * @return {JSX.Element} - Rendered LessonTag component
 */
const LessonTag = (props: Props): JSX.Element => {
    const { cleanTag, cleanTrans } = useFunction();

    // Clean and translate the provided tag
    const lessonTag = cleanTag(cleanTrans(props.tag));

    return (
        <div className={styles.tag}>
            <p>{lessonTag}</p>
        </div>
    );
};

export default LessonTag;
