import { useState, useEffect } from 'react';

interface mediaQuery {
    mobile: number;
    medium: number;
    large: number;
    xlarge: number;
    xxlarge: number;
}

interface ViewportSize {
    viewportWidth: number;
    viewportHeight: number;
    mediaQuery: mediaQuery;
    isMobile: boolean;
}

const useViewport = (): ViewportSize => {
    const [viewportWidth, setViewportWidth] = useState<number>(
        window.innerWidth,
    );
    const [viewportHeight, setViewportHeight] = useState<number>(
        window.innerHeight,
    );

    const mediaQuery: mediaQuery = {
        mobile: 576,
        medium: 768,
        large: 992,
        xlarge: 1200,
        xxlarge: 1440,
    };

    const isMobile: boolean = viewportWidth <= mediaQuery.mobile;

    useEffect(() => {
        // eslint-disable-next-line require-jsdoc
        function handleResize() {
            setViewportWidth(window.innerWidth);
            setViewportHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return { viewportWidth, viewportHeight, mediaQuery, isMobile };
};

export default useViewport;
