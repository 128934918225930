import { useState, useEffect } from 'react';
import { Lesson, UserWord } from '../types/api_response';
import LessonInterface from '../components/Pages/Lesson/LessonInterface';
import AudioInterface from '../components/Pages/Lesson/AudioInterface';
import useFetch from '../useFetch';

interface Props {
    lessonId: string;
}

const useBuildLessonData = (props: Props) => {
    const [firstRender, setFirstRender] = useState<boolean>(false);
    const [lessonInterface, setLessonInterface] =
        useState<LessonInterface | null>(null);
    const [audioPlayer, setAudioPlayer] = useState<AudioInterface | null>(null);
    const [audioTime, setAudioTime] = useState<number>(0);
    const { lessonId } = props;
    const { getReq } = useFetch();
    const currentURL = window.location.href;

    useEffect(() => {
        if (firstRender) {
            if (currentURL.endsWith('/Listen')) {
                location.reload();
            }
            setLessonInterface(null);
            setAudioPlayer(null);
            setAudioTime(0);
        }

        getReq(`/lessons/${lessonId}`).then((res: any) => {
            const lesson: Lesson = res.data;
            // Retrieve userWordsData as a map to pass into lessonDataInterface constructor.
            getReq('/user/words').then((userWordsRes: any) => {
                const userWordsMap = new Map<string, number>();
                if (userWordsRes.data) {
                    // filtering out ghost word
                    const userWords: UserWord[] = userWordsRes.data.filter(
                        (word) => {
                            if (word.word.simplified !== '') {
                                return word;
                            }
                        },
                    );
                    userWords.forEach((item) => {
                        userWordsMap.set(
                            item.word.uuid,
                            item.studyData.srsLevel,
                        );
                    });
                }

                // Set the lesson data and audioPlayers so that user can begin studying
                setLessonInterface(new LessonInterface(lesson, userWordsMap));
                const audioElement: HTMLAudioElement = new Audio(
                    lesson.lessonData.audio,
                );
                setAudioPlayer(
                    new AudioInterface(audioElement, (time) =>
                        setAudioTime(time),
                    ),
                );
            });
        });

        setFirstRender(true);
    }, [currentURL]);

    return { lessonInterface, audioPlayer, audioTime };
};

export default useBuildLessonData;
