import React, { useState } from 'react';
import Navbar from './Navbar';
import Header from './Header';
import styles from './_styles/BaseLayout.module.scss';

interface Props {
    children: JSX.Element[];
}

/**
 * BaseLayout component for structuring the main layout of the application.
 * @param {Props} props - The properties passed to the BaseLayout component.
 * @return {JSX.Element} - The rendered BaseLayout component.
 */
const BaseLayout = (props: Props): JSX.Element => {
    // State to manage the visibility of the navigation menu
    const [isNavOpen, setIsNavOpen] = useState<boolean>(false);

    // Extract children from props
    const PageContent = props.children;

    return (
        <div className={styles.gridContainer}>
            {/* Header component with a toggleNav function */}
            <Header toggleNav={() => setIsNavOpen(!isNavOpen)} />

            {/* Navbar component with gridNav type and navigation state */}
            <Navbar
                navType={'gridNav'}
                isNavOpen={isNavOpen}
                setNavOpen={setIsNavOpen}
            />

            {/* Main content area */}
            <div className={styles.gridPageContent}>{PageContent}</div>
        </div>
    );
};

export default BaseLayout;
