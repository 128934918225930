import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import UserContext from '../../../context/UserContext';
import useViewport from '../../../customhooks/useViewport';
import useFunction from '../../../useFunction';
import useAutoPlay from '../../../customhooks/useAutoPlay';
import keyDownEvent from '../../../utils/KeyDownEvent';
import AudioBar from './AudioBar';
import AudioInterface from './AudioInterface';
import ButtonColor from '../../UI-Components/ButtonColor';
import LessonInterface from './LessonInterface';
import LessonResultsList from './LessonResultsList';
import MobileTopBar from '../../GlobalComponents/MobileTopBar';
import PhraseBlock from './PhraseBlock';
import PageNavModal from '../../GlobalComponents/PageNavModal';
import ResultsPage from '../../GlobalComponents/ResultsPage';
import WordCard from './WordCard';
import styles from './_styles/LearnMode.module.scss';

// While this component is fairly behemoth like, it is actually pretty simple:
//  It renders the Phrase container and word cards, and allows the user to iterate through word cards

interface Props {
    lesson: LessonInterface;
    audioPlayer: AudioInterface;
}

/**
 * @fileoverview
 * Page for displaying Learn mode and handles the logic
 *
 * @param {Props} props
 * - lesson: This is the Lesson Interface that handles all the lesson data
 * - audioPlayer: handles the audio interface and playback
 *
 * @return {JSX.Element} - Rendered Learn mode page component
 */
const LearnMode = (props: Props): JSX.Element => {
    const { lesson, audioPlayer } = props;
    const [hasListened, setHasListened] = useState<boolean>(false);
    const [phraseIndex, setPhraseIndex] = useState<number>(0);
    const [blurCheck, setBlurCheck] = useState<boolean>(false);
    const [wordIndex, setWordIndex] = useState<number>(-1);
    const [progress, setProgress] = useState(0);
    const { percentageBar, lessonUrlName } = useFunction();
    const { bothTypes, userSpeed } = useContext(UserContext);
    const { viewportWidth, mediaQuery } = useViewport();
    const lessonName = lessonUrlName(lesson.name);
    const lessonId = useParams();

    const [startTime, endTime] = lesson.getPhrasePlayTime(phraseIndex);

    const playAudio = () => {
        // if word index is -1 then play the section
        if (wordIndex === -1) {
            audioPlayer.changeSource(lesson.audioSrc);
            audioPlayer.setSpeed(userSpeed);
            audioPlayer.playSection(startTime, endTime);
        } else {
            // when index > -1 it means that the user is in the word breakdown and will play individual words
            const url = `https://storage.googleapis.com/production_word_audio_files/${lesson.phrases[phraseIndex].words[wordIndex].simplified}.wav`;
            const wordAudio = new Audio(url);
            wordAudio.playbackRate = userSpeed;
            if (wordAudio.paused) {
                wordAudio.play();
            } else {
                wordAudio.pause();
            }
        }
    };

    // handles autoplay
    const { isFinished } = useAutoPlay({
        playAudio: () => playAudio(),
        autoPlayVar: [phraseIndex, wordIndex],
        audioPlayer: audioPlayer,
        lessonFinish: progress === lesson.phrases.length,
        lessonFinishVar: [progress],
    });

    const instructionMessage = () => {
        if (wordIndex == -1) {
            if (!hasListened) {
                return 'Listen and say the meaning out loud';
            } else {
                return 'Did you understand the meaning?';
            }
        } else {
            return 'Listen to each word to check your comprehension';
        }
    };

    // Most of the behemoth logic for this component happens here.
    const handleClick = (isSuccess) => {
        // Check if the current word index is -1 (initial state)
        if (wordIndex === -1) {
            // Handle the case when isSuccess is null
            if (isSuccess === null) {
                // Added to show the word after checking
                setBlurCheck(true);
                return setHasListened(true);
            } else if (isSuccess === true) {
                // If the user successfully answered the word
                setBlurCheck(false);
                setProgress((p) => p + 1);

                // Check if there is another word in the current phrase
                if (lesson.hasNextPhrase(phraseIndex)) {
                    setPhraseIndex((p) => p + 1);
                }
            } else if (isSuccess === false) {
                // If the user failed to answer the word, move to the next word
                setBlurCheck(false);
                setWordIndex((w) => w + 1);
            }
            return setHasListened(false);
        } else {
            // Handle the case when the current word index is not -1
            if (isSuccess === null) {
                // Set hasListened to true if isSuccess is null
                return setHasListened(true);
            }
            // Determine the next word or phrase to test
            if (lesson.hasNextWord(phraseIndex, wordIndex)) {
                // Check if the next word has markdown formatting
                if (lesson.phrases[phraseIndex].words[wordIndex + 1].markdown) {
                    // If it has markdown, check if there is another word after the next one
                    if (lesson.hasNextWord(phraseIndex, wordIndex + 1)) {
                        setWordIndex((w) => w + 2);
                    } else if (lesson.hasNextPhrase(phraseIndex)) {
                        // Move to the next phrase if there are no more words in the current phrase
                        setPhraseIndex((p) => p + 1);
                        setProgress((p) => p + 1);
                        setWordIndex(-1);
                    } else {
                        // If no more phrases, increment progress
                        setProgress((p) => p + 1);
                    }
                } else {
                    // If the next word does not have markdown, move to the next word
                    setWordIndex((w) => w + 1);
                }
            } else if (lesson.hasNextPhrase(phraseIndex)) {
                // If there are no more words in the current phrase, move to the next phrase
                setProgress((p) => p + 1);
                setPhraseIndex((p) => p + 1);
                setWordIndex(-1);
            } else {
                // If there are no more phrases, increment progress
                setProgress((p) => p + 1);
            }
            return setHasListened(false);
        }
    };

    const wordbreakdown = wordIndex !== -1;
    const LearnPhraseStyle = bothTypes && styles.phraseContainerBothTypes;
    const progressBar = percentageBar(progress, lesson.phrases.length);
    const buttonHeight = '56px';

    // handles the hotkeys
    const { handleKeyPress } = keyDownEvent({
        upArrow: playAudio,
        rightArrow: () => (hasListened ? handleClick(true) : handleClick(null)),
        leftArrow: () =>
            wordbreakdown
                ? hasListened
                    ? handleClick(true)
                    : handleClick(null)
                : handleClick(false),
    });

    return (
        <>
            {!isFinished ? (
                <div
                    className={styles.pageGrid}
                    onKeyDown={handleKeyPress}
                    tabIndex={0}
                >
                    <input autoFocus className={styles.pageRef} />
                    {/* Used only for mobile screen sizes */}
                    <MobileTopBar
                        lessonName={lesson.name}
                        studyData={lesson.studyData}
                        lessonId={lessonId.lessonId}
                        audioPlayer={audioPlayer}
                    />
                    <main className={styles.lessonContent}>
                        <div
                            className={
                                styles.phraseContainer + ' ' + LearnPhraseStyle
                            }
                        >
                            <h2 className={styles.instructionMessage}>
                                {instructionMessage()}
                            </h2>
                            {/* This block handles the lesson phrases and word breakdown */}
                            {progress !== lesson.phrases.length && (
                                <PhraseBlock
                                    playButton={true}
                                    togglePlayAudio={() => playAudio()}
                                >
                                    {lesson.phrases[phraseIndex].words.map(
                                        (PhraseWord, iterIndex) => {
                                            const activeWord =
                                                wordIndex == iterIndex;

                                            const learnModeShowWord =
                                                wordIndex > iterIndex ||
                                                (activeWord && hasListened);

                                            return (
                                                <>
                                                    <div
                                                        key={PhraseWord.uuid}
                                                        className={
                                                            learnModeShowWord
                                                                ? styles.showWrapper
                                                                : ''
                                                        }
                                                    >
                                                        <WordCard
                                                            PhraseWord={
                                                                PhraseWord
                                                            }
                                                            wordIndex={
                                                                wordIndex
                                                            }
                                                            iterIndex={
                                                                iterIndex
                                                            }
                                                            hasListened={
                                                                hasListened
                                                            }
                                                            learnHighlight={
                                                                activeWord
                                                            }
                                                            wordType={'learn'}
                                                            blurWords={
                                                                !blurCheck &&
                                                                (!hasListened
                                                                    ? iterIndex >=
                                                                      wordIndex
                                                                    : iterIndex >
                                                                      wordIndex)
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            );
                                        },
                                    )}
                                </PhraseBlock>
                            )}
                            <div className={styles.fillerBlock}></div>
                        </div>

                        {/* HANDLES THE TRANSLATION */}
                        <div className={styles.bottom}>
                            <div className={styles.translation}>
                                {/* Show the translation if user checked or user is in word break down */}
                                <h4
                                    className={
                                        hasListened || wordIndex != -1
                                            ? styles.transVisible
                                            : styles.transHidden
                                    }
                                >
                                    {lesson.phrases[phraseIndex].translation}
                                </h4>
                            </div>

                            <div className={styles.testbuttons}>
                                {/* Check if the user understands the word */}
                                {!hasListened ? (
                                    <ButtonColor
                                        height={buttonHeight}
                                        width={'100%'}
                                        color={'orange'}
                                        onClick={() => handleClick(null)}
                                    >
                                        Check
                                    </ButtonColor>
                                ) : /* Show check and continue when doing the word breakdown */
                                wordIndex !== -1 ? (
                                    <ButtonColor
                                        height={buttonHeight}
                                        width={'100%'}
                                        color={'orange'}
                                        onClick={() => handleClick(true)}
                                    >
                                        Continue
                                    </ButtonColor>
                                ) : (
                                    /* Ask user if they understand or do word break down */
                                    hasListened && (
                                        <>
                                            <ButtonColor
                                                height={buttonHeight}
                                                color={'orange'}
                                                onClick={() =>
                                                    handleClick(true)
                                                }
                                            >
                                                I understand
                                            </ButtonColor>

                                            <ButtonColor
                                                height={buttonHeight}
                                                color={'orangeClear'}
                                                onClick={() =>
                                                    handleClick(false)
                                                }
                                            >
                                                Word breakdown
                                            </ButtonColor>
                                        </>
                                    )
                                )}
                            </div>
                        </div>
                    </main>
                    {/* Handles navigating to other lessons */}
                    <PageNavModal
                        openButton={true}
                        studyData={lesson.studyData}
                        lessonId={lessonId.lessonId}
                        lessonName={lessonName}
                    />
                    {/* Show audio bar if bigger than a mobile screen size */}
                    {viewportWidth > mediaQuery.mobile && (
                        <AudioBar
                            audioPlayer={audioPlayer}
                            audioControls={false}
                            isBlurred={false}
                            progressBarValue={progressBar}
                            handlePlay={() => playAudio()}
                        />
                    )}
                </div>
            ) : (
                <>
                    {/* purposely adding both sresults props to equal a negative number */}
                    <ResultsPage
                        correctResults={-0.5}
                        totalResults={0.5}
                        studyData={lesson.studyData}
                        lessonId={lessonId.lessonId}
                        lessonName={lesson.name}
                        lessonType={'learn'}
                    >
                        <LessonResultsList
                            lesson={lesson} // Lesson was altered, so it may matter here as well
                            audioPlayer={audioPlayer}
                        />
                    </ResultsPage>
                </>
            )}
        </>
    );
};

export default LearnMode;
