import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useOutsideClick from '../../customhooks/onOutsideClick'; // Importing a custom hook for handling clicks outside the component
import profileImg from '../../../public/Images/blank-profile.png'; // Placeholder image
import WordSettingsModal from './WordSettingsModal';
import VideoModal from './VideoModal'; // Importing VideoModal component
import styles from './_styles/UserDropdownMenu.module.scss';

/**
 * @fileoverview - display the user drop down menu to access user settings or sign out
 * @param {Props} props
 * @return {JSX.Element} - Rendered user menu component
 */
const UserDropdownMenu = (): JSX.Element => {
    const [isVideoOpen, setToggleVideo] = useState<boolean>(false); // State for toggling the video modal
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false); // State for toggling the settings modal
    const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false); // State for toggling the user menu
    const userDropDownRef = useRef(null); // Ref for the user menu dropdown
    const history = useHistory(); // History hook for navigation

    // Custom hook to handle clicks outside the user menu dropdown
    useOutsideClick(userDropDownRef, () => {
        if (isUserMenuOpen) setIsUserMenuOpen(false);
    });

    // Function to handle user logout
    const logOut = () => {
        localStorage.removeItem('token'); // Removing token from local storage
        localStorage.removeItem('user'); // Removing user information from local storage
        history.push('/login'); // Redirecting to the login page
        window.location.reload(); // Reloading the window
    };

    return (
        <>
            {/* User profile picture with click event to toggle the user menu */}
            <img
                className={styles.profilePic}
                src={profileImg}
                alt="user-image"
                onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
            />

            {/* User menu content */}
            {isUserMenuOpen && (
                <menu ref={userDropDownRef} className={styles.menu}>
                    {/* User information section */}
                    <div className={styles.userInfo}>
                        <img src={profileImg} />
                    </div>

                    {/* Options list in the user menu */}
                    <div className={styles.optionsList}>
                        {/* Link to submit a bug report */}
                        <a
                            href="https://airtable.com/shrcbuW7FIPdT9k2f"
                            target="_"
                        >
                            Submit Bug Report
                        </a>
                        {/* Click event to toggle settings modal and close user menu */}
                        <p
                            onClick={() => {
                                setIsSettingsOpen(!isSettingsOpen);
                                setIsUserMenuOpen(false);
                            }}
                        >
                            Settings
                        </p>
                        {/* Click event to toggle video modal and close user menu */}
                        <p
                            onClick={() => {
                                setToggleVideo(!isVideoOpen);
                                setIsUserMenuOpen(false);
                            }}
                        >
                            Tutorial Video
                        </p>

                        {/* Click event to handle user logout */}
                        <p onClick={() => logOut()}>Logout</p>
                    </div>
                </menu>
            )}

            {/* Video modal component */}
            <VideoModal
                isOpen={isVideoOpen}
                toggleClose={() => setToggleVideo(false)}
                isTutorial={true}
                src={
                    'https://storage.googleapis.com/public_random_files/EM%20Onboarding%20App.mp4'
                }
            />

            {/* Settings modal component */}
            {isSettingsOpen && (
                <WordSettingsModal
                    toggleClose={() => setIsSettingsOpen(false)}
                    top={68}
                    right={16}
                    fcMode={false}
                />
            )}
        </>
    );
};

export default UserDropdownMenu;
