import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useFunction from '../../../useFunction';
import deleteButton from '../../../../public/Images/trash.svg';
import styles from './_styles/AdminTableList.module.scss';
import ButtonColor from '../../UI-Components/ButtonColor';
import WarningModal from './WarningModal';

interface Props {
    isLessonTable: boolean;
    tableData: any;
    resultsLength: number;
    handleDelete?: CallableFunction;
}

/**
 * @fileoverview
 * used for displaying lists of lessons, resources, ect. in the admin pages
 *
 * @param {Props} props
 * - isLessonTable: Boolean indicating whether it's a lesson table
 * - tableData: Data to be displayed in the table
 * - resultsLength: Number of results
 * - handleDelete: Optional function for handling delete action
 *
 * @return {JSX.Element} - Rendered admin table list
 */

const AdminTableList = (props: Props): JSX.Element => {
    const { isLessonTable, tableData, handleDelete, resultsLength } = props;
    // State for controlling the number of items to show
    const [showMore, setShowMore] = useState<number>(15);

    return (
        <div className={styles.listContainer}>
            {/* Table structure */}
            <table className={styles.lessonTable}>
                <thead>
                    {/* Table header */}
                    <tr>
                        <th className={styles.titleHeader}>Title</th>
                        <th>Tag</th>
                        <th>Last Update</th>
                        {isLessonTable && (
                            <>
                                <th>Status</th>
                                <th>Image</th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody className={styles.lessonList}>
                    {/* Mapping over tableData to render TableRow components */}
                    {tableData.map((rowData, i) => {
                        if (i < showMore) {
                            return (
                                <>
                                    <TableRow
                                        isLessonTable={isLessonTable}
                                        rowData={rowData}
                                        handleDelete={handleDelete}
                                    />
                                </>
                            );
                        }
                    })}
                </tbody>
            </table>
            {/* Show more button */}
            {resultsLength > 15 && resultsLength >= showMore && (
                <footer className={styles.moreButton}>
                    <ButtonColor
                        height={'56px'}
                        width={'150px'}
                        color={'orange'}
                        onClick={() => setShowMore(showMore + 15)}
                    >
                        show more
                    </ButtonColor>
                </footer>
            )}
        </div>
    );
};

interface RowProps {
    isLessonTable: boolean;
    rowData: any;
    handleDelete?: CallableFunction;
}

const TableRow = (props: RowProps): JSX.Element => {
    const { isLessonTable, rowData, handleDelete } = props;

    const history = useHistory();
    const { cleanTrans } = useFunction();

    // State for handling delete functionality
    const [deleteParam, setDeleteParam] = useState<string>('');
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [confirmDeleteModal, setConfirmDeleteModal] =
        useState<boolean>(false);

    // Extracting data based on the table type
    const tags = isLessonTable ? rowData.lessonData.tags : rowData.tags;
    const uuid: string = isLessonTable ? rowData.lessonData.uuid : rowData.uuid;
    const title: string = isLessonTable
        ? rowData.lessonData.name
        : rowData.title;

    // Handling click event to navigate to edit page
    const handleClick = (lessonId) => {
        isLessonTable
            ? history.push(`/admin/edit-timing/${lessonId}`)
            : history.push(`/admin/edit-grammar`, rowData);
    };

    // Formatting updated time
    const updatedTime = (time: string) => {
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        return cleanTrans(new Date(time).toLocaleDateString('en-US', options));
    };

    return (
        <>
            {/* Table row */}
            <tr className={styles.listItem} key={uuid}>
                {/* Title column */}
                <td className={styles.title} onClick={() => handleClick(uuid)}>
                    {title}
                </td>

                {/* Tag column */}
                <td onClick={() => handleClick(uuid)}>{cleanTrans(tags)}</td>

                {/* Last Update column */}
                <td onClick={() => handleClick(uuid)}>
                    {isLessonTable
                        ? updatedTime(rowData.lessonData.updatedAt)
                        : updatedTime(rowData.updatedAt)}
                </td>

                {/* Additional columns for lesson table */}
                {isLessonTable && (
                    <>
                        <td onClick={() => handleClick(uuid)}>
                            {rowData.lessonData.status}
                        </td>
                        <td
                            onClick={() => handleClick(uuid)}
                            className={styles.lessonImg}
                        >
                            <img src={rowData.lessonData.image} />
                        </td>
                    </>
                )}

                {/* Delete Lesson Button */}
                {handleDelete && (
                    <td
                        className={styles.deleteButton}
                        onClick={() => {
                            setDeleteModal(true);
                            setDeleteParam(uuid);
                        }}
                    >
                        <img src={deleteButton} />
                    </td>
                )}
            </tr>
            {/* Delete warning modals */}
            {handleDelete && (
                <>
                    <WarningModal
                        show={deleteModal}
                        messageType={'delete'}
                        toggleContinue={() => setConfirmDeleteModal(true)}
                        toggleClose={() => {
                            setDeleteModal(false);
                        }}
                    />
                    <WarningModal
                        show={confirmDeleteModal}
                        messageType={'deletewarning'}
                        toggleContinue={() => handleDelete(deleteParam)}
                        toggleClose={() => {
                            setConfirmDeleteModal(false);
                            setDeleteParam('');
                        }}
                    />
                </>
            )}
        </>
    );
};

export default AdminTableList;
