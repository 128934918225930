import React, { useState } from 'react';
import { LessonStudyData } from '../../types/api_response';
import { Link } from 'react-router-dom';
import useFunction from '../../useFunction';
import AudioInterface from '../Pages/Lesson/AudioInterface';
import CheveronLeft from '../../../public/Images/chevron-left-black.svg';
import LessonSettingsMobile from '../Pages/Lesson/LessonSettingsMobile';
import MoreButton from '../../../public/Images/more-vertical.svg';
import WordSettingsModal from './WordSettingsModal';
import styles from './_styles/MobileTopBar.module.scss';

interface Props {
    audioPlayer?: AudioInterface;
    studyData?: LessonStudyData;
    lessonName?: string;
    lessonId?: string;
    fcMode?: boolean;
    color?: string;
    fcSettings?: CallableFunction;
}

/**
 * Component for rendering the mobile top bar with navigation and settings options.
 * @param {Props} props - The component props.
 * @return {JSX.Element} - The rendered component.
 */
const MobileTopBar = (props: Props) => {
    const [toggleSettings, setToggleSettings] = useState<boolean>(false);
    const {
        audioPlayer,
        lessonId,
        studyData,
        lessonName,
        fcMode = false,
        color,
        fcSettings,
    } = props;
    const { lessonUrlName } = useFunction();

    // Generate a formatted lesson name for URLs
    const lessonNameStr = !fcMode ? lessonUrlName(lessonName) : '';

    return (
        <>
            {/* Mobile top bar */}
            <div className={styles.topBar}>
                {/* Top section containing navigation and lesson title */}
                <div className={`${styles.top} ${styles[color]}`}>
                    <div>
                        {/* Return button linking back to the dashboard or user words */}
                        <Link
                            to={!fcMode ? '/dashboard' : '/userwords'}
                            className={
                                color !== 'orange'
                                    ? styles.returnButton
                                    : styles.returnButtonWht
                            }
                        >
                            <img src={CheveronLeft} />
                        </Link>
                        {/* Lesson title */}
                        <h4
                            className={
                                color !== 'orange'
                                    ? styles.lessonTitle
                                    : styles.lessonTitleWht
                            }
                        >
                            {!fcMode ? lessonName : 'Vocab'}
                        </h4>
                    </div>
                    {/* More button for opening settings */}
                    {color !== 'orange' && (
                        <img
                            className={
                                color !== 'orange'
                                    ? styles.moreButton
                                    : styles.moreButtonWht
                            }
                            src={MoreButton}
                            onClick={() => setToggleSettings(true)}
                        />
                    )}
                </div>

                {/* Bottom section for styling */}
                <div className={`${styles.bottom} ${styles[color]}`}>
                    <div className={styles.bottomBg}></div>
                </div>
            </div>

            {/* Render LessonSettingsMobile or WordSettingsModal based on mode */}
            {toggleSettings && !fcMode && (
                <LessonSettingsMobile
                    toggleClose={() => setToggleSettings(!toggleSettings)}
                    studyData={studyData}
                    lessonId={lessonId}
                    lessonName={lessonNameStr}
                    audioPlayer={audioPlayer}
                    fcMode={fcMode}
                />
            )}
            {toggleSettings && fcMode && (
                <WordSettingsModal
                    fcMode={fcMode}
                    mobileStyle={true}
                    toggleClose={() => setToggleSettings(!toggleSettings)}
                    fcSettings={fcSettings}
                />
            )}
        </>
    );
};

export default MobileTopBar;
