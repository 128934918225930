import React, { useState } from 'react';
import { UserWord, WordType } from '../../../types/api_response';
import ButtonColor from '../../UI-Components/ButtonColor';
import WordListItem from './WordListItem';
import styles from './_styles/WordList.module.scss';

interface Props {
    userWords: UserWord[];
    modalControl?: boolean;
    lessonResultsData?: boolean[] | undefined;
    wordType: WordType['wordType'];
}

const WordListNew = (props: Props) => {
    const {
        userWords,
        lessonResultsData,
        modalControl = false,
        wordType,
    } = props;
    const [showMore, setShowMore] = useState<number>(50);
    const resultsPageBoolean = lessonResultsData !== undefined;

    const handleAudio = (wordName) => {
        const audio = new Audio(
            `https://storage.googleapis.com/production_word_audio_files/${wordName}.wav`,
        );
        if (audio.paused) {
            audio.play();
        } else {
            audio.pause();
            audio.currentTime = 0;
        }
    };

    const wordList: UserWord[] =
        userWords.length > 1
            ? [...userWords].slice(1, userWords.length)
            : userWords;

    const firstWord: UserWord = [...userWords].shift();

    return (
        <>
            {resultsPageBoolean ? (
                <>
                    {userWords.map((word, i) => {
                        if (i < showMore)
                            return (
                                <>
                                    <WordListItem
                                        key={word.word.uuid}
                                        word={word}
                                        style={'wordlist'}
                                        wordType={wordType}
                                        modalStyle={modalControl}
                                        resultsPage={resultsPageBoolean}
                                        lessonResultsData={lessonResultsData[i]}
                                        toggleAudio={() => {
                                            handleAudio(word.word.simplified);
                                        }}
                                    />
                                </>
                            );
                    })}
                </>
            ) : (
                <ul
                    className={
                        !modalControl
                            ? styles.wordRows
                            : styles.wordRows + ' ' + styles.modalStyle
                    }
                >
                    {
                        <WordListItem
                            style={'firstword'}
                            word={firstWord}
                            modalStyle={modalControl}
                            resultsPage={resultsPageBoolean}
                            toggleAudio={() => {
                                handleAudio(firstWord.word.simplified);
                            }}
                            lessonResultsData={
                                resultsPageBoolean && lessonResultsData[0]
                            }
                            wordType={wordType}
                        />
                    }
                    {userWords.length > 1 &&
                        wordList.map((word, i) => {
                            if (i < showMore)
                                return (
                                    <>
                                        <WordListItem
                                            key={word.word.uuid}
                                            word={word}
                                            style={'wordlist'}
                                            modalStyle={modalControl}
                                            resultsPage={resultsPageBoolean}
                                            wordType={wordType}
                                            toggleAudio={() => {
                                                handleAudio(
                                                    word.word.simplified,
                                                );
                                            }}
                                            lessonResultsData={
                                                resultsPageBoolean &&
                                                lessonResultsData[i]
                                            }
                                        />
                                    </>
                                );
                        })}

                    {userWords.length > showMore && (
                        <footer className={styles.showMore}>
                            <ButtonColor
                                height={'56px'}
                                width={'150px'}
                                color={'orange'}
                                onClick={() => setShowMore(showMore + 50)}
                            >
                                show more
                            </ButtonColor>
                        </footer>
                    )}
                </ul>
            )}
        </>
    );
};

export default WordListNew;
