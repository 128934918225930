import React, { useState } from 'react';
import { UserWord, WordType } from '../../../types/api_response';
import useViewport from '../../../customhooks/useViewport';
import useFunction from '../../../useFunction';
import CloseButton from '../../../../public/Images/ClearButtonX.svg';
import WordList from './WordList';
import WordListFilter from './WordListFilter';
import WordFilterOption from './WordFilterOption';
import WordListTypeToggle from './WordListTypeToggle';
import styles from './_styles/WordListModal.module.scss';
import Modal from '../Modal';

interface Props {
    isOpen: boolean;
    setWordType: CallableFunction;
    toggleClose: CallableFunction;
    wordType: WordType['wordType'];
    userWords: UserWord[];
}

const WordListModal = (props: Props): JSX.Element => {
    const { toggleClose, userWords, isOpen, setWordType, wordType } = props;
    const { viewportWidth, mediaQuery } = useViewport();
    const [sortParam, setSortParam] = useState<string>('All');
    const { wordSort } = useFunction();

    const list1 = [...wordSort(userWords, sortParam)].slice(
        0,
        userWords.length / 2,
    );
    const list2 = [...wordSort(userWords, sortParam)].slice(
        userWords.length / 2,
        userWords.length,
    );

    return (
        <Modal
            className={styles.wordList}
            isOpen={isOpen}
            toggleClose={toggleClose}
            // backdropStyle={backdropStyle}
        >
            <img
                src={CloseButton}
                className={styles.closeButton}
                onClick={() => toggleClose()}
            />
            <div className={styles.modalHeader}>
                <WordListFilter
                    filterParam={sortParam}
                    modalStyle={true}
                    wordType={wordType}
                    setWordType={setWordType}
                >
                    <WordFilterOption
                        setParam={() => setSortParam('All')}
                        filterParam={sortParam}
                        value={'All'}
                        topStyle={true}
                        modalStyle={true}
                    />
                    <WordFilterOption
                        setParam={() => setSortParam('Recent')}
                        filterParam={sortParam}
                        value={'Recent'}
                        modalStyle={true}
                    />
                    <WordFilterOption
                        setParam={() => setSortParam('Frequency')}
                        filterParam={sortParam}
                        value={'Frequency'}
                        bottomStyle={true}
                        modalStyle={true}
                    />
                </WordListFilter>
                {viewportWidth > mediaQuery.mobile && (
                    <WordListTypeToggle
                        english={false}
                        wordType={wordType}
                        setWordType={setWordType}
                    />
                )}
            </div>
            <div className={styles.list}>
                {viewportWidth > mediaQuery.medium + 80 &&
                userWords.length > 20 ? (
                    <>
                        <div className={styles.duallist}>
                            <WordList
                                userWords={list1}
                                modalControl={true}
                                wordType={wordType}
                            />
                            <WordList
                                userWords={list2}
                                modalControl={true}
                                wordType={wordType}
                            />
                        </div>
                    </>
                ) : (
                    <WordList
                        userWords={wordSort(userWords, sortParam)}
                        modalControl={true}
                        wordType={wordType}
                    />
                )}
            </div>
        </Modal>
    );
};

export default WordListModal;
