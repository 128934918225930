import React, { useState, useEffect, createContext } from 'react';
import { userObject } from '../types/api_response';
import useFetch from '../useFetch';

const UserContext = createContext<UserContextType | null>(null);

interface UserContextType {
    userObject: userObject;
    pinyin: boolean;
    bothTypes: boolean;
    currentWeek: string;
    mandarinType: boolean;
    userSpeed: number;
    autoPlay: boolean;
    setUserObject: CallableFunction;
    setUserSpeed: CallableFunction;
    setAutoPlay: CallableFunction;
}

// eslint-disable-next-line react/prop-types
export const UserProvider = ({ children }): JSX.Element => {
    const defaultUserData: userObject = {
        characterType: 'Simplified',
        darkMode: 'light',
        textMode: 'Both',
        currentClassAndUnit: '|EM Q1 W1|',
        goals: {
            dailyNewVocab: 0,
            dailyVocabReviewed: 0,
        },
    };
    const { putReq, getReq } = useFetch();
    const [renderCheck, setRenderCheck] = useState<boolean>(true);
    const [userSpeed, setUserSpeed] = useState<number>(1);
    const [autoPlay, setAutoPlay] = useState<boolean>(true);
    const [userObject, setUserObject] = useState<userObject>(defaultUserData);
    const hasAuth = localStorage.getItem('auth0Token');

    useEffect(() => {
        if (hasAuth === null) {
            return;
        } else {
            getReq('/user').then((res: any) => {
                const userData: userObject = res.data.preferences;
                const isDefault: boolean =
                    userData.currentClassAndUnit === undefined ||
                    userData.currentClassAndUnit === '';

                if (isDefault) {
                    setUserObject(defaultUserData);
                } else {
                    setUserObject(userData);
                }
            });
        }
    }, [hasAuth]);

    const pinyin: boolean = userObject.textMode == 'Pinyin';
    const bothTypes: boolean = userObject.textMode == 'Both';
    const mandarinType: boolean = userObject.characterType == 'Simplified';
    const currentWeek: string = userObject.currentClassAndUnit;

    const updateUser = (update) => {
        putReq('/user', update);
    };

    // Render Check is used to insure that useEffect doesn't update the user on the initial render
    useEffect(() => {
        if (renderCheck === true) {
            setRenderCheck(false);
        } else {
            updateUser(userObject);
        }
    }, [userObject]);

    return (
        <UserContext.Provider
            value={{
                userObject,
                pinyin,
                bothTypes,
                mandarinType,
                currentWeek,
                userSpeed,
                autoPlay,
                setUserObject,
                setUserSpeed,
                setAutoPlay,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
