import React, { useState, useEffect, FormEventHandler } from 'react';
import {
    Lesson,
    LessonObj,
    updateLessonObj,
} from '../../../types/api_response';
import useFunction from '../../../useFunction';
import AddTag from './AddTag';
import ButtonColor from '../../UI-Components/ButtonColor';
import FilesUploader from './FilesUploader';
import FormInput from './FormInput';
import WarningModal from './WarningModal';
import styles from './_styles/EditForm.module.scss';

interface Props {
    lesson?: Lesson | undefined;
    handleSubmit?: FormEventHandler;
    lessonObj: LessonObj | updateLessonObj;
    setLessonObj: any;
    children: any;
}

// eslint-disable-next-line valid-jsdoc
/**
 * @fileoverview
 * Form for creating and editing lessons
 *
 * @param {Props} props
 * - lesson: lesson data is passed in if editing a lesson
 * - handleSubmit: logic for sumbitting lesson and saving data
 * - lessonObj: the data that is going to be editted such as name, description, lessonImage, ect.
 * - setLessonObj: save the new changes to the lessonObj
 *
 * @return {JSX.Element} - Rendered edit lesson form
 */
const EditForm = (props: Props): JSX.Element => {
    const { lesson, handleSubmit, children, lessonObj, setLessonObj } = props;
    const isCreateMode = lesson === undefined;
    const { cleanTrans } = useFunction();

    const [tagsArr, setTagsArr] = useState<string[]>(
        isCreateMode ? ['EM', ''] : cleanTrans(lessonObj.tags)[0].split(' '),
    );
    const [lessonImage, setLessonImage] = useState<File | null>(null);
    const [lessonAudio, setLessonAudio] = useState<File | null>(null);
    const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);

    // Update lesson object when related state variables change
    useEffect(() => {
        setLessonObj((prev) => ({
            ...prev,
            lessonImage: lessonImage,
            lessonAudio: lessonAudio,
            tags: tagsArr.join(' '),
        }));
    }, [lessonImage, lessonAudio, tagsArr]);

    // Test lesson link
    const testlistenMode = `/lesson/${lesson?.lessonData.uuid}/${lesson?.lessonData.name}/Listen`;

    return (
        <>
            {/* Lesson form */}
            <form
                className={styles.createForm}
                onSubmit={
                    isCreateMode
                        ? handleSubmit
                        : (e) => {
                              e.preventDefault();
                          }
                }
            >
                {/* Display lesson title for non-create mode */}
                {!isCreateMode && (
                    <div className={styles.lessonTitle}>
                        <h4>{lesson.lessonData.name}</h4>
                    </div>
                )}

                {/* Lesson metadata editor */}
                <div className={styles.lessonMetaEditor}>
                    <div className={!isCreateMode && styles.editorFlex}>
                        {/* Input fields for lesson details */}
                        <FormInput
                            id={'title'}
                            label={'Title'}
                            value={lessonObj.name}
                            maxLength={50}
                            placeHolder="Enter name of the lesson: Max 50 characters"
                            updateValue={(e) => {
                                setLessonObj((prev) => ({
                                    ...prev,
                                    name: e.target.value,
                                }));
                            }}
                        />
                        <FormInput
                            id={'description'}
                            label={'Description'}
                            value={lessonObj.description}
                            maxHeight={62}
                            maxLength={160}
                            placeHolder="Brief description of the lesson: Max 160 characters"
                            updateValue={(e) => {
                                setLessonObj((prev) => ({
                                    ...prev,
                                    description: e.target.value,
                                }));
                            }}
                        />
                        <div className={styles.bottomForm}>
                            {/* Tag input and Test Lesson button */}
                            <AddTag tagsArr={tagsArr} setTagsArr={setTagsArr} />
                            {!isCreateMode && (
                                <ButtonColor
                                    height={'38px'}
                                    width={'156px'}
                                    color={'orange'}
                                    onClick={() => setIsWarningOpen(true)}
                                >
                                    Test Lesson
                                </ButtonColor>
                            )}
                        </div>
                    </div>

                    {/* Files uploader for lesson images and audio */}
                    <FilesUploader
                        imageFile={lessonImage}
                        audioFile={lessonAudio}
                        handleImageFile={(e) => setLessonImage(e)}
                        handleAudioFile={(e) => setLessonAudio(e)}
                        lessonImg={lesson?.lessonData.image}
                    />
                </div>

                {/* Render additional components passed as children */}
                {children}
            </form>

            {/* Warning modal for test lesson */}
            <WarningModal
                show={isWarningOpen}
                messageType={'warning'}
                toggleContinueLink={testlistenMode}
                toggleClose={() => {
                    setIsWarningOpen(false);
                }}
            />
        </>
    );
};

export default EditForm;
