import React from 'react';
import { Link } from 'react-router-dom';
import styles from './_styles/ButtonColor.module.scss';

interface Props {
    children: any;
    color: string;
    width?: string;
    height?: string;
    fontsize?: string;
    fontweight?: string;
    margin?: string;
    link?: any;
    disable?: boolean;
    type?: any;
    onClick?: (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => void | CallableFunction;
}

/**
 * ButtonColor component for rendering a styled button with optional link
 * @param {Props} props - Component props including children elements, style options, and link
 * @return {JSX.Element} - Rendered ButtonColor component
 */
const ButtonColor = ({
    children,
    color,
    width = '130px',
    height = '35px',
    fontsize = '16px',
    fontweight = '700',
    margin = '',
    link = '',
    disable = false,
    type = 'button',
    ...props
}: Props): JSX.Element => {
    const style = {
        minWidth: width,
        minHeight: height,
        fontSize: fontsize,
        margin: margin,
        fontWeight: fontweight,
    };

    /*

    types:
    blue
    blueClear
    orange
    orangeClear
    block

    */
    const disableButton = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (color === 'block') {
            e.preventDefault();
        }
    };

    return (
        <>
            {link === '' ? (
                <button
                    disabled={disable}
                    style={style}
                    className={`${styles.wrapper} ${styles[color]}`}
                    type={type}
                    {...props}
                    // onClick={(e) => disableButton(e)}
                >
                    {children}
                </button>
            ) : (
                <Link
                    style={style}
                    className={`${styles.wrapper} ${styles[color]}`}
                    to={link}
                    onClick={(e) => disableButton(e)}
                >
                    {children}
                </Link>
            )}
        </>
    );
};

export default ButtonColor;
