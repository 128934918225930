import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import useFetch from '../../../useFetch';
import AddTag from './AddTag';
import ButtonColor from '../../UI-Components/ButtonColor';
import FormInput from './FormInput';
import GrammarResource from '../Resources/GrammarResource';
import WarningModal from './WarningModal';
import styles from './_styles/CreateResourceForm.module.scss';
import { Resource } from '../../../types/api_response';
interface GrammarStructure {
    title: string;
    description: string;
    structure: string;
    resourceURI: string;
    tags: string;
    uuid?: string;
}

/**
 *
 * @fileoverview
 * This component is used for creating and editing grammar resources
 *
 * @return {JSX.Element} - Rendered resource editor component
 */
const EditResources = (): JSX.Element => {
    const blankResource: Resource = {
        title: '',
        description: '',
        structure: '',
        resourceURI: '',
        tags: 'EM',
    };

    const location = useLocation();
    const grammarStucture: GrammarStructure = location.state;
    const { postReq, putReq } = useFetch();
    const [status, setStatus] = useState<string>('idle');
    const [isSuccessOpen, setIsSuccessOpen] = useState<boolean>(false);
    const [isMissingOpen, setIsMissingOpen] = useState<boolean>(false);
    const [tagsArr, setTagsArr] = useState<string[]>(
        grammarStucture ? grammarStucture.tags.split(' ') : ['EM', ''],
    );
    const [grammarObj, setGrammarObj] = useState<Resource>(
        grammarStucture ? grammarStucture : blankResource,
    );

    // Handling form submission
    const handleSubmit = (event) => {
        event.preventDefault();

        // Joining tags array into a single string
        const tagsJoined: string = tagsArr.join(' ');

        // Checking if URL is empty
        const urlIsEmpty: boolean = grammarObj.resourceURI === '';

        // Creating the grammar object to be submitted
        const submitGrammarObj: GrammarStructure = {
            title: grammarObj.title,
            description: grammarObj.description,
            structure: grammarObj.structure,
            resourceURI: urlIsEmpty ? ' ' : grammarObj.resourceURI,
            tags: tagsJoined,
        };

        // Checking if required fields are empty
        if (
            grammarObj.title === '' ||
            grammarObj.description === '' ||
            grammarObj.structure === '' ||
            tagsArr.length <= 0
        ) {
            setIsMissingOpen(true);
            return;
        }

        // Setting status to loading
        setStatus('loading');

        // Handling different responses based on request status
        const responseStatus = (response) => {
            if (response.status === 'success') {
                setIsSuccessOpen(true);
                setStatus('idle');
            }
            if (response.status === 'fail') {
                setStatus('idle');
                window.alert('Something went wrong');
            }
        };

        // Making either a PUT or POST request based on grammarStructure existence
        grammarStucture
            ? putReq(
                  `/admin/grammar/${grammarStucture.uuid}`,
                  submitGrammarObj,
              ).then((res: any) => {
                  responseStatus(res);
              })
            : postReq('/admin/grammar', submitGrammarObj).then((res: any) => {
                  responseStatus(res);
              });
    };

    return (
        // Main container for the component
        <div className={styles.resourcesPage}>
            {/* Form for editing grammar resources */}
            <form className={styles.resourceForm}>
                {/* Input field for grammar title */}
                <FormInput
                    id={'title'}
                    label={'Title'}
                    value={grammarObj.title}
                    maxHeight={58}
                    // Placeholder and callback for updating title
                    placeHolder="Enter a title of the Grammar Point, i.e. Expressing Duration with 多久"
                    updateValue={(e) => {
                        setGrammarObj((prev) => ({
                            ...prev,
                            title: e.target.value,
                        }));
                    }}
                />
                {/* Input field for grammar description */}
                <FormInput
                    id={'description'}
                    label={'Description'}
                    value={grammarObj.description}
                    maxHeight={160}
                    // Placeholder and callback for updating description
                    placeHolder="A short description for how to use the grammar point. i.e. Asking “how long” using question words：多久duōjiǔ，多长时间duōcháng shíjiān，几个月jǐgeyuè，几天jǐtiān, etc."
                    changeText={true}
                    updateValue={(e) => {
                        setGrammarObj((prev) => ({
                            ...prev,
                            description: e.target.value,
                        }));
                    }}
                />
                {/* Input field for grammar structure */}
                <FormInput
                    id={'grammar'}
                    label={'Grammar Structure'}
                    value={grammarObj.structure}
                    maxHeight={160}
                    // Placeholder and callback for updating grammar structure
                    placeHolder="Enter the typical structure of how to use this grammar. i.e. [bold]Subject + [highltight]一边[highlight/] [verb] , [highlight]一边[highlight/] [verb][bold/]"
                    changeText={true}
                    updateValue={(e) => {
                        setGrammarObj((prev) => ({
                            ...prev,
                            structure: e.target.value,
                        }));
                    }}
                />
                {/* Input field for grammar link */}
                <FormInput
                    id={'link'}
                    label={'Link'}
                    value={grammarObj.resourceURI}
                    maxHeight={58}
                    // Placeholder and callback for updating link
                    placeHolder="Canvas link to the page of this Grammar Point"
                    updateValue={(e) => {
                        setGrammarObj((prev) => ({
                            ...prev,
                            resourceURI: e.target.value,
                        }));
                    }}
                />

                {/* Component for adding and managing tags */}
                <AddTag tagsArr={tagsArr} setTagsArr={setTagsArr} />
            </form>
            {/* Preview of the grammar resource */}
            <div className={styles.resourcePreview}>
                <p>Preview</p>
                {/* Component displaying the grammar resource preview */}
                <GrammarResource resource={grammarObj} />
            </div>
            {/* Buttons for canceling or submitting the form */}
            <div className={styles.buttons}>
                {/* Cancel button */}
                <ButtonColor
                    width={'49%'}
                    height={'56px'}
                    color={'orangeClear'}
                    fontsize={'20px'}
                    disable={status === 'loading'}
                    link={'/admin/resources-list'}
                >
                    Cancel
                </ButtonColor>
                {/* Submit button */}
                <ButtonColor
                    width={'49%'}
                    height={'56px'}
                    color={'orange'}
                    fontsize={'20px'}
                    type={'submit'}
                    disable={status === 'loading'}
                    onClick={(e) => handleSubmit(e)}
                >
                    {status === 'loading' ? 'Submitting' : 'Submit'}
                </ButtonColor>
            </div>
            {/* Modal for missing fields warning */}
            <WarningModal
                show={isMissingOpen}
                messageType={'missingfields'}
                singleButton={true}
                toggleClose={() => {
                    setIsMissingOpen(false);
                }}
            />
            {/* Modal for upload success message */}
            <WarningModal
                show={isSuccessOpen}
                messageType={'uploadsuccess'}
                singleButton={true}
                toggleContinue={() => setIsSuccessOpen(false)}
                toggleClose={() => setIsSuccessOpen(false)}
            />
        </div>
    );
};

export default EditResources;
