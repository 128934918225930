import React, { useEffect, useRef } from 'react';
import { Word } from '../../types/api_response';
import useFunction from '../../useFunction'; // Custom hook for utility functions
import closeButton from '../../../public/Images/ClearButtonX.svg'; // Close button icon
import ExampleSentences from './ExampleSentences'; // Component for rendering example sentences
import HanziWord from './HanziWord'; // Component for rendering Hanzi word
import playButton from '../../../public/Images/AudioIcons/btn-play.svg'; // Play button icon
import styles from './_styles/WordCardModal.module.scss'; // Styles for the WordCardModal component
import useGetWordExamples from '../../customhooks/useGetWordExamples'; // Custom hook for fetching word examples

interface Props {
    word: Word; // Word object containing word details
    translations: string; // Translations of the word
    show: boolean; // Flag indicating whether to show the modal
    toggleClose: CallableFunction; // Function to toggle the modal closed
}

/**
 * @fileoverview - Display a word card modal with word data and examples
 * @param {Props} props
 * @return {JSX.Element} - Rendered word card modal
 */
const WordCardModal = (props: Props) => {
    const audioRef = useRef<HTMLAudioElement>(); // Reference to the audio element for pronunciation
    const { word, translations, show, toggleClose } = props;
    const { cleanTrans } = useFunction(); // Function for cleaning translations
    const simplifiedWord = word.simplified;

    // Cleanup function when the component unmounts
    useEffect(() => {
        const audioElement = audioRef.current;
        return () => {
            audioElement?.pause(); // Pause the audio playback
            if (audioElement) {
                audioElement.currentTime = 0;
                audioElement.src = ''; // Clear the audio source
            }
        };
    }, []);

    // Fetch example sentences for the word
    const exampleSentences = useGetWordExamples({
        phraseNum: 3,
        wordList: [word],
    });

    const translationsArr: string[] = cleanTrans(translations);

    return (
        <>
            {/* Audio element for word pronunciation */}
            <audio
                ref={audioRef}
                src={`https://storage.googleapis.com/production_word_audio_files/${simplifiedWord}.wav`}
            />

            {show && (
                <aside
                    className={styles.backdrop}
                    onClick={() => toggleClose()}
                >
                    <aside
                        onClick={(e) => e.stopPropagation()}
                        className={styles.definitions}
                    >
                        {/* Close button for the modal */}
                        <img
                            className={styles.closeButton}
                            src={closeButton}
                            onClick={() => toggleClose()}
                        />

                        {/* Container for word definitions */}
                        <div className={styles.wordDefContainer}>
                            <div className={styles.wordDefTop}>
                                {/* Display word details */}
                                <div className={styles.modalWord}>
                                    <p className={styles.pinyin}>
                                        {word.pinyin}
                                    </p>
                                    <div>
                                        {/* Play button for pronunciation */}
                                        <img
                                            className={styles.playButton}
                                            src={playButton}
                                            onClick={() => {
                                                audioRef.current?.play();
                                            }}
                                        />

                                        {/* Display Hanzi word */}
                                        <HanziWord word={word} size="medium" />
                                    </div>
                                </div>
                            </div>

                            {/* Display translations */}
                            <div className={styles.wordDefBottom}>
                                {translationsArr.map((translation, i) => {
                                    if (i >= 3) {
                                        return;
                                    } else {
                                        return (
                                            <div
                                                key={i}
                                                className={styles.translation}
                                            >
                                                <div>{i + 1}.</div>
                                                <p>{translation}</p>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>

                        {/* Display example sentences */}
                        <div className={styles.wordExamples}>
                            <ExampleSentences
                                word={word}
                                exampleSentences={exampleSentences[0]}
                                phraseNum={3}
                            />
                        </div>
                    </aside>
                </aside>
            )}
        </>
    );
};

export default WordCardModal;
