/* eslint-disable require-jsdoc */
// eslint-disable-next-line require-jsdoc
import React, { useContext } from 'react';
import UserContext from './context/UserContext';
import { Phrase, PhraseWord, UserWord, Word } from './types/api_response';

export default function useFunction() {
    const { pinyin, bothTypes, mandarinType } = useContext(UserContext);

    function calcPercentage(numerator: number, denominator: number): number {
        return Math.round((numerator / denominator) * 100);
    }
    function percentageBar(numerator: number, denominator: number): number {
        return (numerator / denominator) * 100;
    }

    function secondsToMin(num: number): string {
        const min: number = Math.floor(num / 60);
        const sec: number = num - min * 60;

        if (min === 0) {
            return `${sec}s`;
        }
        return `${min}m ${sec}s`;
    }

    /* Checks user words to see if its due */
    function checkDueWords(
        wordData: UserWord[],
        checkWord: boolean,
    ): UserWord[] {
        if (!wordData) {
            return [];
        }
        if (checkWord) {
            return wordData.filter(
                (word) =>
                    new Date().getTime() >=
                        new Date(word.studyData.dateDue).getTime() &&
                    !word.studyData.isBlocked &&
                    word.studyData.srsLevel < 7,
            );
        } else {
            return wordData.filter(
                (word) =>
                    !word.studyData.isBlocked && word.studyData.srsLevel < 7,
            );
        }
    }

    function checkNewWords(wordData: UserWord[]): UserWord[] {
        return wordData.filter((word) => word.studyData.srsLevel <= 0);
    }

    function cleanTrans(unparsedTranslation: string | undefined): string[] {
        if (!unparsedTranslation) {
            return [''];
        }
        return unparsedTranslation
            .split('||')
            .map((splitWord) => splitWord.replace(/[|]/gi, ''));
    }

    // should be used after clean trans to remove any extra characters

    function cleanTag(tag: string[]) {
        return [tag[0].split(' ').splice(1, 2).join(' ')];
    }

    /* filters user word arrays and removes words greater than srs */
    function lessonWordsSorted(wordArr: UserWord[]) {
        return wordArr.filter((word) => word.studyData.srsLevel <= 0);
    }

    function lessonUrlName(param) {
        return encodeURIComponent(param.replace(/[^a-zA-Z\s]/g, ''));
    }

    function logPosition(
        ref: React.RefObject<HTMLDivElement>,
        findHeight: boolean,
    ) {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            const { top, bottom, left, right } = rect;
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            if (findHeight) {
                // Determine the location of the component based on the viewport height
                if (top < windowHeight / 2 && bottom > windowHeight / 2) {
                    console.log(
                        'The component is in the center of the viewport',
                    );
                    return false;
                } else if (top < windowHeight / 2) {
                    console.log(
                        'The component is above the center of the viewport',
                    );
                    return true;
                } else {
                    console.log(
                        'The component is below the center of the viewport',
                    );
                    return false;
                }
            }
            if (!findHeight) {
                // Determine the location of the component based on the viewport height
                if (left < windowWidth / 2 && right > windowWidth / 2) {
                    /* console.log(
                        'The component is in the center of the viewport',
                    ); */
                    return true;
                } else if (left < windowWidth / 2) {
                    /* console.log('The component is on the left of the viewport'); */
                    return true;
                } else {
                    /* console.log(
                        'The component is on the right of the viewport',
                    ); */
                    return false;
                }
            }
        }
    }

    function hanziPreview(phrase: Phrase): string {
        return phrase.words
            .map((word) => {
                if (word.markdown === '') {
                    return word.word.simplified;
                } else {
                    return word.markdown;
                }
            })
            .join('');
    }

    function createWordString(phraseArr: PhraseWord[], word: Word): string {
        const arr: string[] = [];

        phraseArr.forEach((phraseWord) => {
            if (phraseWord.markdown !== '') {
                arr.push(phraseWord.markdown);
            } else {
                arr.push(
                    pinyin || bothTypes
                        ? phraseWord.word.pinyin
                        : mandarinType
                        ? phraseWord.word.simplified
                        : phraseWord.word.traditional,
                );
            }
            if (pinyin || bothTypes) {
                arr.push(' ');
            }
        });

        return arr.join('');
    }

    function wordSort(wordlist: UserWord[], type: string): UserWord[] {
        if (type == 'recent') {
            return wordlist.sort(
                (a, b) =>
                    (new Date(b.studyData.dateDue) as any) -
                    (new Date(a.studyData.dateDue) as any),
            );
        } else if (type == 'frequency') {
            return wordlist.sort((a, b) => b.word.frequency - a.word.frequency);
        } else {
            return wordlist;
        }
    }

    function sortByDueDate(
        words: UserWord[],
        randomOnly: boolean,
        preferences: number,
    ) {
        const sortedWords = randomOnly
            ? words
            : words.sort((a: UserWord, b: UserWord) => {
                  return (
                      new Date(a.studyData.dateDue).getTime() -
                      new Date(b.studyData.dateDue).getTime()
                  );
              });

        const randomizeWords = randomOnly
            ? sortedWords.sort(() => 0.5 - Math.random()).slice(0, preferences)
            : sortedWords.slice(0, preferences).sort(() => 0.5 - Math.random());
        return randomizeWords;
    }

    function limitString(str, limit) {
        if (str.length <= limit) {
            return str;
        } else {
            // Trim the string to the specified limit and remove any trailing spaces
            str = str.substring(0, limit).trim();
            // Append '...' to the trimmed string
            return str + '...';
        }
    }

    const addPipeToWords = (str) => {
        const words = str.split(' ');
        const modifiedWords = words.map((word) => `|${word}|`);
        const result = modifiedWords.join('');

        return result;
    };

    const filterResources = (
        resources: Resource[],
        selectedWeek: string,
    ): Resource[] => {
        const filteredResources: Resource[] = [];
        if (resources === null) {
            return filteredResources;
        } else if (selectedWeek.includes('ZJ')) {
            resources.filter((resource) => {
                if (resource.tags.includes('ZJ')) {
                    filteredResources.push(resource);
                }
            });
            return filteredResources;
        } else {
            resources.filter((resource) => {
                const tag = resource.tags;
                const weekTag = cleanTrans(selectedWeek)[0];
                if (tag.includes(weekTag)) {
                    filteredResources.push(resource);
                }
            });
            return filteredResources;
        }
    };

    return {
        calcPercentage,
        secondsToMin,
        checkDueWords,
        cleanTrans,
        cleanTag,
        percentageBar,
        lessonWordsSorted,
        checkNewWords,
        lessonUrlName,
        logPosition,
        hanziPreview,
        createWordString,
        wordSort,
        sortByDueDate,
        limitString,
        addPipeToWords,
        filterResources,
    };
}
