import React from 'react';
import TagList from '../../UI-Components/TagList';
import styles from './_styles/GrammarResource.module.scss';
import { Resource } from '../../../types/api_response';
interface Props {
    resource: Resource;
}

/**
 * @fileoverview
 * Displays the grammar resource and has a special function to parse the text and display different colors
 *
 * @param {Props} props
 * - title: title of grammar resource
 * - description: text for the grammar resource description
 * - structure: text for the grammar structure
 * - tagArr: an array of tags
 *
 * @return {JSX.Element} - Rendered Grammar resource
 */
const GrammarResource = (props: Props): JSX.Element => {
    const { resource } = props;

    // Component to parse markup text and apply styles
    // eslint-disable-next-line react/prop-types
    const ParseMarkupToHTMLWithStyles = ({ text }) => {
        const parseText = (text) => {
            const elements = [];
            let currentIndex = 0;

            // Regular expression to match different markup patterns
            // eslint-disable-next-line react/prop-types
            text.replace(
                /\[b\](.*?)\[\/b\]|\[o\](.*?)\[\/o\]|\[p\](.*?)\[\/p\]|\[t\](.*?)\[\/t\]|\[\/n\]|([^[\]]+)/g,
                (
                    match,
                    boldText,
                    orangeText,
                    purpleText,
                    tealText,
                    plainText,
                    newLine,
                ) => {
                    const key = currentIndex++;
                    let element;

                    if (boldText) {
                        element = (
                            <span key={key} className={styles.bold}>
                                {boldText}
                            </span>
                        );
                    } else if (orangeText) {
                        element = (
                            <span key={key} className={styles.orangeText}>
                                {orangeText}
                            </span>
                        );
                    } else if (purpleText) {
                        element = (
                            <span key={key} className={styles.purpleText}>
                                {purpleText}
                            </span>
                        );
                    } else if (tealText) {
                        element = (
                            <span key={key} className={styles.tealText}>
                                {tealText}
                            </span>
                        );
                    } else if (plainText) {
                        element = (
                            <span key={key} className={styles.normal}>
                                {plainText}
                            </span>
                        );
                    } else if (newLine) {
                        element = <br key={key} />;
                    }

                    elements.push(element);
                    return match; // return the original match to continue replacements
                },
            );

            return elements;
        };

        const parsedElements = parseText(text);

        return <div>{parsedElements}</div>;
    };

    const tagArr: string[] = resource.tags.split(' ');

    return (
        <div className={styles.grammerContainer}>
            {/* Tag list component */}

            <div className={styles.taglist}>
                <TagList tags={tagArr} />
                {resource.resourceURI !== '' && (
                    <a
                        href={resource.resourceURI}
                        className={styles.link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        View More
                    </a>
                )}
            </div>

            {/* Grammar title */}
            <p className={styles.grammarTitle}>{resource.title}</p>

            {/* Grammar description with parsed markup and styles */}
            <div className={styles.grammarDescription}>
                <ParseMarkupToHTMLWithStyles text={resource.description} />
            </div>

            {/* Grammar structure with parsed markup and styles */}
            <div className={styles.grammarExplanation}>
                <ParseMarkupToHTMLWithStyles text={resource.structure} />
            </div>
        </div>
    );
};

export default GrammarResource;
