import { useState, useEffect, useRef } from 'react';
import useFetch from '../useFetch';
import { Phrase, Word } from '../types/api_response';

interface Props {
    phraseNum: number;
    wordList: Word[];
    reset?: any;
}

const useGetWordExamples = (props: Props) => {
    const { phraseNum = 2, wordList, reset } = props;
    const [exampleSentences, setExampleSentences] = useState<Phrase[][] | []>(
        [],
    );
    const { getReq } = useFetch();

    const phraseNumRef = useRef(phraseNum);
    const wordListRef = useRef(wordList);
    const getReqRef = useRef(getReq);

    useEffect(() => {
        phraseNumRef.current = phraseNum;
        wordListRef.current = wordList;
        getReqRef.current = getReq;
    }, [phraseNum, wordList, getReq]);

    useEffect(() => {
        setExampleSentences([]);
        const fetchExamples = async () => {
            try {
                const exampleArr: Phrase[][] = await Promise.all(
                    wordListRef.current.map(async (word) => {
                        const res: any = await getReqRef.current(
                            `/words/${word.simplified}/phrases?limit=${phraseNumRef.current}`,
                        );
                        if (res.data.phrases === null) {
                            return [];
                        } else {
                            return res.data.Phrases;
                        }
                    }),
                );
                setExampleSentences(exampleArr);
            } catch (error) {
                console.error('Error fetching examples:', error);
            }
        };

        fetchExamples();
    }, [reset]);

    return exampleSentences;
};

export default useGetWordExamples;
