import React from 'react';
import styles from './_styles/DEV.module.scss';
import useGetWordExamples from '../../customhooks/useGetWordExamples';
import { Word } from '../../types/api_response';

import MasterButton from '../UI-Components/MasterButton';
const DEV = () => {
    const word: Word[] = [
        {
            createdAt: '',
            frequency: 107133694,
            hskV2Level: 2,
            hskV3Level: 1,
            pinyin: 'de',
            simplified: '的',
            tags: '',
            traditional: '的',
            translation:
                '|de*||(a special word usually indicating possession) (more generally used to attach descriptions to a noun)|',
            updatedAt: '',
            uuids: 'fafdf60e-56be-460e-a088-407efb8da8fd',
        },
        {
            createdAt: '',
            frequency: 107133694,
            hskV2Level: 2,
            hskV3Level: 1,
            pinyin: 'de',
            simplified: '的',
            tags: '',
            traditional: '的',
            translation:
                '|de*||(a special word usually indicating possession) (more generally used to attach descriptions to a noun)|',
            updatedAt: '',
            uuids: 'fafdf60e-56be-460e-a088-407efb8da8fd',
        },
    ];

    const exampleSentences = useGetWordExamples({
        wordList: word,
        phraseNum: 2,
    });

    console.log(exampleSentences);

    return (
        <div className={styles.devpage}>
            <MasterButton isBlock={true} />
            <MasterButton isBlock={false} />
        </div>
    );
};

export default DEV;
