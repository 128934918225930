import React, { useRef } from 'react';

interface Props {
    id: string;
    name: string;
    accept: string;
    handleFile: CallableFunction;
    styles: string;
    errorMessage?: string;
    children: JSX.Element[];
    // requried?: boolean;
}

/**
 * Component for handling file uploads with a hidden file input.
 * @param {Props} props - The component props.
 * @return {JSX.Element} - The rendered component.
 */
const FileUploader = (props: Props): JSX.Element => {
    // Create a ref for the hidden file input
    const hiddenFileInput = useRef<HTMLInputElement | null>(null);
    // Destructure props, providing a default errorMessage if not provided
    const { errorMessage = 'Error' /* , required = false  */ } = props;

    // Click event handler for triggering the hidden file input
    const handleClick = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ): void => {
        e.stopPropagation();
        if (hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    // Change event handler for handling file selection
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files && event.target.files.length === 0) {
            return alert(errorMessage);
        }
        // Get the uploaded file and call the handleFile function with it
        const fileUploaded = event.target.files[0];
        props.handleFile(fileUploaded);
    };

    return (
        <>
            {/* Visible button for triggering file input */}
            <button
                type="button"
                onClick={(e) => handleClick(e)}
                className={props.styles}
            >
                {props.children}
            </button>
            {/* Hidden file input */}
            <input
                id={props.id}
                name={props.name}
                accept={props.accept}
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: 'none' }}
            />
        </>
    );
};

export default FileUploader;
