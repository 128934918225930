import React, { useContext } from 'react';
import UserContext from '../../../context/UserContext';
import AudioInterface from './AudioInterface';
import LessonInterface from './LessonInterface';
import PlayButton from '../../../../public/Images/AudioIcons/btn-play.svg';
import PhraseBlock from './PhraseBlock';
import WordCard from './WordCard';
import correct from '../../../../public/Images/check-green.svg';
import incorrect from '../../../../public/Images/wrong-red.svg';
import styles from './_styles/LessonResultsList.module.scss';

interface Props {
    testResults?: UserAnswer[];
    audioPlayer: AudioInterface;
    lesson: LessonInterface;
}

interface UserAnswer {
    userAnswer: string;
    isCorrect: boolean;
}

/**
 *
 * @fileoverview
 * Displays the phrases from the lessons for the results page to be reviewed and listen to audio again
 *
 * @param {Props} props
 * - testResults: An array of UserAnswers for displaying the users answer and if they are correct
 * - audioPlayer: playback the audio
 * - lesson: get playback times
 *
 * @return {JSX.Element} - Rendered lesson results list
 */
const LessonResultsList = (props: Props) => {
    const { lesson, audioPlayer, testResults } = props;
    const { bothTypes, pinyin } = useContext(UserContext);

    const playAudio = (index) => {
        const [startTime, endTime] = lesson.getPhrasePlayTime(index);
        audioPlayer.playSection(startTime, endTime);
    };

    /* Styles the phrase with green or red outline only in test mode*/
    const phraseBlockStyle = (index) => {
        if (testResults === undefined) {
            return;
        }
        const result = testResults[index];
        return result
            ? result.isCorrect
                ? styles.correct
                : styles.incorrect
            : undefined;
    };

    /* Used for keeping the play button inline with the text elements. Especially for both types */
    const playButtonStyle = () => {
        if (bothTypes) {
            return styles.bothTypes;
        } else if (pinyin) {
            return styles.pinyin;
        } else {
            return '';
        }
    };

    return (
        <div className={styles.resultContainer}>
            {lesson.phrases.map((phrase, index) => {
                return (
                    <>
                        <div
                            key={phrase.uuid}
                            className={
                                styles.resultListItem +
                                ' ' +
                                phraseBlockStyle(index)
                            }
                        >
                            <img
                                src={PlayButton}
                                onClick={() => playAudio(index)}
                                className={`
                                ${styles.playButton} 
                                ${playButtonStyle()}
                                `}
                            />
                            <div className={styles.resultContent}>
                                <PhraseBlock>
                                    {phrase.words.map((PhraseWord) => {
                                        return (
                                            <WordCard
                                                key={PhraseWord.uuid}
                                                PhraseWord={PhraseWord}
                                                wordType={'learn'}
                                                resultsPage={true}
                                            />
                                        );
                                    })}
                                </PhraseBlock>

                                {/* TESTMODE ONLY */}
                                {/* Display the user's answer */}
                                {testResults &&
                                    testResults[index].userAnswer !== '' && (
                                        <div className={styles.answerStyle}>
                                            <h4 className={styles.userAnswer}>
                                                your answer
                                            </h4>
                                            <p>
                                                {testResults[index].userAnswer}
                                            </p>
                                        </div>
                                    )}
                                {/* Display the correct answer */}
                                {testResults && (
                                    <div className={styles.answerStyle}>
                                        <h4 className={styles.answerCorrect}>
                                            correct answer
                                        </h4>
                                        <b>{phrase.translation}</b>
                                    </div>
                                )}
                            </div>
                            {/* Display correct or incorrect mark for users answers */}
                            {testResults && (
                                <>
                                    <img
                                        src={
                                            testResults[index].isCorrect
                                                ? correct
                                                : incorrect
                                        }
                                        className={`
                                                ${styles.checkType}
                                                ${playButtonStyle()}`}
                                    />
                                </>
                            )}
                        </div>
                    </>
                );
            })}
        </div>
    );
};

export default LessonResultsList;
