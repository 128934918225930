import React, { useState } from 'react';
import audioUploadIcon from '../../../../public/Images/audioUploadIcon.svg';
import CloseButton from '../../../../public/Images/ClearButtonX.svg';
import FileUploader from '../../GlobalComponents/FileUploader';
import imageUploadIcon from '../../../../public/Images/imageUploadIcon.svg';
import styles from './_styles/FilesUploader.module.scss';

interface Props {
    audioFile?: File | null;
    imageFile?: File | null;
    handleAudioFile?: CallableFunction;
    handleImageFile?: CallableFunction;
    lessonImg?: any;
}

// eslint-disable-next-line valid-jsdoc
/**
 *
 * @fileoverview
 * Component for uploading audio and image files
 *
 * @return {JSX.Element} - Rendered file uploader component
 */
const FilesUploader = (props: Props): JSX.Element => {
    const {
        audioFile,
        imageFile,
        handleAudioFile,
        handleImageFile,
        lessonImg,
    } = props;

    // State variable to store the preview of the image
    const [imagePreview, setImagePreivew] = useState<string | undefined>(
        undefined,
    );

    // Function to preview the image file
    const previewImg = (imgFile: File | undefined): string | undefined => {
        try {
            if (imgFile) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImagePreivew(reader.result as string);
                };
                reader.readAsDataURL(imgFile);
            }
        } catch (error) {
            console.error('Error previewing image:', error);
        }
        return imagePreview;
    };

    return (
        <aside className={styles.fileUpload}>
            {/* Audio File Upload */}
            {handleAudioFile && (
                <div className={styles.file}>
                    <label htmlFor="audioFile" className={styles.label}>
                        Audio File
                    </label>
                    <FileUploader
                        id={'audioFile'}
                        name={'audioFile'}
                        accept={'.flac, .wav'}
                        handleFile={handleAudioFile}
                        errorMessage={'Please upload a valid .wav audio file!'}
                        styles={styles.audioFileInput}
                    >
                        <div className={styles.audioFileMessage}>
                            <img src={audioUploadIcon} alt="upload image" />
                            {audioFile?.name === undefined ? (
                                <p>
                                    upload an audio file. <br /> supported
                                    format: .wav
                                </p>
                            ) : (
                                <p>{audioFile?.name}</p>
                            )}
                        </div>
                    </FileUploader>
                </div>
            )}

            {/* Image File Upload */}
            {handleImageFile && (
                <div className={styles.file}>
                    <label htmlFor="imageFile" className={styles.label}>
                        Cover Image
                    </label>

                    <FileUploader
                        id={'imageFile'}
                        name={'imageFile'}
                        accept={'.png, .jpg'}
                        handleFile={handleImageFile}
                        errorMessage={
                            'Please upload a valid .png or .jpg file!'
                        }
                        styles={styles.imageFileInput}
                    >
                        {/* Clear Image button */}
                        <img
                            className={styles.clearImage}
                            src={CloseButton}
                            alt="delete"
                            onClick={() => handleImageFile(null)}
                        />

                        {/* Displaying image preview or upload message */}
                        {imageFile?.name || lessonImg ? (
                            imageFile?.name ? (
                                <>
                                    <img
                                        className={styles.imagePreview}
                                        src={previewImg(imageFile)}
                                        alt="preview image"
                                    />
                                </>
                            ) : (
                                <>
                                    <img
                                        className={styles.imagePreview}
                                        src={lessonImg}
                                        alt="preview image"
                                    />
                                </>
                            )
                        ) : (
                            <div className={styles.imageFileMessage}>
                                <img src={imageUploadIcon} />
                                <p>
                                    upload <br /> images of <br /> max 5MB
                                </p>
                            </div>
                        )}
                    </FileUploader>
                </div>
            )}
        </aside>
    );
};

export default FilesUploader;
