import React, { useState } from 'react';
import AddTag from './AddTag';
import ButtonColor from '../../UI-Components/ButtonColor';
import CheckBox from '../../UI-Components/CheckBox';
import FilesUploader from './FilesUploader';
import FormInput from './FormInput';
import MultiMediaResource from '../Resources/MultiMediaResource';
import styles from './_styles/CreateResourceForm.module.scss';

interface Multimedia {
    title: string;
    description: string;
    resourceURI: string;
    videoLength: string;
    videoType: 'Youtube' | 'Other' | '';
    tags: string;
    coverImage: File | null;
}

/**
 *
 * @fileoverview
 * Multimedia creator and editor
 *
 * @param {Props} props
 * - title: title
 * - description: description
 * - resourceURI: link to the multimedia resource
 * - videoLength: video length
 * - videoType: select type of video to display different type of video playback element
 * - tags: display the tags
 * - coverImage: cover image for multimedia
 * 
 *  @return {JSX.Element} - Rendered create mulitimedia form
 
 */
const CreateMultimedia = (): JSX.Element => {
    // Default multimedia object structure
    const DefaultObj: Multimedia = {
        title: '',
        description: '',
        resourceURI: '',
        tags: '',
        coverImage: null,
        videoType: '',
        videoLength: '',
    };

    const [multimediaObj, setMultimediaObj] = useState<Multimedia>(DefaultObj);
    const [tagsArr, setTagsArr] = useState<string[]>(['EM', '']);
    const [coverImage, setCoverImage] = useState<File | null>(null);

    return (
        <div className={styles.resourcesPage}>
            <form className={styles.resourceForm}>
                {/* Input fields for multimedia details */}
                <FormInput
                    id={'title'}
                    label={'Title'}
                    value={multimediaObj.title}
                    maxHeight={58}
                    placeHolder="Enter a title of the Multi-media"
                    updateValue={(e) => {
                        setMultimediaObj((prev) => ({
                            ...prev,
                            title: e.target.value,
                        }));
                    }}
                />
                <FormInput
                    id={'description'}
                    label={'Description'}
                    value={multimediaObj.description}
                    maxHeight={58}
                    placeHolder="A short description of the Multi-media"
                    updateValue={(e) => {
                        setMultimediaObj((prev) => ({
                            ...prev,
                            description: e.target.value,
                        }));
                    }}
                />
                <FormInput
                    id={'link'}
                    label={'Link'}
                    value={multimediaObj.resourceURI}
                    maxHeight={58}
                    placeHolder="Video link to the Multi-media"
                    updateValue={(e) => {
                        setMultimediaObj((prev) => ({
                            ...prev,
                            resourceURI: e.target.value,
                        }));
                    }}
                />
                <FormInput
                    id={'Video Length'}
                    label={'Video Length'}
                    value={multimediaObj.videoLength}
                    maxLength={5}
                    maxHeight={36}
                    maxWidth={110}
                    placeHolder="Video Time"
                    updateValue={(e) => {
                        setMultimediaObj((prev) => ({
                            ...prev,
                            videoLength: e.target.value,
                        }));
                    }}
                />
                <div className={styles.checkBoxContainer}>
                    <CheckBoxSelect
                        multimediaObj={multimediaObj}
                        setMultiMediaObj={setMultimediaObj}
                        videoType="Youtube"
                    />
                    <CheckBoxSelect
                        multimediaObj={multimediaObj}
                        setMultiMediaObj={setMultimediaObj}
                        videoType="Other"
                    />
                </div>
                {/* Cover image uploader and tag input */}
                <div className={styles.bottomForm}>
                    <FilesUploader
                        imageFile={coverImage}
                        handleImageFile={(e) => setCoverImage(e)}
                    />
                    <AddTag tagsArr={tagsArr} setTagsArr={setTagsArr} />
                </div>
            </form>
            {/* Displaying multimedia preview */}
            <div className={styles.resourcePreview}>
                <p>Preview</p>
                <MultiMediaResource
                    title={multimediaObj.title}
                    description={multimediaObj.description}
                    resourceURI={multimediaObj.resourceURI}
                    tagsArr={tagsArr}
                    imageFile={coverImage}
                />
            </div>
            {/* Buttons for canceling and submitting the form */}
            <div className={styles.buttons}>
                <ButtonColor
                    width={'49%'}
                    height={'56px'}
                    color={'orangeClear'}
                    fontsize={'20px'}
                    disable={status === 'loading'}
                    link={'/admin/resources-list'}
                >
                    Cancel
                </ButtonColor>
                <ButtonColor
                    width={'49%'}
                    height={'56px'}
                    color={'orange'}
                    fontsize={'20px'}
                    type={'submit'}
                    disable={status === 'loading'}
                >
                    {status === 'loading' ? 'Submitting' : 'Submit'}
                </ButtonColor>
            </div>
        </div>
    );
};

// Props interface for CheckBoxSelect component
interface CheckboxProps {
    multimediaObj: Multimedia;
    setMultiMediaObj: CallableFunction;
    videoType: string;
}

// CheckBoxSelect component for selecting video type
const CheckBoxSelect = (props: CheckboxProps): JSX.Element => {
    const { multimediaObj, setMultiMediaObj, videoType } = props;

    return (
        <label className={styles.checkBox} htmlFor={videoType}>
            <CheckBox isChecked={multimediaObj.videoType === videoType} />
            <input
                id={videoType}
                value={videoType}
                type="radio"
                checked={multimediaObj.videoType === videoType}
                onChange={() => {
                    setMultiMediaObj((prev) => ({
                        ...prev,
                        videoType: videoType,
                    }));
                }}
            />
            {videoType}
        </label>
    );
};

export default CreateMultimedia;
