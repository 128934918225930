import React, { useState } from 'react';
import styles from './_styles/UserLogin.module.scss';
import EMLogo from '../../../public/Images/ExcelMandarinLogo.svg';
import { LoginButton } from '../Auth/login-button';
import ButtonColor from '../UI-Components/ButtonColor';
// import { useAuth0 } from '@auth0/auth0-react';

/**
 * This component renders renders the user authentication page.
 * 1. Allows users to login with Auth0*
 * 2. Allows users to link their old system login to the new Auth0 login**
 *
 * *Will create an account for new users.
 *
 * **Only done once -- future logins use route 1.
 * @return {JSX.Element} - Returns the entire page
 */
const UserLogin = (): JSX.Element => {
    const [syncAccount, setSyncAccount] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    // const { user } = useAuth0();
    // const { loginUser } = useContext(UserContext);

    return (
        <>
            <div className={styles.page}>
                <div className={styles.userLogin}>
                    <div className={styles.logo}>
                        <img src={EMLogo} />
                        <h1>Learn Mandarin fun and fast</h1>
                    </div>
                    {!syncAccount ? (
                        <div className={styles.authlogin}>
                            <LoginButton Auth0={true} />
                            <ButtonColor
                                color={'blue'}
                                width={'100%'}
                                height={'56px'}
                                onClick={() => {
                                    setSyncAccount(!syncAccount);
                                }}
                            >
                                Migrate existing account to my google acount
                            </ButtonColor>

                            <p>
                                Please click migrate existing account if you
                                have not already signed in with google or you
                                will loose all your previous progress
                            </p>
                        </div>
                    ) : (
                        <form
                            className={styles.loginForm}
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                            autoComplete={'on'}
                        >
                            <input
                                type="text"
                                onChange={(e) => setUsername(e.target.value)}
                                value={username}
                                placeholder="username"
                                required
                            />
                            <input
                                type={'text'}
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                placeholder="password"
                                required
                            />
                            <LoginButton
                                Auth0={false}
                                oldCredentials={{
                                    username: username,
                                    password: password,
                                }}
                            />
                            <p onClick={() => setSyncAccount(false)}>Go back</p>
                        </form>
                    )}
                </div>
            </div>
        </>
    );
};

export default UserLogin;
