/* eslint-disable require-jsdoc */
// eslint-disable-next-line require-jsdoc

import { Lesson } from './types/api_response';

export default function tagParser() {
    function getSubTagArray(tag: string): string[] {
        /* Tag structure looks like this |EM Q1 W1 D1| */
        if (typeof tag !== 'string' || tag.length === 0) {
            console.error('Invalid input for getSubTagArray function.');
            return [];
        }
        const arrayOfTags = tag.replace(/[|]/gi, '').split(' ');
        const filteredTags = arrayOfTags.filter((tag) => tag.trim() !== '');
        /* The output looks like this [EM, Q1, W1, D1] */
        return filteredTags;
    }

    function getSubTagLetter(tag: string) {
        if (typeof tag === 'string' && tag.length > 0) {
            return tag.charAt(0);
        } else {
            console.error('Invalid input for getSubTagLetter function.');
            return null;
        }
    }

    function getSubTagString(tag: string) {
        const arrayOfTags = getSubTagArray(tag);

        switch (arrayOfTags[0]) {
            case 'Quarter':
                return `Q${arrayOfTags[1]}`;
            case 'Intermediate':
                return `C${arrayOfTags[2]}`;
            case 'Week':
                return `W${arrayOfTags[1]}`;
            default:
                return '';
        }
    }

    function getTagString(
        tag: string,
        subTag: 'Q' | 'W' | 'D' | 'C',
        outputFormat: 'tag' | 'full',
    ) {
        const arrayOfTags = getSubTagArray(tag);
        let response = arrayOfTags.find((tag) => {
            return tag.includes(subTag);
        });

        if (outputFormat == 'full') {
            const map = {
                Q: 'Quarter ',
                W: 'Week ',
                D: 'Day ',
                C: 'Intermediate Cycle ',
            };
            response = response?.replace(subTag, map[subTag]);
        }

        if (response == undefined) {
            return '';
        }
        return response;
    }

    function getDayTagNumber(tag: string): number {
        // DISQ: DO NOT LEAVE CODE THAT IS TYPED INCORRECTLY LIKE THIS
        const tagArr = getSubTagArray(tag);
        const response = tagArr.find((tag) => {
            return tag.includes('D');
        });
        if (response == undefined) {
            return '';
        }
        return response.replace(/[^\d]/g, '');
    }

    function getQuarterWeekTag(tag: string) {
        const intermediate = getTagString(tag, 'C', 'tag');
        const quarter =
            getTagString(tag, 'Q', 'tag') != 'Q0'
                ? getTagString(tag, 'Q', 'tag')
                : 'Intro';
        const week = getTagString(tag, 'W', 'tag');

        return `${intermediate} ${quarter} ${week}`;
    }

    function currentWeekSubTag(currentWeek: string) {
        const subTagArr = getSubTagArray(currentWeek);
        if (subTagArr[1] == 'ZJ') {
            subTagArr.splice(0, 1);
        }

        return getSubTagLetter(subTagArr[1]);
    }

    function lessonFilter(lessonsArray: Lesson[], userCurrentWeek): Lesson[] {
        return lessonsArray?.filter((lesson) => {
            if (
                getQuarterWeekTag(lesson.lessonData.tags) ==
                getQuarterWeekTag(userCurrentWeek)
            ) {
                return lesson;
            }
        });
    }

    function lessonSort(
        lessonsArray: Lesson[],
        type: 'tag' | 'lastSeen',
    ): Lesson[] {
        if (lessonsArray === undefined) {
            return [];
        }
        if (type == 'tag') {
            return lessonsArray.sort(
                (a, b) =>
                    getDayTagNumber(a.lessonData.tags) -
                    getDayTagNumber(b.lessonData.tags),
            );
        }
        if (type == 'lastSeen') {
            return lessonsArray.sort((a, b) => {
                const date1 = new Date(
                    a.studyData.dateLastSeen,
                ) as any as number;
                const date2 = new Date(
                    b.studyData.dateLastSeen,
                ) as any as number;
                return date2 - date1;
            });
        } else {
            return [];
        }
    }

    const quarters: string[] = [
        'Quarter 0',
        'Quarter 1',
        'Quarter 2',
        'Quarter 3',
        'Quarter 4',
        'Intermediate Cycle 1',
        'Intermediate Cycle 2',
        'Intermediate Cycle 3',
        'Intermediate Cycle 4',
        'Intermediate Cycle 5',
    ];
    const weeks: string[] = ['Week 1', 'Week 2', 'Week 4', 'Week 5', 'Week 7'];

    const weeksIC: string[] = [
        'Week 1',
        'Week 2',
        'Week 3',
        'Week 4',
        'Week 5',
    ];

    return {
        getTagString,
        getSubTagLetter,
        getSubTagArray,
        getQuarterWeekTag,
        getDayTagNumber,
        currentWeekSubTag,
        getSubTagString,
        lessonFilter,
        lessonSort,
        quarters,
        weeks,
        weeksIC,
    };
}
