interface Props {
    upArrow: () => void;
    leftArrow: () => void;
    rightArrow: () => void;
}

const keyDownEvent = (props: Props) => {
    const { upArrow, leftArrow, rightArrow } = props;

    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const { altKey, metaKey, code } = event;

        // Check if neither Alt nor Meta keys are pressed
        if (!altKey && !metaKey) {
            // up arrow
            if (code === 'ArrowUp') {
                upArrow();
            }
            // left arrow
            if (code === 'ArrowLeft') {
                leftArrow();
            }
            // right arrow
            if (code === 'ArrowRight') {
                rightArrow();
            }
        }
    };

    return { handleKeyPress };
};

export default keyDownEvent;
