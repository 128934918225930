import React, { useContext } from 'react';
import { Phrase, Word } from '../../types/api_response';
import useFunction from '../../useFunction';
import UserContext from '../../context/UserContext';
import styles from './_styles/ExampleSentence.module.scss';

interface Props {
    word: Word;
    phrase: Phrase;
    className?: string;
}

/**
 * Component for rendering an example sentence with options for displaying Mandarin and Pinyin.
 * @param {Props} props - The component props.
 * @return {JSX.Element} - The rendered component.
 */
const ExampleSentence = (props: Props): JSX.Element => {
    // Destructure props
    const { pinyin, bothTypes } = useContext(UserContext);
    const { phrase, className } = props;

    // Custom hook for utility functions
    const { createWordString } = useFunction();

    return (
        <div className={`${styles.phraseLine} ${className}`}>
            {/* Conditional class based on language display options */}
            <p
                className={
                    !pinyin && !bothTypes
                        ? styles.phraseMandarin
                        : styles.phrasePinyin
                }
            >
                {/* Create and render the word string within the phrase */}
                {createWordString(phrase.words, props.word)}
            </p>
            {/* Display the translation of the phrase */}
            <p className={styles.phraseTrans}>{phrase.translation}</p>
        </div>
    );
};

export default ExampleSentence;
