import { useEffect } from 'react';

// see word card for example
// You must use useRef
// use if statement for the state item you want to close on outside click and set its value
/* 
useOutsideClick(showRef, () => {
        if (show) setShow(false);
        if (showMore) setShowMore(false);
    });
*/

/**
 * Custom hook to handle outside clicks and invoke a callback when clicking outside a specified ref.
 * @param {React.RefObject<any>} ref - Reference to the element for which outside clicks should be detected.
 * @param {Function} callback - Callback function to be invoked when an outside click is detected.
 */
const useOutsideClick = (ref, callback) => {
    /**
     * Handle click events outside the specified ref.
     * @param {MouseEvent} e - The click event.
     */
    const handleClick = (e) => {
        // Check if the ref exists and the clicked element is outside the specified ref
        if (ref.current && !ref.current.contains(e.target)) {
            // Invoke the provided callback when an outside click is detected
            callback();
        }
    };

    // Set up event listener on mount and clean up on unmount
    useEffect(() => {
        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    });
};

export default useOutsideClick;
