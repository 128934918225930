import React from 'react';
import { Phrase } from '../../../types/api_response';
import useFunction from '../../../useFunction';
import AudioInterface from '../Lesson/AudioInterface';
import cheveronLeft from '../../../../public/Images/chevron-left-black.svg';
import deleteButton from '../../../../public/Images/trash.svg';
import PlayButton from '../../../../public/Images/AudioIcons/btn-play.svg';
import styles from './_styles/EditLessonMetaEditor.module.scss';

interface Props {
    phrases: Phrase[];
    activeIndex: number;
    activePhrase: Phrase | null;
    audioPlayer: AudioInterface | null;
    audioPhrase: AudioInterface | null;
    resetAudio: CallableFunction;
    setPhrases: CallableFunction;
    setActiveIndex: CallableFunction;
    setActivePhrase: CallableFunction;
    setIsDeleteWarningOpen: CallableFunction;
}

// eslint-disable-next-line valid-jsdoc
/**
 * @fileoverview
 * Used for editing lessons. Displays information for editing lessons
 * - the current phrase
 * - input for the translation
 * - current phrase being editted
 * - button for deleting a phrase
 *
 * @return {JSX.Element} - Rendered lesson editor meta data controls
 *
 */
const EditLessonMetaEditor = (props: Props): JSX.Element => {
    const {
        audioPhrase,
        phrases,
        activePhrase,
        activeIndex,
        setPhrases,
        setActivePhrase,
        setActiveIndex,
        setIsDeleteWarningOpen,
    } = props;

    const { hanziPreview } = useFunction();

    // Function to play the audio of the active phrase
    const playAudio = () => {
        const firstWordTime =
            activePhrase !== null ? parseFloat(activePhrase.words[0].time) : 0;

        const getLastWord = () => {
            const isMarkdown: boolean =
                activePhrase &&
                activePhrase.words[activePhrase.words.length - 1].markdown !=
                    '';

            const lastWordTime: number = activePhrase
                ? activePhrase.words.length > 1
                    ? isMarkdown
                        ? parseFloat(
                              activePhrase.words[activePhrase.words.length - 2]
                                  .time,
                          ) + 0.5
                        : parseFloat(
                              activePhrase.words[activePhrase.words.length - 1]
                                  .time,
                          ) + 0.5
                    : parseFloat(activePhrase.words[0].time)
                : 0;
            return lastWordTime;
        };
        audioPhrase?.playSection(firstWordTime, getLastWord());
    };

    return (
        <div className={styles.phraseMetaEditor}>
            {/* Conditional rendering based on the existence of an active phrase */}
            {activePhrase && (
                <>
                    {/* Phrase header section */}
                    <div className={styles.phraseHeader}>
                        <div className={styles.phraseSelector}>
                            {/* Button to navigate to the previous phrase */}
                            <button
                                onClick={() => {
                                    setActivePhrase(phrases[activeIndex - 1]);
                                    setActiveIndex(
                                        (activeIndex) => activeIndex - 1,
                                    );
                                }}
                                disabled={activeIndex == 0}
                            >
                                <img src={cheveronLeft} />
                            </button>
                            {/* Displaying the current phrase index */}
                            <h2>
                                Phrase{'  '}
                                {phrases?.length > activeIndex
                                    ? activeIndex + 1
                                    : activeIndex}{' '}
                                / {phrases?.length}
                            </h2>
                            {/* Button to navigate to the next phrase */}
                            <button
                                onClick={() => {
                                    setActivePhrase(phrases[activeIndex + 1]);
                                    setActiveIndex(
                                        (activeIndex) => activeIndex + 1,
                                    );
                                }}
                                disabled={activeIndex == phrases.length - 1}
                            >
                                <img
                                    src={cheveronLeft}
                                    className={styles.chevronFlipped}
                                />
                            </button>
                        </div>
                        {/* Button to delete the current phrase */}
                        <button
                            className={styles.deleteButton}
                            onClick={() => setIsDeleteWarningOpen(true)}
                        >
                            <img src={deleteButton} />
                            Delete This Phrase
                        </button>
                    </div>
                    {/* Phrase translation section */}
                    <div className={styles.phraseTranslation}>
                        {/* Phrase preview with play button and hanzi */}
                        <div className={styles.phrasePreview}>
                            <h4>Phrase</h4>
                            <div>
                                <img src={PlayButton} onClick={playAudio} />
                                <p>{hanziPreview(activePhrase)}</p>
                            </div>
                        </div>
                        {/* Translation editor */}
                        <div className={styles.translationEditor}>
                            <label htmlFor="translation">Translation</label>
                            {/* Textarea for editing the translation */}
                            <textarea
                                className={styles.inputBox}
                                id="translation"
                                name="translation"
                                value={
                                    activePhrase.translation === undefined
                                        ? ''
                                        : activePhrase.translation
                                }
                                onChange={(e) => {
                                    // Update the activePhrase's translation
                                    setActivePhrase((prev) => {
                                        prev.translation = e.target.value;
                                        return prev;
                                    });
                                    // Update the phrases array with the modified translation
                                    setPhrases((phrases) => {
                                        return [
                                            ...phrases.slice(0, activeIndex),
                                            {
                                                words: activePhrase.words,
                                                translation: e.target.value,
                                                uuid: activePhrase.uuid,
                                            },
                                            ...phrases.slice(activeIndex + 1),
                                        ];
                                    });
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default EditLessonMetaEditor;
