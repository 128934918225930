import React, { useState } from 'react';
import { UserWord, WordType } from '../../../types/api_response';
import useViewport from '../../../customhooks/useViewport';
import useFunction from '../../../useFunction';
import HanziWord from '../HanziWord';
import PlayButton from '../../../../public/Images/AudioIcons/btn-play.svg';
import WordCardModal from '../WordCardModal';
import styles from './_styles/WordListItem.module.scss';
import WordDefModal from '../WordDefModal';

interface Props {
    word: UserWord;
    toggleAudio: () => void;
    resultsPage?: boolean;
    lessonResultsData?: boolean;
    style: string;
    modalStyle?: boolean;
    wordType: WordType['wordType'];
}

const MissedWords = (props: Props): JSX.Element => {
    const {
        word,
        lessonResultsData,
        style,
        resultsPage = false,
        toggleAudio,
        modalStyle,
        wordType,
    } = props;
    const [show, setShow] = useState(false);
    const { cleanTrans, limitString } = useFunction();
    const { viewportWidth, mediaQuery, isMobile } = useViewport();

    const transArr = cleanTrans(word.word.translation);

    const activeStyle = (bool) => {
        return bool ? styles.correct : styles.incorrect;
    };

    const wordlistStyle = (type) => {
        if (type == 'firstword') {
            return styles.firstword;
        } else if (type == 'lastword') {
            return styles.lastword;
        } else {
            return;
        }
    };

    const wordlistBorder = (type) => {
        if (type == 'wordlist') {
            return styles.middleBorder;
        } else if (type == 'lastword') {
            return styles.lastBorder;
        }
    };

    const wordContainerWidth = {
        width: '30%',
    };

    return (
        <>
            <li
                className={
                    !resultsPage
                        ? modalStyle && viewportWidth >= mediaQuery.large
                            ? styles.wordListDual + ' ' + wordlistStyle(style)
                            : styles.wordListItem + ' ' + wordlistStyle(style)
                        : styles.wordListItemResult +
                          ' ' +
                          activeStyle(lessonResultsData)
                }
            >
                <div
                    className={
                        !resultsPage
                            ? styles.word + ' ' + wordlistBorder(style)
                            : styles.word
                    }
                >
                    <img
                        className={styles.playButton}
                        src={PlayButton}
                        onClick={toggleAudio}
                    />
                    <div onClick={() => setShow(true)}>
                        {!modalStyle && !isMobile && (
                            <HanziWord
                                word={word.word}
                                delegatedProps={{
                                    className: styles.wordSimplified,
                                }}
                            />
                        )}
                        {!modalStyle && !isMobile && (
                            <p className={styles.wordPinyin}>
                                {word.word.pinyin}{' '}
                            </p>
                        )}

                        {(modalStyle || isMobile) &&
                            (wordType == 'pinyin' ? (
                                <p
                                    className={styles.wordPinyin}
                                    style={wordContainerWidth}
                                >
                                    {word.word.pinyin}{' '}
                                </p>
                            ) : (
                                <HanziWord
                                    word={word.word}
                                    delegatedProps={{
                                        className: `${styles.wordSimplified} 
                                        ${styles.wordSimplifiedModal}`,
                                    }}
                                />
                            ))}

                        {(modalStyle && viewportWidth >= mediaQuery.large) ||
                        transArr.length === 1 ? (
                            <p className={styles.wordTranslation}>
                                {transArr[0]}
                            </p>
                        ) : (
                            <p className={styles.wordTranslation}>
                                {transArr[0]}, {limitString(transArr[1], 50)}
                            </p>
                        )}
                    </div>
                </div>
            </li>
            {show && !isMobile && (
                <WordCardModal
                    word={word.word}
                    translations={word.word.translation}
                    show={show}
                    toggleClose={() => setShow(!show)}
                />
            )}
            {show && isMobile && (
                <WordDefModal
                    word={word.word}
                    isOpen={show}
                    toggleClose={() => setShow(false)}
                    bottom={modalStyle ? 0 : 88}
                />
            )}
        </>
    );
};

export default MissedWords;
