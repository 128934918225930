import React, { useState, useEffect, useRef } from 'react';
import { LessonStudyData } from '../../types/api_response';
import useViewport from '../../customhooks/useViewport';
import ButtonColor from '../UI-Components/ButtonColor';
import LessonNavIcons from '../UI-Components/LessonNavIcons';
import styles from './_styles/PageNav.module.scss';

interface Props {
    studyData: LessonStudyData;
    lessonName: string;
    lessonId: string;
    vertical: boolean;
    testResults?: boolean;
    lessonCard?: boolean;
    lessonCardMobile?;
    buttonWidth?: string;
    margin?: string;
    listenAgain?: CallableFunction;
}

/**
 * @fileoverview
 * component used for navigating between lesson modes
 * @param {Props} props
 * @return {JSX.Element} - Rendered page nav component
 */
const PageNav = (props: Props) => {
    // Destructuring props
    const {
        studyData,
        vertical,
        lessonName,
        lessonId,
        testResults,
        lessonCard,
        lessonCardMobile,
        buttonWidth,
        margin = 0,
        listenAgain,
    } = props;

    // Generating URLs based on lesson details
    const listenMode = `/lesson/${lessonId}/${lessonName}/Listen`;
    const learnMode = `/lesson/${lessonId}/${lessonName}/Learn`;
    const testMode = `/lesson/${lessonId}/${lessonName}/Test`;

    // Study data details
    const listened = studyData.timesListened;
    const drilled = studyData.timesDrilled;
    const bestScore = studyData.bestTestScore;

    // Ref for container
    const containerRef = useRef(null);

    // Viewport information
    const viewport = useViewport();

    // Function to determine next lesson based on study data
    const nextLesson = () => {
        if (studyData.timesDrilled === 0) {
            return learnMode;
        } else if (studyData.bestTestScore === 0) {
            return testMode;
        } else {
            return listenMode;
        }
    };

    // State to store pixels from the left for positioning
    const [pixelsFromLeft, setPixelsFromLeft] = useState<number>(0);

    // State to store the current selected option (Listen, Learn, Test)
    const [optionValue, setOptionValue] = useState<string>(nextLesson());

    // Effect to calculate and update pixelsFromLeft on viewport changes
    useEffect(() => {
        const container = containerRef.current;
        const firstChild = container.firstElementChild;
        const pixelsFromLeft = !vertical
            ? firstChild.offsetLeft + 16
            : firstChild.offsetTop + 16;
        setPixelsFromLeft(pixelsFromLeft);
    }, [viewport]);

    // Styles for positioning lines based on viewport and orientation
    const leftStyle = testResults
        ? {
              left: pixelsFromLeft,
              right: pixelsFromLeft,
          }
        : {
              left: pixelsFromLeft,
              minWidth: `calc(50% - ${pixelsFromLeft}px - 8px)`,
          };

    const rightStyle = {
        right: pixelsFromLeft,
        minWidth: `calc(50% - ${pixelsFromLeft}px  - 8px)`,
    };

    const topStyle = testResults
        ? {
              top: pixelsFromLeft,
              bottom: pixelsFromLeft,
          }
        : {
              top: pixelsFromLeft,
              minHeight: `calc(50% - ${pixelsFromLeft}px  - 8px)`,
          };

    const bottomStyle = {
        bottom: pixelsFromLeft,
        minHeight: `calc(50% - ${pixelsFromLeft}px  - 8px)`,
    };

    // Function to add 'lineGrey' class based on conditions
    const lineGrey = (mode) => {
        if (listened <= 0 && mode === 'listen') {
            return styles.lineGrey;
        }
        if ((listened === 0 || drilled === 0) && mode === 'learn') {
            return styles.lineGrey;
        }
    };

    // Boolean to check if the orientation is sideways
    const sideways =
        vertical ||
        (testResults && viewport.viewportWidth <= viewport.mediaQuery.mobile)
            ? true
            : false;

    // Button height and width configurations
    const btnHeight = '44px';
    const btnWidth =
        buttonWidth != undefined ? buttonWidth : sideways ? '180px' : '120px';

    // Styles for the container div
    const style = {
        margin: margin,
        minHeight: sideways && '240px',
    };

    // Function to refresh the page
    const refreshPage = () => {
        window.location.reload();
    };

    // Function to check if the current page is the same as the specified lesson link
    const samePage = (lessonLink) => {
        const currentURL = window.location.href;
        if (currentURL.includes(lessonLink)) {
            return true;
        }
        return false;
    };

    return (
        <aside
            style={style}
            className={!sideways ? styles.vertical : styles.horizontal}
        >
            {/* LINES */}
            <div
                ref={containerRef}
                className={!sideways ? styles.navLine : styles.navLineSideways}
            >
                {/* LISTEN CIRCLE */}
                <LessonNavIcons type={listened === 0 ? 'start' : 'check'} />

                {/* FIRST LINE */}
                <div
                    className={`
                    ${lineGrey('listen')} 
                    ${!sideways ? styles.line : styles.lineSideways}`}
                    style={!sideways ? leftStyle : topStyle}
                ></div>

                {/* LEARN CIRCLE */}
                {listened === 0 ? (
                    <LessonNavIcons
                        type={
                            listened === 0
                                ? 'hide'
                                : drilled === 0 && listened >= 1
                                ? 'start'
                                : 'check'
                        }
                    />
                ) : drilled <= 0 ? (
                    <LessonNavIcons type={'start'} />
                ) : (
                    <LessonNavIcons type={'check'} />
                )}

                {/* SECOND LINE */}
                <div
                    className={`
                    ${lineGrey('learn')} 
                    ${sideways ? styles.lineSideways : styles.line} `}
                    style={sideways ? bottomStyle : rightStyle}
                ></div>

                {/* TEST CIRCLE */}
                <LessonNavIcons
                    type={
                        listened === 0 || drilled === 0
                            ? 'hide'
                            : bestScore <= 0
                            ? 'start'
                            : 'check'
                    }
                />
                {testResults && bestScore > 0 && (
                    <LessonNavIcons type={'check'} />
                )}
            </div>

            {/* TESTMODE RESULTS */}

            {/* BUTTONS */}
            {!lessonCard && !lessonCardMobile ? (
                <div
                    className={
                        !sideways
                            ? styles.navButtons
                            : styles.navButtonsSideways
                    }
                >
                    {/* LISTEN BUTTON */}
                    <ButtonColor
                        color={listened >= 1 ? 'blueClear' : 'blue'}
                        link={samePage(listenMode) ? undefined : listenMode}
                        height={btnHeight}
                        width={btnWidth}
                        onClick={listenAgain}
                    >
                        {listened >= 1 ? 'Listen Again' : 'Listen'}
                    </ButtonColor>

                    {/* LEARN BUTTON */}
                    <ButtonColor
                        color={
                            listened === 0
                                ? 'block'
                                : drilled > 0
                                ? 'blueClear'
                                : 'blue'
                        }
                        link={samePage(learnMode) ? '' : learnMode}
                        height={btnHeight}
                        width={btnWidth}
                        onClick={() => refreshPage()}
                    >
                        {drilled >= 1 ? 'Learn Again' : 'Learn'}
                    </ButtonColor>

                    {/* TEST BUTTON */}
                    <ButtonColor
                        color={
                            listened === 0 || drilled === 0
                                ? 'block'
                                : bestScore > 0
                                ? 'blueClear'
                                : 'blue'
                        }
                        link={samePage(testMode) ? '' : testMode}
                        height={btnHeight}
                        width={btnWidth}
                        onClick={() => refreshPage()}
                    >
                        {bestScore > 0
                            ? 'Test Again'
                            : bestScore <= 0 &&
                              testResults &&
                              samePage(testMode)
                            ? 'Retry'
                            : 'Test'}
                    </ButtonColor>

                    {testResults && bestScore > 0 && (
                        <ButtonColor
                            color={'blue'}
                            link={'/dashboard'}
                            height={btnHeight}
                            width={btnWidth}
                        >
                            Complete
                        </ButtonColor>
                    )}
                </div>
            ) : (
                <>
                    <div className={styles.navButtons}>
                        <ButtonColor
                            color={
                                optionValue === listenMode
                                    ? 'pageNavSelect'
                                    : 'pageNav'
                            }
                            height={btnHeight}
                            width={btnWidth}
                            onClick={() => setOptionValue(listenMode)}
                        >
                            Listen
                        </ButtonColor>
                        <ButtonColor
                            color={
                                optionValue === learnMode
                                    ? 'pageNavSelect'
                                    : 'pageNav'
                            }
                            height={btnHeight}
                            width={btnWidth}
                            onClick={() => setOptionValue(learnMode)}
                        >
                            Learn
                        </ButtonColor>
                        <ButtonColor
                            color={
                                drilled <= 0
                                    ? 'block'
                                    : optionValue === testMode
                                    ? 'pageNavSelect'
                                    : 'pageNav'
                            }
                            height={btnHeight}
                            width={btnWidth}
                            onClick={
                                drilled <= 0
                                    ? () => ''
                                    : () => setOptionValue(testMode)
                            }
                        >
                            Test
                        </ButtonColor>
                    </div>
                    <ButtonColor
                        color={'blue'}
                        link={optionValue}
                        height={'60px'}
                        width={lessonCardMobile ? '95%' : '83%'}
                        margin={'1rem 0 0'}
                        fontsize={'20px'}
                    >
                        {bestScore <= 0
                            ? 'Continue this lesson'
                            : 'Review This Lesson'}
                    </ButtonColor>
                </>
            )}
            <div className={styles.mobileCentered}></div>
        </aside>
    );
};

export default PageNav;
