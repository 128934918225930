import React, { useContext } from 'react';
import styles from './_styles/FCWord.module.scss';
import UserContext from '../../../context/UserContext';
import HanziWord from '../../GlobalComponents/HanziWord';
import { Word } from '../../../types/api_response';

interface Props {
    word: Word;
}

// eslint-disable-next-line valid-jsdoc
/**
 *
 * @fileOverview
 * This component handles the logic for the word. Depending on the users settings, the hanzi, pinyin, or both words will be shown
 *
 * @param {Props} props
 * It receives word data for displaying the lesson word
 *
 * @return {JSX.Element} - Rendered FC word
 */
const FCWord = (props: Props): JSX.Element => {
    const { pinyin, bothTypes } = useContext(UserContext);
    const { word } = props;

    /* This is for whats that are very long and need to be smaller to fit on mobile */
    const fontScale = (pinyinWord: string): string => {
        return pinyinWord.split('').length <= 11
            ? styles.bigword
            : styles.longbigword;
    };

    return (
        <>
            <div className={styles.wordSimple}>
                {/* Shows only pinyin or pinyin on top and hanzi on bottom */}
                {(pinyin || bothTypes) && (
                    <p
                        className={
                            bothTypes
                                ? styles.smallword
                                : fontScale(word.pinyin)
                        }
                    >
                        {word.pinyin}
                    </p>
                )}
                {/* Show Traditional or Simplified*/}
                {(!pinyin || bothTypes) && (
                    <HanziWord word={word} size="large" />
                )}
            </div>
        </>
    );
};

export default FCWord;
