import { useState, useEffect } from 'react';
import useFetch from '../useFetch';
import { Resource } from '../types/api_response';

const useGetResources = () => {
    const [resourcesData, setResourcesData] = useState<Resource[] | []>([]);
    const { getReq } = useFetch();

    useEffect(() => {
        getReq('/grammar').then((res: any) => {
            const data: Resource[] = res.data;
            setResourcesData(data);
        });
    }, []);

    return { resourcesData, setResourcesData };
};

export default useGetResources;
