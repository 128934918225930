import React, { useState } from 'react';
import { UserWord, WordType } from '../../../types/api_response';
import useFunction from '../../../useFunction';
import useViewport from '../../../customhooks/useViewport';
import HanziWord from '../../GlobalComponents/HanziWord';
import WordListModal from '../../GlobalComponents/WordList/WordListModal';
import WordListTypeToggle from '../../GlobalComponents/WordList/WordListTypeToggle';
import styles from './_styles/WordListDash.module.scss';

interface Props {
    wordlist: UserWord[];
    wordType: WordType['wordType'];
    setWordType: CallableFunction;
    sortType: string;
    toggleVocabType?: boolean;
    title: string;
    delegatedProps: any;
}

// eslint-disable-next-line valid-jsdoc
/**
 * @fileoverview
 * display a preview of the current week's words
 *
 * @param {Props} props
 * - wordList: array of userwords
 * - wordType: display the type of word to appear ex. hanzi/pinyin
 * - setWordType: set the wordType within the wordlist
 * - sortType: variable to sort the list of words
 * - toggleVocabType: show the wordtype toggle for hanzi/pinyin
 *
 * @return {JSX.Element} - Rendered word list for dashboard
 */
const WordsListDash = (props: Props): JSX.Element => {
    const {
        wordlist,
        wordType,
        setWordType,
        sortType,
        title,
        toggleVocabType = false,
    } = props;
    const { viewportWidth, mediaQuery } = useViewport();
    const { cleanTrans, wordSort } = useFunction();
    const [isWordListOpen, setIsWordListOpen] = useState<boolean>(false);

    return (
        <>
            <aside className={styles.flashcardsList}>
                <div className={styles.sectHeader}>
                    {/* shwo the word type toggle for hanzi pinyin */}
                    {toggleVocabType && (
                        <WordListTypeToggle
                            english={true}
                            wordType={wordType}
                            setWordType={setWordType}
                        />
                    )}
                </div>
                <h4>{title}</h4>
                <div className={styles.wordlist}>
                    {/* Map filtered and sorted words */}
                    {wordSort(wordlist, sortType).map((word, i) => {
                        const limit =
                            viewportWidth > mediaQuery.mobile ? 20 : 10;

                        const translation = cleanTrans(
                            word.word.translation,
                        )[0];

                        if (i < limit) {
                            return (
                                <div
                                    key={word.word.uuid}
                                    className={styles.wordlistitem}
                                >
                                    {wordType !== 'hanzi' ? (
                                        <p className={styles.pinyinList}>
                                            {wordType == 'english' &&
                                                translation}
                                            {wordType == 'pinyin' &&
                                                word.word.pinyin}
                                        </p>
                                    ) : (
                                        <HanziWord
                                            word={word.word}
                                            delegatedProps={{
                                                className: styles.hanziList,
                                            }}
                                        />
                                    )}
                                </div>
                            );
                        }
                    })}
                </div>
                <p onClick={() => setIsWordListOpen(true)}>see more</p>
            </aside>
            {/* Modal that lists all the words for the current week */}
            <WordListModal
                toggleClose={() => setIsWordListOpen(false)}
                setWordType={setWordType}
                isOpen={isWordListOpen}
                userWords={wordlist}
                wordType={wordType}
            />
        </>
    );
};

export default WordsListDash;
