import React from 'react';
import selectorUp from '../../../public/Images/Selector/selector-up.svg';
import selectorDown from '../../../public/Images/Selector/selector-down.svg';
import styles from './_styles/Selector.module.scss';

interface Props {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
    toggleUp: any;
    toggleDown: any;
    selectorHeight: string;
    height: string;
    width: string;
}

/**
 * Selector component for rendering a selector with up and down arrows
 * @param {Props} props - Component props including top, bottom, left, right positioning, toggleUp and toggleDown functions, selectorHeight, width, and height
 * @return {JSX.Element} - Rendered Selector component
 */
const Selector = (props: Props): JSX.Element => {
    const {
        top,
        bottom,
        left,
        right,
        toggleUp,
        toggleDown,
        selectorHeight = '28px',
        width = '14px',
        height = '10px',
    } = props;

    /* The parent element needs to have 
    relative positioning to place the component*/
    const style = {
        top: top,
        bottom: bottom,
        left: left,
        right: right,
        height: selectorHeight,
    };

    // CSS style for the arrow images
    const styleImg = {
        width: width,
        height: height,
    };

    return (
        <div className={styles.selector} style={style}>
            <img src={selectorUp} onClick={toggleUp} style={styleImg} />
            <img src={selectorDown} onClick={toggleDown} style={styleImg} />
        </div>
    );
};

export default Selector;
