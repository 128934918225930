import React from 'react';
import SearchIcon from '../../../public/Images/search-icon.svg';
import styles from './_styles/SearchBar.module.scss';

interface Props {
    value: string;
    handleChange: any;
    placeholder: string;
    padding?: string;
    name: string;
    width: string /* query */;
}

/**
 * SearchBar component for rendering a search input with an optional icon
 * @param {Props} props - Component props including value, handleChange function, placeholder, padding, name, and width
 * @return {JSX.Element} - Rendered SearchBar component
 */
const SearchBar = (props: Props): JSX.Element => {
    const { value, handleChange, placeholder, padding, name, width } = props;

    // CSS style for the container label
    const style = {
        padding: padding,
        width: width,
    };

    // CSS style for the input element
    const inputStyle = {
        width: width,
    };

    return (
        <label htmlFor={name} className={styles.searchBar} style={style}>
            <img src={SearchIcon} />
            <input
                className={styles.searchInput}
                style={inputStyle}
                type="search"
                name={name}
                id={name}
                value={value}
                placeholder={placeholder}
                onChange={handleChange}
            />
        </label>
    );
};

export default SearchBar;
