import React, { useState, useContext } from 'react';
import { Resource } from '../../../types/api_response';
import UserContext from '../../../context/UserContext';
import useFunction from '../../../useFunction';
import useSearch from '../../../customhooks/useSearch';
import useViewport from '../../../customhooks/useViewport';
import useGetResources from '../../../customhooks/useGetResources';
import FilterSidebar from '../../GlobalComponents/FilterSideBar';
import FilterButton from '../../UI-Components/FilterButton';
import GrammarResource from './GrammarResource';
import LoadingSpinner from '../../UI-Components/LoadingSpinner';
import MobileHeader from '../../GlobalComponents/MobileHeader';
import Navbar from '../../BaseLayout/Navbar';
import SearchBar from '../../UI-Components/SearchBar';
import gridStyles from '../PageGrid.module.scss';
import styles from './_styles/Resources.module.scss';

/**
 *
 * @fileoverview
 * Page component for rendering all resrouces and filtering
 *
 * @return {JSX.Element} - Rendered resources page
 */
const Resources = (): JSX.Element => {
    const { filterResources } = useFunction();
    const { currentWeek } = useContext(UserContext);
    const [selectedWeek, setSelectedWeek] = useState<string>(currentWeek);
    const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
    const { isMobile } = useViewport();
    const { resourcesData } = useGetResources();
    const { searchResources, searchQueary, setSearchQuery } = useSearch({
        resources: resourcesData,
    });

    const resources: Resource[] = filterResources(
        searchResources(resourcesData),
        selectedWeek,
    );

    return (
        <div className={`${gridStyles.pageGrid} ${styles.pageGrid}`}>
            <LoadingSpinner />
            {/* Mobile header with breadcrumb and filter button */}
            <MobileHeader>
                <h3 className={styles.headerBreadCrumb}>Resources</h3>
                <FilterButton
                    style={'mobile'}
                    onClick={() => setIsFilterOpen(!isFilterOpen)}
                />
            </MobileHeader>
            {/* Main content section */}
            <main className={styles.resourcePageBody}>
                <div className={styles.resourceHeader}>
                    {/* Search bar for filtering resources */}
                    <SearchBar
                        value={searchQueary}
                        handleChange={(e) => setSearchQuery(e.target.value)}
                        placeholder={'Input Title or Tag'}
                        name={'resource-search'}
                        width={'100%'}
                    />

                    {/* Filter button for desktop */}
                    {!isMobile && (
                        <FilterButton
                            style={'desktop'}
                            onClick={() => setIsFilterOpen(!isFilterOpen)}
                        />
                    )}
                </div>

                {/* List of grammar resources */}
                <div className={styles.grammarList}>
                    {resources.map((resource, index) => (
                        <GrammarResource key={index} resource={resource} />
                    ))}
                </div>

                {/* Filter sidebar for selecting weeks */}
                <FilterSidebar
                    isOpen={isFilterOpen}
                    toggleClose={() => setIsFilterOpen(false)}
                    setFilter={setSelectedWeek}
                />
            </main>
            {/* Navbar for mobile navigation */}
            <Navbar navType={'gridNavMobile'} />
        </div>
    );
};

export default Resources;
