/* eslint-disable require-jsdoc */
/* eslint-disable guard-for-in */

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useLoading } from './customhooks/useLoading';

export default function useFetch() {
    const history: History = useHistory();
    const { getAccessTokenSilently } = useAuth0();
    const { isLoading, setIsLoading } = useLoading();
    const loading = isLoading;

    const baseUrl = process.env.API_URI;

    useEffect(() => {
        const accessTokenCheck = async () => {
            try {
                const token = await getAccessTokenSilently();
                localStorage.setItem('auth0Token', token);
            } catch (e) {
                console.log(e.message);
            }
        };

        accessTokenCheck();
    }, [getAccessTokenSilently]);

    // Content-Type will automatically be set by browser due to quirk with including audio file & JSON in one query
    function initializeLesson(url, body) {
        return new Promise((resolve, reject) => {
            const jwt = localStorage.getItem('auth0Token');
            setIsLoading(true);
            const formData = new FormData();
            // eslint-disable-next-line guard-for-in
            for (const name in body) {
                formData.append(name, body[name]);
            }
            fetch(baseUrl + url, {
                method: 'post',
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
                body: formData,
            })
                .then((response) => {
                    if (response.status == 401 || !jwt) {
                        history.push('/login');
                        return;
                    }

                    return response.json();
                })
                .then((data) => {
                    if (!data) {
                        setIsLoading(false);
                        return reject(data);
                    }
                    setIsLoading(false);
                    resolve(data);
                })
                .catch((error) => {
                    setIsLoading(false);
                    reject(error);
                });
        });
    }

    function updateLesson(url, body) {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            const jwt = localStorage.getItem('auth0Token');

            // parse form data
            const formData = new FormData();
            for (const name in body) {
                formData.append(name, body[name]);
            }

            fetch(baseUrl + url, {
                method: 'put',
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
                body: formData,
            })
                .then((response) => {
                    if (response.status == 401 || !jwt) {
                        history.push('/login');
                        return;
                    }

                    return response.json();
                })
                .then((data) => {
                    if (!data) {
                        setIsLoading(false);
                        return reject(data);
                    }
                    setIsLoading(false);
                    resolve(data);
                })
                .catch((error) => {
                    setIsLoading(false);
                    reject(error);
                });
        });
    }

    function deleteLesson(url) {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            const jwt = localStorage.getItem('auth0Token');

            // parse form data

            fetch(baseUrl + url, {
                method: 'delete',
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            })
                .then((response) => {
                    if (response.status == 401 || !jwt) {
                        history.push('/login');
                        return;
                    }

                    return response.json();
                })
                .then((data) => {
                    if (!data) {
                        setIsLoading(false);
                        return reject(data);
                    }
                    setIsLoading(false);
                    resolve(data);
                })
                .catch((error) => {
                    setIsLoading(false);
                    reject(error);
                });
        });
    }

    /* **************** EM Requests ************************************ */

    function getReq(url) {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            const jwt = localStorage.getItem('auth0Token');
            fetch(baseUrl + url, {
                method: 'get',
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            })
                .then((response) => {
                    // console.log('made it jwt check');
                    if (response.status == 401 || !jwt) {
                        history.push('/login');
                        return;
                    }
                    return response.json();
                })
                .then((data) => {
                    if (!data) {
                        // console.log('No data');
                        setIsLoading(false);
                        return reject(data);
                    }
                    // console.log('data is good');
                    setIsLoading(false);
                    resolve(data);
                })
                .catch((error) => {
                    // console.log('Had an error');
                    setIsLoading(false);
                    reject(error);
                });
        });
    }

    function postReq(url, body) {
        return new Promise((resolve, reject) => {
            // setIsLoading(true);
            const jwt = localStorage.getItem('auth0Token');
            fetch(baseUrl + url, {
                method: 'post',
                body: JSON.stringify(body),
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            })
                .then((response) => {
                    // console.log('Response:', response);
                    if (response.status == 401 || !jwt) {
                        history.push('/login');
                        return;
                    }

                    return response.json();
                })
                .then((data) => {
                    if (!data) {
                        // setIsLoading(false);
                        return reject(data);
                    }
                    // setIsLoading(false);
                    resolve(data);
                })
                .catch((error) => {
                    // setIsLoading(false);
                    reject(error);
                });
        });
    }

    function putReq(url, body) {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            const jwt = localStorage.getItem('auth0Token');
            fetch(baseUrl + url, {
                method: 'put',
                body: JSON.stringify(body),
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            })
                .then((response) => {
                    if (response.status == 401 || !jwt) {
                        history.push('/login');
                        return;
                    }

                    return response.json();
                })
                .then((data) => {
                    if (!data) {
                        setIsLoading(false);
                        return reject(data);
                    }
                    setIsLoading(false);
                    resolve(data);
                })
                .catch((error) => {
                    setIsLoading(false);
                    reject(error);
                });
        });
    }

    function deleteReq(url) {
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            const jwt = localStorage.getItem('auth0Token');
            fetch(baseUrl + url, {
                method: 'delete',
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            })
                .then((response) => {
                    // console.log('made it jwt check');
                    if (response.status == 401 || !jwt) {
                        history.push('/login');
                        return;
                    }
                    return response.json();
                })
                .then((data) => {
                    if (!data) {
                        // console.log('No data');
                        setIsLoading(false);
                        return reject(data);
                    }
                    // console.log('data is good');
                    setIsLoading(false);
                    resolve(data);
                })
                .catch((error) => {
                    // console.log('Had an error');
                    setIsLoading(false);
                    reject(error);
                });
        });
    }

    function loginUser(url, body) {
        // console.log('logging in user');
        return new Promise((resolve, reject) => {
            setIsLoading(true);
            const jwt = localStorage.getItem('auth0Token');
            fetch(baseUrl + url, {
                method: 'post',
                body: JSON.stringify(body),
                headers: {
                    Authorization: `Bearer ${jwt}`,
                },
            })
                .then((response) => {
                    if (response.status === 401) {
                        // resolve({ error: 401 });
                        // alert('Incorrect Username or Password');
                        // return history.push('/login');
                    }
                    return response.json();
                })
                .then((data) => {
                    setIsLoading(false);
                    resolve(data);
                })
                .catch((error) => {
                    setIsLoading(false);
                    reject(error);
                });
        });
    }

    return {
        initializeLesson,
        updateLesson,
        deleteLesson,
        loading,
        loginUser,
        getReq,
        postReq,
        putReq,
        deleteReq,
    };
}
