import React from 'react';
import box from '../../../../public/Images/check-box.svg';
import boxCheck from '../../../../public/Images/checked-box.svg';
import styles from './_styles/LessonFilter.module.scss';

interface Props {
    clearFilters: () => void;
    lessonTitle: any;
    filters: any;
    setFilters: any;
    userLessons: any;
    setUserLessons: any;
    scrollToTop: any;
}

const LessonFilter = (props: Props): JSX.Element => {
    const {
        clearFilters,
        lessonTitle,
        filters,
        setFilters,
        userLessons,
        setUserLessons,
        scrollToTop,
    } = props;

    const quarters = ['Q1', 'Q2', 'Q3'];
    const weeks = ['W1', 'W2', 'W4', 'W5', 'W7', 'W8', 'W10'];

    return (
        <aside className={styles.filter} onClick={(e) => e.stopPropagation()}>
            <h4 className={styles.filterHeader}>Filters</h4>

            {/* This is for clearing the filters */}
            {filters.tags.length > 1 && (
                <div className={styles.filterSelect} onClick={clearFilters}>
                    <img src={boxCheck} />
                    <p>{lessonTitle}</p>
                </div>
            )}

            {/* Temporary solution for my lessons */}
            {filters.tags.length == 1 && (
                <>
                    <div
                        className={styles.filterSelect}
                        onClick={() => {
                            setUserLessons(!userLessons);
                            scrollToTop;
                        }}
                    >
                        {!userLessons ? (
                            <>
                                <img src={box} />
                            </>
                        ) : (
                            <>
                                <img src={boxCheck} />
                            </>
                        )}

                        <p>My Lessons</p>
                    </div>
                </>
            )}
            {/* This maps the buttons to filter by quarter */}
            {!userLessons &&
                filters.tags.length == 1 &&
                quarters.map((quarter, index) => {
                    return (
                        <div
                            key={quarter}
                            className={styles.filterSelect}
                            onClick={() => {
                                setFilters(({ ...prev }) => {
                                    prev.tags.push(quarter);
                                    return prev;
                                });
                                scrollToTop;
                            }}
                        >
                            <img src={box} />
                            <p>Quarter {index + 1}</p>
                        </div>
                    );
                })}

            {/*  */}
            {filters.tags.length > 1 &&
                weeks.map((week) => {
                    return (
                        <div
                            key={week}
                            className={styles.filterSelectSub}
                            onClick={() => {
                                setFilters(({ ...prev }) => {
                                    prev.tags[2] = week;
                                    return prev;
                                });
                                scrollToTop;
                            }}
                        >
                            {filters.tags[2] === week ? (
                                <>
                                    <img src={boxCheck} />
                                </>
                            ) : (
                                <>
                                    <img src={box} />
                                </>
                            )}
                            <p>Week {week.replace('W', '')}</p>
                        </div>
                    );
                })}
        </aside>
    );
};

export default LessonFilter;
