import React from 'react';
import styles from './_styles/CircleBar.module.scss';

interface Props {
    /** Color of the progress indicator */
    color: string;
    /** Color of the track (background) of the progress bar */
    trackColor: string;
    /** Percentage of completion for the progress bar */
    percentage: number;
    /** Height of the circular progress bar */
    height: number;
    /** Font size for the percentage text inside the progress bar */
    fontsize: number;
    /** Stroke width for the progress bar */
    stroke: number;
}

/**
 * CircleBar component for rendering a circular progress bar
 * @param {Props} props - Component props including color, trackColor, percentage, height, fontsize, and stroke
 * @return {JSX.Element} - Rendered CircleBar component
 */
const CircleBar = (props: Props): JSX.Element => {
    const { color, trackColor, percentage, height, fontsize, stroke } = props;

    // Calculations for the progress bar
    const size: number = height;
    const strokeWidth = stroke;
    const center = size / 2;
    const radius = center - strokeWidth;
    const progress = percentage;
    const arcLength = 2 * Math.PI * radius;
    const arcOfffset = arcLength * ((100 - progress) / 100);

    // Styles for the components
    const stylesIndicator = {
        position: 'relative',
        height: height,
        width: height,
    };

    const stylesFont = {
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: fontsize,
    };

    const stylesTextBox = {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    };

    const stylesSvg = {
        transform: 'rotate(-90deg)',
        height: height,
        width: height,
    };

    const stylesTrack = {
        cx: center,
        cy: center,
        r: radius,
        stroke: trackColor,
        strokeWidth: strokeWidth,
        fill: 'transparent',
    };

    const stylesIndication = {
        cx: center,
        cy: center,
        r: radius,
        stroke: color,
        strokeWidth: strokeWidth,
        strokeDasharray: arcLength,
        strokeDashoffset: arcOfffset,
        fill: 'transparent',
    };

    return (
        <>
            <div className={styles.circleGraph}>
                <div style={stylesIndicator}>
                    <div style={stylesTextBox}>
                        <p className={styles.percentage} style={stylesFont}>
                            {progress}%
                        </p>
                    </div>
                    <svg style={stylesSvg}>
                        <circle {...stylesTrack} />
                        <circle {...stylesIndication} />
                    </svg>
                </div>
            </div>
        </>
    );
};

export default CircleBar;
