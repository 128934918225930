import React from 'react';
import styles from './_styles/LessonModal.module.scss';
import { LessonData, LessonStudyData } from '../../../types/api_response';
import useFunction from '../../../useFunction';
import ProgressBar from '../../UI-Components/ProgressBar';
import CloseButton from '../../../../public/Images/ClearButtonX.svg';
import PageNav from '../../GlobalComponents/PageNav';
import ButtonColor from '../../UI-Components/ButtonColor';

interface Props {
    open: boolean;
    close: CallableFunction;
    lessonData: LessonData;
    studyData: LessonStudyData;
    wordPercent: number;
}

const LessonModal = (props: Props) => {
    const { open, close, lessonData, studyData, wordPercent } = props;
    const { secondsToMin, cleanTrans, cleanTag, lessonUrlName } = useFunction();
    const lessonName = lessonUrlName(lessonData.name);
    const lessonTag = cleanTag(cleanTrans(lessonData.tags));
    const testScore = parseFloat((studyData.bestTestScore * 100).toFixed(2));

    return (
        <>
            {open && (
                <aside className={styles.backdrop} onClick={() => close()}>
                    <aside
                        className={styles.lessonModal}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <img
                            src={CloseButton}
                            className={styles.closeButton}
                            onClick={() => close()}
                        />
                        <div className={styles.lessonContent}>
                            {studyData.bestTestScore !== 0 && (
                                <aside className={styles.testScore}>
                                    tested: {testScore}%
                                </aside>
                            )}
                            <img src={lessonData.image} />
                            <div className={styles.lessonDescription}>
                                <div>
                                    <h4>{lessonData.name}</h4>
                                    <p className={styles.lessonTime}>
                                        {secondsToMin(lessonData.length)}
                                    </p>
                                    <p className={styles.description}>
                                        {lessonData.description}
                                    </p>
                                </div>

                                <div className={styles.bottomDescription}>
                                    <p className={styles.tag}>{lessonTag}</p>
                                    <div className={styles.progressBar}>
                                        <ProgressBar
                                            color={'#13C2C2'}
                                            percentage={wordPercent}
                                            height={8}
                                        />
                                        <p>{wordPercent}% vocab matching</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.modalButtons}>
                            {studyData.timesListened <= 0 ? (
                                <ButtonColor
                                    color={'blue'}
                                    link={`/lesson/${lessonData.uuid}/${lessonName}/Listen`}
                                    width={'100%'}
                                    height={'62px'}
                                    fontsize={'20px'}
                                >
                                    Start{' '}
                                </ButtonColor>
                            ) : (
                                <PageNav
                                    studyData={studyData}
                                    lessonName={lessonName}
                                    lessonId={lessonData.uuid}
                                    sideways={false}
                                    lessonCard={true}
                                />
                            )}
                        </div>
                    </aside>
                </aside>
            )}
        </>
    );
};

export default LessonModal;
