import React, { useEffect } from 'react';
import { Route, useParams } from 'react-router-dom';
import useGetLessonModeData from '../../../customhooks/useGetLessonModeData';
import LoadingSpinner from '../../UI-Components/LoadingSpinner';
import ListenMode from './ListenMode';
import LearnMode from './LearnMode';
import TestMode from './TestMode';
import styles from './_styles/LessonPage.module.scss';

/**
 * @fileOverview
 * This component stores the routes for between all the lesson modes so that they can share the same datas
 *
 * @return {JSX.Element} - Rendered lesson route page component
 */
const LessonPage = (): JSX.Element => {
    const lessonId: { lessonId: string } = useParams();
    const { lessonInterface, audioPlayer, audioTime } = useGetLessonModeData({
        lessonId: lessonId.lessonId,
    });

    useEffect(() => {
        return () => {
            if (audioPlayer) {
                audioPlayer.stop();
            }
        };
    }, []);

    if (!lessonInterface || audioPlayer == null) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <div className={styles.body}>
                <Route path="/lesson/:lessonId/:lessonName/Listen">
                    <ListenMode
                        lesson={lessonInterface}
                        audioPlayer={audioPlayer}
                        audioTime={audioTime}
                    />
                </Route>
                <Route path="/lesson/:lessonId/:lessonName/Learn">
                    <LearnMode
                        lesson={lessonInterface}
                        audioPlayer={audioPlayer}
                    />
                </Route>
                <Route path="/lesson/:lessonId/:lessonName/Test">
                    <TestMode
                        lesson={lessonInterface}
                        audioPlayer={audioPlayer}
                    />
                </Route>
            </div>
        </>
    );
};
export default LessonPage;
