import { Lesson } from './types/api_response';

const filterLessons = (lessons: Lesson[], filters) => {
    // lessons is an array of lessons, filters is an object containing filter fields. Full example in Lessons.tsx component.
    //    The 'filters' parameter can take single filters like so: filterLessons(lessonsData, {onlyStudied: false, onlyNotStudied: true})

    return lessons.filter((lesson) => {
        return Object.keys(filters).reduce((acc, filter) => {
            const filterValues = filters[filter];
            const lessonValues = lesson.studyData;
            let found = false;

            if (filter == 'userAll') {
                if (
                    filterValues == true &&
                    (lessonValues.timesListened > 0 ||
                        lessonValues.timesDrilled > 0 ||
                        lessonValues.bestTestScore > 0)
                ) {
                    found = true;
                }
            }
            // In each subsequent logic gate, check filter conditions, define lessonValues
            // based on filter, execute filter's logic, and return found value
            if (filter == 'onlyStudied') {
                if (
                    (filterValues == true && lessonValues.timesListened > 0) ||
                    filterValues == false
                ) {
                    found = true;
                }
            }

            if (filter == 'onlyNotStudied') {
                if (
                    (filterValues == true && lessonValues.timesListened == 0) ||
                    filterValues == false
                ) {
                    found = true;
                }
            }

            if (filter == 'onlyTested') {
                if (
                    (filterValues == true && lessonValues.bestTestScore > 0) ||
                    filterValues == false
                ) {
                    found = true;
                }
            }

            if (filter == 'onlyNotTested') {
                if (
                    (filterValues == true && lessonValues.bestTestScore == 0) ||
                    filterValues == false
                ) {
                    found = true;
                }
            }

            if (filter === 'tags' || filter === 'name') {
                // used replace to remove the string brackets ==> ||
                const lessonValues = lesson.lessonData[filter].replace(
                    /[|]/gi,
                    '',
                );
                let val = '';

                if (typeof filterValues == typeof []) {
                    val = filterValues.join(' ');
                } else {
                    val = filterValues;
                }

                if (filterValues.length < 3) {
                    found = lessonValues.includes(val);
                } else {
                    found =
                        lessonValues.includes(val) &&
                        lessonValues.split(' ')[2] === filterValues[2];
                    // the and insures that the 3rd tag is the exact same
                }
            }

            return acc && found;
        }, true);
    });
};

export default filterLessons;
