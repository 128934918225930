/* eslint-disable require-jsdoc */
import { useState, useEffect } from 'react';
import moment from 'moment';

function checkForIOS(): {
    isIOS: boolean;
    isSafari: boolean;
    prompt: boolean;
} {
    if ((navigator as any).standalone) {
        return {
            isIOS: false,
            isSafari: false,
            prompt: false,
        };
    }

    const today: Date = moment().toDate();
    const lastPrompt: Date | null = localStorage.getItem('installPrompt')
        ? new Date(localStorage.getItem('installPrompt'))
        : null;
    const days: number = moment(today).diff(lastPrompt, 'days');
    const ua = window.navigator.userAgent;
    const webkit = !!ua.match(/WebKit/i);
    const isIPad = !!ua.match(/iPad/i);
    const isIPhone = !!ua.match(/iPhone/i);
    const isIOS = isIPad || isIPhone;
    const isSafari = isIOS && webkit && !ua.match(/CriOS/i);
    const prompt: boolean = (isNaN(days) || days > 1) && isIOS && isSafari;

    if (prompt && 'localStorage' in window) {
        localStorage.setItem('installPrompt', today.toISOString());
    }

    return { isIOS, isSafari, prompt };
}

export default function useIsIOS() {
    const [isIOS, setIsIOS] = useState({});

    useEffect(() => {
        setIsIOS(checkForIOS());
        // return () => console.log('CLEANUP INSTALL PROMPT', isIOS);
    }, []);

    return isIOS;
}
