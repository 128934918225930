import React from 'react';
import styles from './_styles/LessonModalMobile.module.scss';
import { LessonData, LessonStudyData } from '../../../types/api_response';
import useFunction from '../../../useFunction';
import ProgressBar from '../../UI-Components/ProgressBar';
import CloseButton from '../../../../public/Images/ClearButtonX.svg';
import LessonTag from '../../UI-Components/LessonTag';
import PageNav from '../../GlobalComponents/PageNav';
import ButtonColor from '../../UI-Components/ButtonColor';

interface Props {
    open: boolean;
    close: () => void;
    lessonData: LessonData;
    studyData: LessonStudyData;
    wordPercent: number;
}

const LessonModalMobile = (props: Props) => {
    const { open, close, lessonData, studyData, wordPercent } = props;
    const { secondsToMin, lessonUrlName } = useFunction();
    const lessonName = lessonUrlName(lessonData.name);
    const testScore = parseFloat((studyData.bestTestScore * 100).toFixed(2));

    return (
        <>
            {open && (
                <aside className={styles.backdrop}>
                    <aside className={styles.mobileModal}>
                        <img
                            src={CloseButton}
                            className={styles.closeButton}
                            onClick={() => close()}
                        />
                        <div className={styles.lessonContent}>
                            <h4 className={styles.lessonHeader}>
                                {lessonData.name}
                            </h4>
                            {studyData.bestTestScore !== 0 && (
                                <aside className={styles.testScore}>
                                    tested: {testScore}%
                                </aside>
                            )}
                            <img
                                src={lessonData.image}
                                className={styles.lessonImage}
                            />
                            <p className={styles.lessonTime}>
                                {secondsToMin(lessonData.length)}
                            </p>
                            <p className={styles.description}>
                                {lessonData.description}
                            </p>
                            <LessonTag tag={lessonData.tags} />
                            <div className={styles.progressBar}>
                                <ProgressBar
                                    color={'#13C2C2'}
                                    percentage={wordPercent}
                                    height={8}
                                />
                                <p>{wordPercent}% vocab matching</p>
                            </div>
                        </div>

                        <div className={styles.modalButtons}>
                            {studyData.timesListened <= 0 ? (
                                <ButtonColor
                                    color={'blue'}
                                    link={`/lesson/${lessonData.uuid}/${lessonName}/Listen`}
                                    height={'62px'}
                                    width={'95%'}
                                    fontsize={'20px'}
                                >
                                    Start{' '}
                                </ButtonColor>
                            ) : (
                                <PageNav
                                    studyData={studyData}
                                    lessonName={lessonName}
                                    lessonId={lessonData.uuid}
                                    sideways={false}
                                    lessonCardMobile={true}
                                />
                            )}
                        </div>
                    </aside>
                </aside>
            )}
        </>
    );
};

export default LessonModalMobile;
