import React, { useState } from 'react';
import styles from './_styles/WorksheetResource.module.scss';
import { Worksheet } from '../../../types/components';
import TagList from '../../UI-Components/TagList';
// import pageQuantity from '../../../../public/page-quantity.svg';

interface Props {
    workSheetObj: Worksheet;
}

// This component is still in progress
const WorksheetResource = (props: Props): JSX.Element => {
    const { workSheetObj } = props;
    const { title, tags, coverImage, imageFile } = workSheetObj;

    // State to manage image preview
    const [imagePreview, setImagePreivew] = useState<string | undefined>(
        undefined,
    );

    // Function to preview image
    const previewImg = (imgFile: File | undefined): string | undefined => {
        try {
            if (imgFile) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImagePreivew(reader.result as string);
                };
                reader.readAsDataURL(imgFile);
            }
        } catch (error) {
            console.error('Error previewing image:', error);
        }
        return imagePreview;
    };

    // Array of tags
    const tagArr = tags.split(' ');

    return (
        <div className={styles.worksheet}>
            {/* Render cover image or placeholder */}
            {imageFile?.name || coverImage ? (
                imageFile?.name ? (
                    <img
                        className={styles.coverImage}
                        src={previewImg(imageFile)}
                        alt="preview image"
                    />
                ) : (
                    <img
                        className={styles.coverImage}
                        src={coverImage}
                        alt="resource-cover-img"
                    />
                )
            ) : (
                <div className={styles.noCoverImage}>No Image</div>
            )}
            {/* Render worksheet details */}
            <div className={styles.worksheetBottom}>
                <div>
                    <p className={styles.type}>Review</p>
                    <p className={styles.title}>{title}</p>
                </div>
                {/* Render tags using TagList component */}
                <TagList tags={tagArr} />
            </div>
        </div>
    );
};

export default WorksheetResource;
