import React, { useState, useContext } from 'react';
import styles from './_styles/LessonSettingsMobile.module.scss';
import PageNav from '../../GlobalComponents/PageNav';
import SpeedSettingsModal from './SpeedSettingsModal';
import WordSettingsModal from '../../GlobalComponents/WordSettingsModal';
import CloseButton from '../../../../public/Images/ClearButtonX.svg';
import UserContext from '../../../context/UserContext';
import AudioInterface from './AudioInterface';
import { LessonStudyData } from '../../../types/api_response';

interface Props {
    toggleClose: () => void;
    audioPlayer?: AudioInterface;
    studyData?: LessonStudyData;
    lessonName?: string;
    lessonId?: string;
    fcMode?: boolean;
}

/**
 * @fileoverview
 * Displays the Lesson settings when in mobile view
 *
 * @param {Props} props
 * - toggleClose: handle close settings
 * - audioPlayer: audio controls for updating the lesson speed
 * - studyData: needed for accessing lesson navigations and moving between lessons
 * - lessonName: needed for lesson navigation
 * - lessonId: needed for lesson navigation
 * - fcMode: displays different styles if fc mode is true
 *
 * @return {JSX.Element} - Rendered lesson settings modal for mobile
 *
 */
const LessonSettingsMobile = (props: Props) => {
    const {
        lessonId,
        studyData,
        lessonName,
        toggleClose,
        audioPlayer,
        fcMode = false,
    } = props;

    const { userSpeed, setUserSpeed } = useContext(UserContext);
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
    const [isSpeedSettingsOpen, setIsSpeedSettingsOpen] =
        useState<boolean>(false);

    const updatePlaySpeed = (value: number) => {
        setUserSpeed(value);
        audioPlayer?.setSpeed(value);
    };

    return (
        <>
            <div className={styles.backdrop} onClick={toggleClose}>
                <div
                    className={!fcMode && styles.settingsModal}
                    onClick={(e) => e.stopPropagation()}
                >
                    {fcMode ? (
                        <>
                            <WordSettingsModal
                                toggleClose={() => setIsSettingsOpen(false)}
                                fcMode={false}
                                mobileStyle={true}
                            />
                        </>
                    ) : (
                        <>
                            <div className={styles.settingsTop}>
                                <div>
                                    <p>Jump to</p>
                                    <img
                                        src={CloseButton}
                                        onClick={toggleClose}
                                    />
                                </div>
                                {studyData && lessonId && lessonName && (
                                    <PageNav
                                        studyData={studyData}
                                        lessonId={lessonId}
                                        lessonName={lessonName}
                                        buttonWidth={'110px'}
                                    />
                                )}
                            </div>
                            <div className={styles.settingsBottom}>
                                <div
                                    className={styles.settingsButton}
                                    onClick={() => setIsSpeedSettingsOpen(true)}
                                >
                                    <p className={styles.buttonTop}>
                                        {userSpeed}x
                                    </p>
                                    <p className={styles.buttonBottom}>Speed</p>
                                </div>
                                <div
                                    className={styles.settingsButton}
                                    onClick={() => setIsSettingsOpen(true)}
                                >
                                    <p className={styles.buttonTop}>简/繁</p>
                                    <p className={styles.buttonBottom}>
                                        Display
                                    </p>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {isSpeedSettingsOpen && (
                <>
                    <SpeedSettingsModal
                        audioPlayer={audioPlayer}
                        playSpeed={userSpeed}
                        updateSpeed={updatePlaySpeed}
                        mobileStyle={true}
                        toggleClose={() => setIsSpeedSettingsOpen(false)}
                    />
                </>
            )}
            {isSettingsOpen && (
                <>
                    <WordSettingsModal
                        toggleClose={() => setIsSettingsOpen(false)}
                        fcMode={false}
                        mobileStyle={true}
                    />
                </>
            )}
        </>
    );
};

export default LessonSettingsMobile;
