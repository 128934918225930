import React from 'react';
import { Resource } from '../../../types/api_response';
import useFetch from '../../../useFetch';
import useGetResources from '../../../customhooks/useGetResources';
import useSearch from '../../../customhooks/useSearch';
import AdminTableList from './AdminTableList';
import ButtonColor from '../../UI-Components/ButtonColor';
import SearchBar from '../../UI-Components/SearchBar';
import styles from './_styles/LessonList.module.scss';

/**
 *
 * @fileoverview
 * ResourceList component for displaying and managing resources
 *
 * @return {JSX.Element} - Rendered resource list page component
 */
const ResourceList = () => {
    // Custom hook for handling API requests
    const { deleteReq } = useFetch();

    // Custom hook for fetching and managing resources data
    const { resourcesData, setResourcesData } = useGetResources();

    // Custom hook for searching and filtering resources
    const { searchResources, setSearchQuery, searchQueary, resourceTotal } =
        useSearch({ resources: resourcesData });

    // Function to handle the deletion of a resource
    const handleDelete = (UUID: string) => {
        deleteReq(`/admin/grammar/${UUID}`).then((data: any) => {
            if (data.status === 'success') {
                console.log('deleted successfully', data);
            } else {
                console.log(data);
            }
        });
        setResourcesData((prev) => {
            return prev.filter((resource) => {
                return resource.uuid !== UUID;
            });
        });
    };

    // Function to sort resources based on the updatedAt property
    const sortDate = (resources: Resource[]) => {
        return resources.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt));
    };

    return (
        <>
            {/* Search bar and create grammar button */}
            <div className={styles.searchBar}>
                <SearchBar
                    value={searchQueary}
                    handleChange={(e) => setSearchQuery(e.target.value)}
                    placeholder={'Input Title or Tag'}
                    name={'resource-search'}
                />
                <ButtonColor
                    color={'orangeClear'}
                    link={'/admin/edit-grammar'}
                    width={'148px'}
                >
                    Create grammar
                </ButtonColor>
            </div>
            {/* Displaying the list of resources in an AdminTableList component */}
            <AdminTableList
                isLessonTable={false}
                tableData={sortDate(searchResources(resourcesData))}
                resultsLength={resourceTotal}
                handleDelete={handleDelete}
            />
        </>
    );
};

export default ResourceList;
