import { useState, useEffect } from 'react';
import { Lesson } from '../types/api_response';
import useFetch from '../useFetch';

interface Props {
    getAdminLessons?: boolean;
}

const useGetLessons = (props: Props) => {
    const { getAdminLessons = false } = props;
    const [lessonsData, setLessonsData] = useState<Lesson[]>([]);
    const { getReq } = useFetch();

    useEffect(() => {
        if (!getAdminLessons) {
            getReq('/lessons').then((res: any) => {
                const lessons: Lesson[] = res.data;
                setLessonsData(lessons);
            });
        } else {
            getReq('/lessons?unfinished=true').then((data: any) => {
                const lessons: Lesson[] = data.data;
                setLessonsData(lessons);
            });
        }
    }, []);

    return { lessonsData, setLessonsData };
};

export default useGetLessons;
