import React from 'react';
import styles from './_styles/Modal.module.scss';

interface Props {
    children?: any;
    isOpen: boolean;
    className: string;
    toggleClose: CallableFunction;
    style?: object;
    backdropStyle?: object;
}

/**
 * Generic Modal component for displaying overlays.
 * @param {Props} props - The component props.
 * @return {JSX.Element} - The rendered component.
 */
export const Modal = ({
    children,
    isOpen,
    toggleClose,
    style,
    backdropStyle,
    ...props
}: Props): JSX.Element => {
    return (
        <>
            {/* Render the modal and backdrop only when isOpen is true */}
            {isOpen && (
                <aside
                    style={backdropStyle}
                    className={styles.backdrop}
                    onClick={() => toggleClose()}
                >
                    {/* Modal body with provided style, className, and children */}
                    <aside
                        style={style}
                        className={styles.modalBody + ' ' + props.className}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {children}
                    </aside>
                </aside>
            )}
        </>
    );
};

export default Modal;
