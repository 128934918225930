import React from 'react';
import ButtonColor from '../../UI-Components/ButtonColor';
import styles from './_styles/WordOptionsModal.module.scss';
import Modal from '../../GlobalComponents/Modal';

interface Props {
    block: boolean;
    isOpen: boolean;
    toggleClose: CallableFunction;
    confirm: CallableFunction;
}

/**
 * @fileOverview
 * This is a modal that handles blocking or mastering a word in a flashcard session
 *
 * @param {Props} props
 * - block: if block show the text for blocking, else show the mastering text
 * - isOpen: boolean for if the modal is open
 * - toggleClose: handles closing the modal
 * - confirm: handles the logic to block or master the word
 *
 * @return {JSX.Element} - Rendered word settings modal
 */
const wordSettingModal = (props: Props): JSX.Element => {
    const { block, isOpen, toggleClose, confirm } = props;
    const buttonHeight = '56px';

    return (
        <Modal
            isOpen={isOpen}
            toggleClose={() => toggleClose()}
            className={styles.settingsModal}
        >
            <h4>
                {block
                    ? 'Click confirm to block this word'
                    : 'Click confirm to mark word as mastered'}
            </h4>
            <div>
                <ButtonColor
                    onClick={() => toggleClose()}
                    height={buttonHeight}
                    color={'blueClear'}
                >
                    Cancel
                </ButtonColor>
                <ButtonColor
                    onClick={() => confirm()}
                    height={buttonHeight}
                    color={'blue'}
                >
                    Confirm
                </ButtonColor>
            </div>
        </Modal>
    );
};

export default wordSettingModal;
