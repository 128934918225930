import { useState, useEffect, useContext } from 'react';
import { UserWord } from '../types/api_response';
import UserContext from '../context/UserContext';
import useFetch from '../useFetch';

interface Props {
    userWordsFetched: boolean;
    getWeeklyWords?: boolean;
}

const useGetWords = (props: Props) => {
    const { userWordsFetched, getWeeklyWords = false } = props;
    const [userWords, setUserWords] = useState<UserWord[]>([]);
    const [weeklyVocabList, setWeeklyVocabList] = useState<UserWord[]>([]);
    const { currentWeek } = useContext(UserContext);
    const { getReq } = useFetch();

    // Filtering out ghost words and words with punctuation
    const containsPunctuation = (str) => {
        const punctuationRegex = /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/;
        return punctuationRegex.test(str) || str === '';
    };

    const filterIncompleteWords = (words: UserWord[]) => {
        if (words !== null) {
            return words.filter((word) => {
                const pinyin = word.word.pinyin;
                const simplified = word.word.simplified;
                const traditional = word.word.traditional;
                if (
                    pinyin !== '' &&
                    pinyin !== undefined &&
                    traditional !== undefined &&
                    !containsPunctuation(simplified)
                ) {
                    return word;
                }
            });
        } else {
            return [];
        }
    };

    useEffect(() => {
        if (!userWordsFetched) {
            getReq('/user/words').then((res: any) => {
                const userWords: UserWord[] = filterIncompleteWords(res.data);
                setUserWords(userWords);
            });
        }
        if (getWeeklyWords) {
            getReq(`/user/words?tag=${currentWeek}`).then((res: any) => {
                const weeklyWords: UserWord[] = filterIncompleteWords(res.data);
                setWeeklyVocabList(weeklyWords);
            });
        }
    }, [currentWeek]);

    return { userWords, weeklyVocabList };
};

export default useGetWords;
