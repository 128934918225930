import React from 'react';

// wrap color prop as a string
// define height, width will always be the max space available
// num === numerator
// den === denomanator
// needed to calculate css progress bar styling

interface Props {
    color: string;
    height: number;
    percentage?: number;
}

/**
 * ProgressBar component for rendering a progress bar with customizable color and height
 * @param {Props} props - Component props including color, height, and optional percentage
 * @return {JSX.Element} - Rendered ProgressBar component
 */
const ProgressBar = (props: Props): JSX.Element => {
    const { color, height, percentage = 0 } = props;

    // CSS styles for the progress bar container
    const containerStyles = {
        height: height,
        width: '100%',
        backgroundColor: '#e0e0de',
        borderRadius: 50,
    };

    // CSS styles for the filled portion of the progress bar
    const fillerStyles = {
        height: '100%',
        width: `${percentage <= 100 ? percentage : 100}%`,
        backgroundColor: color,
        borderRadius: 'inherit',
        textAlign: 'right',
        transition: 'width .2s', // Smooth transition effect for width changes
    };

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}></div>
        </div>
    );
};

export default ProgressBar;
