import React from 'react';
import styles from './_styles/LessonNavIcons.module.scss';
import CheckIcon from '../../../public/Images/FlashcardsIcons/check.svg';

interface Props {
    type: string;
}

/* 
TYPES:
check
start
next
hide
*/

/**
 * LessonNavIcons component for rendering icons used in lesson navigation
 * @param {Props} props - Component props including the type of the icon
 * @return {JSX.Element} - Rendered LessonNavIcons component
 */
const LessonNavIcons = (props: Props): JSX.Element => {
    const { type } = props;

    return (
        <div className={`${styles.wrapper} ${styles[type]}`}>
            {type === 'check' && <img src={CheckIcon} alt="Check Icon" />}
        </div>
    );
};

export default LessonNavIcons;
