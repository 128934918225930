import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useFunction from '../../../useFunction';
import UserContext from '../../../context/UserContext';
import useAutoPlay from '../../../customhooks/useAutoPlay';
import useUpdateLessonData from '../../../customhooks/useUpdateLessonData';
import AudioInterface from './AudioInterface';
import AudioBar from './AudioBar';
import CloseButton from '../../../../public/Images/ClearButtonX.svg';
import LessonInterface from './LessonInterface';
import MobileTopBar from '../../GlobalComponents/MobileTopBar';
import PageNavModal from '../../GlobalComponents/PageNavModal';
import PageNav from '../../GlobalComponents/PageNav';
import PhraseBlock from './PhraseBlock';
import PlayButtonImg from '../../../../public/Images/AudioIcons/btn-play.svg';
import WordCard from './WordCard';
import styles from './_styles/ListenMode.module.scss';

interface Props {
    lesson: LessonInterface;
    audioPlayer: AudioInterface;
    audioTime: number;
}

/**
 * @fileoverview
 * Listen mode component
 *
 * @param {Props} props
 * - lesson: This is the Lesson Interface that handles all the lesson data
 * - audioPlayer: handles the audio interface and playback
 * - audioTime: tracks the lesson audio time
 *
 * @return {JSX.Element} - Rendered Listen mode page component
 */
const ListenMode = (props: Props): JSX.Element => {
    const { lesson, audioPlayer, audioTime = 0 } = props;
    const [isTranscriptOpen, setIsTranscriptOpen] = useState<boolean>(false);
    const [firstFinish, setFirstFinish] = useState<boolean>(false);
    const [blurWords, setBlurWords] = useState<boolean>(true);
    const { percentageBar, lessonUrlName } = useFunction();
    const lessonId = useParams();
    const scrollRef = useRef(null);
    const { pinyin, bothTypes } = useContext(UserContext);

    /* Handles updating the lesson data */
    const { updateLesson, updatedStudyData } = useUpdateLessonData({
        lessonType: 'listen',
        lessonId: lessonId.lessonId,
        studyData: lesson.studyData,
        timesListened: lesson.studyData.timesListened,
    });

    useAutoPlay({ audioPlayer: audioPlayer });

    useEffect(() => {
        // Get the audio element from the audio player.
        const audioElement = audioPlayer.audioElement;
        // Callback function for handling audio end event.
        const handleAudioEnd = () => {
            console.log('finished');
        };
        // Callback function for scrolling into view.
        const handleScrollIntoView = () => {
            // Scroll into view if the ref is available.
            if (scrollRef.current) {
                scrollRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                });
            }
        };
        // If it's the first finish, update the lesson.
        if (firstFinish) {
            updateLesson('listen');
        }
        // Check if the audio element is available.
        if (audioElement) {
            // Add an event listener for the 'ended' event of the audio.
            audioElement.addEventListener('ended', handleAudioEnd);
            // Cleanup function for the useEffect hook.
            return () => {
                // Cleanup event listener.
                audioElement.removeEventListener('ended', handleAudioEnd);
                // Stop the audio playback and reset the current time.
                audioElement.pause();
                audioElement.currentTime = 0;
                // Scroll into view.
                setFirstFinish(true);
                handleScrollIntoView();
            };
        }
    }, [firstFinish, audioTime >= audioPlayer.audioElement.duration]);

    const progressBar = percentageBar(
        audioTime,
        lesson.phraseEndAudio(lesson.phrases.length - 1),
    );

    const lessonName = lessonUrlName(lesson.name);

    const playButtonStyle = () => {
        if (bothTypes) {
            return 'bothTypes';
        } else if (pinyin) {
            return 'pinyin';
        } else {
            return 'hanzi';
        }
    };

    return (
        <>
            <div className={styles.pageGrid}>
                <MobileTopBar
                    lessonName={lesson.name}
                    studyData={updatedStudyData}
                    lessonId={lessonId.lessonId}
                    audioPlayer={audioPlayer}
                />
                <main
                    className={`${styles.lessonContent} ${styles.listenModeStyle}`}
                >
                    <section className={styles.phrases}>
                        <h2 className={styles.instructionMessage}>
                            Listen to the article
                        </h2>
                        {/* Map out all the phrases in the lesson */}
                        {lesson.phrases.map((phrase, phraseIndex) => {
                            // phrase time is needs to be a number and controls many functions
                            const phraseTime = parseFloat(phrase.words[0].time);
                            const phraseLast =
                                parseFloat(phrase.words.slice(-2)[0].time) +
                                0.75;

                            /* Styling for play button */
                            const buttonActive =
                                audioTime >= phraseTime &&
                                audioTime <= phraseLast &&
                                styles.activeButton;

                            return (
                                <div
                                    className={styles.phraseFlex}
                                    key={phrase.uuid}
                                >
                                    {/* Set audio to phrase startTime with playFrom && then play audio */}
                                    <img
                                        src={PlayButtonImg}
                                        className={`
                                        ${buttonActive} 
                                        ${styles.phrasePlayBtn} 
                                        ${styles[playButtonStyle()]}`}
                                        onClick={() => {
                                            audioPlayer.playFrom(phraseTime);
                                            audioPlayer.play();
                                        }}
                                    />

                                    <PhraseBlock
                                        key={phrase.uuid}
                                        dimmed={audioTime < phraseTime}
                                    >
                                        {/* Map each word in each phrase */}
                                        {lesson?.phrases[phraseIndex].words.map(
                                            (PhraseWord, wordIndex) => {
                                                return (
                                                    <>
                                                        <WordCard
                                                            key={
                                                                PhraseWord.uuid
                                                            }
                                                            PhraseWord={
                                                                PhraseWord
                                                            }
                                                            blurWords={
                                                                blurWords
                                                            }
                                                            dimmed={
                                                                audioTime <
                                                                phraseTime
                                                            }
                                                            audioTime={
                                                                audioTime
                                                            }
                                                            wordEndTime={
                                                                lesson.getWordPlayTime(
                                                                    phraseIndex,
                                                                    wordIndex,
                                                                )[1]
                                                            }
                                                            wordType={'listen'}
                                                            isLastWord={
                                                                lesson.lastWordTime ===
                                                                PhraseWord.time
                                                            }
                                                            resultsPage={false}
                                                        />
                                                    </>
                                                );
                                            },
                                        )}
                                    </PhraseBlock>
                                </div>
                            );
                        })}
                    </section>
                    {/* Page nave for mobile view */}
                    {firstFinish && (
                        <div className={styles.mobilePageNav} ref={scrollRef}>
                            <PageNav
                                studyData={updatedStudyData}
                                lessonId={lessonId.lessonId}
                                lessonName={lessonName}
                                vertical={true}
                                testResults={lesson.studyData.bestTestScore > 0}
                                listenAgain={() => audioPlayer.playFrom(0)}
                            />
                        </div>
                    )}
                </main>

                {/* Page nav for desktop view */}
                <PageNavModal
                    autoOpen={firstFinish}
                    openButton={true}
                    studyData={updatedStudyData}
                    lessonId={lessonId.lessonId}
                    lessonName={lessonName}
                    listenAgain={() => audioPlayer.playFrom(0)}
                />
                <AudioBar
                    audioPlayer={audioPlayer}
                    audioControls={true}
                    isBlurred={blurWords}
                    handleBlur={() => setBlurWords(!blurWords)}
                    handlePlay={() => audioPlayer.play()}
                    progressBarValue={progressBar}
                    transcript={isTranscriptOpen}
                    handleOpenTranscript={() =>
                        setIsTranscriptOpen(!isTranscriptOpen)
                    }
                />
                {isTranscriptOpen && (
                    <div className={styles.transcriptModal}>
                        <img
                            src={CloseButton}
                            onClick={() => setIsTranscriptOpen(false)}
                        />
                        <h5>{lesson.name}</h5>
                        {lesson.phrases.map((phrase, phraseIndex) => {
                            // Get start and end times for the current phrase
                            const [start, end] =
                                lesson.getPhrasePlayTime(phraseIndex);
                            // Check if the current phrase is within the playback time range
                            const isCurrentPhrase =
                                audioTime >= start && audioTime <= end;
                            return (
                                <p
                                    key={phrase.uuid}
                                    className={`${styles.transcriptPhrase} ${
                                        isCurrentPhrase ? styles.selected : ''
                                    }`}
                                >
                                    {/* Render speaker data (if available) */}
                                    {phrase.speaker && `${phrase.speaker}: `}

                                    {/* Render phrase translation */}
                                    {phrase.translation}
                                </p>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
};

export default ListenMode;
