import React, { useState, useEffect } from 'react';
import styles from './_styles/PageNavModal.module.scss';
import NavIndicator from '../../../public/Images/NavIcons/nav-indicator.svg';
import PageNav from './PageNav';
import { LessonStudyData } from '../../types/api_response';

interface Props {
    openButton: boolean; // Indicates if the nav open button appears
    autoOpen?: boolean; // Option to auto-open the nav
    studyData: LessonStudyData;
    lessonId: string;
    lessonName: string;
    listenAgain?: CallableFunction; // Callback for Listen mode to open nav automatically after audio finishes
}

/**
 * @fileoverview - used for displaying the pageNav component in a modal form in all lesson mode
 * @param {Props} props
 * @return {JSX.Element} - Rendered Page nav modal
 */
const PageNavModal = (props: Props): JSX.Element => {
    // Destructuring props
    const {
        openButton,
        autoOpen,
        studyData,
        lessonId,
        lessonName,
        listenAgain,
    } = props;

    // State to manage whether the navigation is open or closed
    const [isOpen, setIsOpen] = useState(!openButton);

    // Effect to auto-open the navigation when autoOpen is true
    useEffect(() => {
        if (autoOpen) {
            setIsOpen(true);
        }
    }, [autoOpen]);

    return (
        <section className={styles.lessonNav}>
            {/* Render PageNavModal if isOpen is true */}
            {isOpen && (
                <div className={styles.pageNavModal}>
                    {/* Render PageNav component */}
                    <PageNav
                        studyData={studyData}
                        lessonName={lessonName}
                        lessonId={lessonId}
                        vertical={true}
                        testResults={studyData.bestTestScore > 0}
                        listenAgain={listenAgain}
                    />
                </div>
            )}

            {/* Render nav open button if openButton is true */}
            {openButton && (
                <>
                    {/* Toggle button to open/close the navigation */}
                    <img
                        className={!isOpen && styles.closeToggle}
                        onClick={() => setIsOpen(!isOpen)}
                        src={NavIndicator}
                        alt="nav-open"
                    />
                </>
            )}
        </section>
    );
};

export default PageNavModal;
