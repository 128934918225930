import React from 'react';
import styles from './_styles/SwitchButton.module.scss';

// input and label require the id and html value to be the same so you must use the name prop

// any name will do. if there are multiple switches make sure to use different names

interface Props {
    isOn: boolean;
    handleToggle: any;
    name: string;
}

/**
 * SwitchButton component for rendering a switch button with a checkbox
 * @param {Props} props - Component props including isOn, handleToggle, and name
 * @return {JSX.Element} - Rendered SwitchButton component
 */
const SwitchButton = (props: Props): JSX.Element => {
    // CSS style for the background color when the switch is on
    const bgColor = {
        backgroundColor: '#06D6A0',
    };

    return (
        <>
            {/* Checkbox input for the switch */}
            <input
                checked={props.isOn}
                onChange={props.handleToggle}
                className={styles.checkbox}
                id={props.name}
                type="checkbox"
            />
            {/* Label for styling the switch button with background color */}
            <label
                style={props.isOn ? bgColor : undefined}
                className={styles.label}
                htmlFor={props.name}
            >
                {/* Span element for the switch button */}
                <span className={styles.switchButton} />
            </label>
        </>
    );
};

export default SwitchButton;
