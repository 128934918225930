import React, { useState, useContext } from 'react';
import { WordType } from '../../../types/api_response';
import useFunction from '../../../useFunction';
import useGetLessons from '../../../customhooks/useGetLessons';
import useGetWords from '../../../customhooks/useGetWords';
import ButtonColor from '../../UI-Components/ButtonColor';
import FCPreferences from '../FlashCards/FCPreferences';
import InstallPWAModal from '../../GlobalComponents/InstallPWAModal';
import LessonFilterModal from './LessonFilterModal';
import LessonCardListItem from '../LessonStore/LessonCardListItem';
import LoadingSpinner from '../../UI-Components/LoadingSpinner';
import MobileHeader from '../../GlobalComponents/MobileHeader';
import Navbar from '../../BaseLayout/Navbar';
import tagParser from '../../../TagParser';
import UserDropdownMenu from '../../GlobalComponents/UserDropdownMenu';
import UserContext from '../../../context/UserContext';
import WordsListDash from './WordsListDash';
import gridStyles from '../PageGrid.module.scss';
import styles from './_styles/Dashboard.module.scss';
import useGetResources from '../../../customhooks/useGetResources';

/**
 *
 * @fileOverview
 * - This is the Dashboard page for the app
 * - User can access lessons and start flashcard study from here
 * - User can select their current week from here
 * @dataOrigin
 * This page fetches all lessons and all user words as well as the weelkly words
 *
 *
 * @return {JSX.Element} - Rendered dashboard page component
 */
const Dashboard = (): JSX.Element => {
    const [isPreferencesOpen, setIsPreferencesOpen] = useState<boolean>(false);
    const [isSelectWeekOpen, setIsSelectWeekOpen] = useState<boolean>(false);

    /* Selector state for weekly word list word style */
    const [wordTypeToggle, setWordTypeToggle] =
        useState<WordType['wordType']>('hanzi');

    const { getQuarterWeekTag, lessonFilter, lessonSort } = tagParser();
    const { checkDueWords, filterResources } = useFunction();
    const { currentWeek } = useContext(UserContext);

    /* Hook for getting lessons data */
    const { lessonsData } = useGetLessons({ getAdminLessons: false });
    const { resourcesData } = useGetResources();

    /* Hook for getting word data */
    const { userWords, weeklyVocabList } = useGetWords({
        userWordsFetched: false,
        getWeeklyWords: true,
    });

    const weeklyResourceTotal = filterResources(resourcesData, currentWeek);
    const filteredLessons = lessonFilter(lessonsData, currentWeek);
    const sortedLessons = lessonSort(filteredLessons, 'tag');
    const noWordsStyle =
        weeklyVocabList.length == 0 && styles.lessonListNoWords;

    return (
        <>
            {/* Pop up modal to show users how to install pwa on iphone */}
            <InstallPWAModal />
            <LoadingSpinner />
            {isPreferencesOpen && (
                <FCPreferences
                    toggleClose={() => setIsPreferencesOpen(false)}
                    userWordsFetched={true}
                    fetchedUserWords={userWords}
                    weeklywordsFetched={true}
                    fetchedWeeklyWords={weeklyVocabList}
                />
            )}
            {isSelectWeekOpen && (
                <LessonFilterModal close={() => setIsSelectWeekOpen(false)} />
            )}

            <main className={gridStyles.pageGrid}>
                {/* Header that only exists when at mobile screen size */}
                <MobileHeader>
                    <div></div>
                    <UserDropdownMenu />
                </MobileHeader>
                {/* Navbar that only exists when at mobile screen size */}
                <Navbar navType={'gridNavMobile'} />

                <div className={styles.dashboard}>
                    {/* The 3 boxes at the top of dashboard */}
                    <div className={styles.dashNav}>
                        {/* Box for selecting user's current week */}
                        <aside>
                            <p>Current week</p>
                            <h3>{getQuarterWeekTag(currentWeek)}</h3>
                            <ButtonColor
                                onClick={() => setIsSelectWeekOpen(true)}
                                color={'blueClear'}
                                height={'44px'}
                                width={'156px'}
                            >
                                Select week
                            </ButtonColor>
                        </aside>
                        {/* Box for starting a flashcard session */}
                        <aside>
                            <p>Vocab due</p>
                            <h3>
                                {userWords
                                    ? checkDueWords(userWords, true).length
                                    : 0}
                            </h3>
                            <ButtonColor
                                onClick={() => setIsPreferencesOpen(true)}
                                color={'blue'}
                                height={'44px'}
                                width={'156px'}
                            >
                                Review now
                            </ButtonColor>
                        </aside>
                        {/* Weekly resources */}
                        <aside id={styles.resources}>
                            <p>Weekly resources</p>
                            <h3>{weeklyResourceTotal.length}</h3>
                            <ButtonColor
                                link={'/resources'}
                                color={'blueClear'}
                                height={'44px'}
                                width={'156px'}
                            >
                                View Resources
                            </ButtonColor>
                        </aside>
                    </div>
                    <div className={styles.dashContent}>
                        {/* Current week's list of lessons */}
                        <aside
                            className={styles.lessonList + ' ' + noWordsStyle}
                        >
                            <div className={styles.sectHeader}>
                                <h4>Lessons of the week</h4>
                            </div>
                            <div className={styles.itemList}>
                                {sortedLessons.map((lesson) => {
                                    return (
                                        <LessonCardListItem
                                            key={lesson.lessonData.uuid}
                                            lesson={lesson}
                                            userWords={userWords}
                                        />
                                    );
                                })}
                            </div>
                        </aside>
                        {/* Weekly vocab list. If no words, it does not appear */}
                        {weeklyVocabList.length > 0 && (
                            <div className={styles.wordList}>
                                <h4>Vocab of the week</h4>
                                {/* Preview of the current week's words */}
                                <WordsListDash
                                    sortType={'recent'}
                                    wordlist={weeklyVocabList}
                                    toggleVocabType={true}
                                    wordType={wordTypeToggle}
                                    setWordType={setWordTypeToggle}
                                    title={'Recently learned'}
                                />
                                <WordsListDash
                                    sortType={'frequency'}
                                    wordlist={weeklyVocabList}
                                    wordType={wordTypeToggle}
                                    setWordType={setWordTypeToggle}
                                    title={'Frequently used'}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </>
    );
};

export default Dashboard;
