import React, { useState, useEffect, useRef } from 'react';
import { Word } from '../../types/api_response'; // Import Word type
import useGetWordExamples from '../../customhooks/useGetWordExamples'; // Custom hook for fetching word examples
import useFunction from '../../useFunction'; // Custom hook for utility functions
import ExampleSentence from './ExampleSentence'; // Component for rendering example sentences
import doubleCheveron from '../../../public/Images/double-chevrons-down.svg'; // Double chevron icon
import HanziWord from './HanziWord'; // Component for rendering Hanzi word
import Modal from './Modal'; // Modal component
import volumePlay from '../../../public/Images/volume-2.svg'; // Volume play icon
import styles from './_styles/WordDefModal.module.scss'; // Styles for the WordDefModal component

interface Props {
    word: Word; // Word object containing word details
    isOpen: boolean; // Flag indicating whether the modal is open
    toggleClose: CallableFunction; // Function to toggle the modal closed
    bottom: number; // Bottom position of the modal
}

/**
 * @fileoverview - Display a word card modal with word data and examples
 * @param {Props} props
 * @return {JSX.Element} - Rendered word card modal
 */
const WordDefModal = (props: Props): JSX.Element => {
    const [showExamples, setShowExamples] = useState<boolean>(false); // State to control the visibility of examples
    const audioRef = useRef<HTMLAudioElement>(); // Reference to the audio element for pronunciation
    const { cleanTrans } = useFunction(); // Function for cleaning translations
    const { word, isOpen, toggleClose, bottom } = props;

    // Cleanup function when the component unmounts
    useEffect(() => {
        const audioElement = audioRef.current;
        return () => {
            audioElement?.pause(); // Pause the audio playback
            if (audioElement) {
                audioElement.currentTime = 0;
                audioElement.src = ''; // Clear the audio source
            }
        };
    }, []);

    const translation: string = cleanTrans(word.translation).join(', '); // Cleaned and joined translations
    const exampleSentences = useGetWordExamples({
        wordList: [word],
        phraseNum: 2,
    });

    // Style for positioning the modal
    const style = {
        position: 'absolute',
        bottom: `${bottom}px`,
    };

    return (
        <>
            {/* Audio element for word pronunciation */}
            <audio
                ref={audioRef}
                src={`https://storage.googleapis.com/production_word_audio_files/${word.simplified}.wav`}
            />

            {/* Modal component */}
            <Modal
                className={styles.wordModal}
                isOpen={isOpen}
                toggleClose={toggleClose}
                style={style}
            >
                {/* Container for word details */}
                <div className={styles.wordDef}>
                    <div className={styles.wordDefTop}>
                        {/* Display word details */}
                        <div className={styles.word}>
                            <p className={styles.pinyin}>{word.pinyin}</p>
                            <HanziWord word={word} />
                        </div>

                        {/* Play button for pronunciation */}
                        <img
                            className={styles.playAudio}
                            src={volumePlay}
                            alt="volume-play"
                            onClick={() => {
                                audioRef.current?.play();
                            }}
                        />
                    </div>

                    {/* Display translations */}
                    <p className={styles.translation}>{translation}</p>

                    {/* Display examples or toggle button */}
                    <div className={!showExamples && styles.wordExamples}>
                        {!showExamples ? (
                            <img
                                src={doubleCheveron}
                                alt="open-examples"
                                onClick={() => setShowExamples(true)}
                            />
                        ) : (
                            <>
                                <p className={styles.exampleTag}>
                                    Example Sentences
                                </p>
                                {exampleSentences[0].map((example, i) => {
                                    return (
                                        <ExampleSentence
                                            key={i}
                                            phrase={example}
                                            className={styles.exampleSentance}
                                        />
                                    );
                                })}
                            </>
                        )}
                    </div>
                </div>

                {/* Additional options (commented out for now) */}
                {/* <div className={styles.wordOptions}></div> */}
            </Modal>
        </>
    );
};

export default WordDefModal;
