import React from 'react';
import styles from './_styles/LoadingSpinner.module.scss';
import { useLoading } from '../../customhooks/useLoading';

/**
 * LoadingSpinner component for rendering a loading spinner based on the isLoading state
 * @return {JSX.Element | null} - Rendered LoadingSpinner component or null if not loading
 */
const LoadingSpinner = (): JSX.Element | null => {
    const { isLoading } = useLoading();

    return (
        <>
            {/* Render loading spinner only if isLoading is true */}
            {isLoading && (
                <div className={styles.page}>
                    <div className={styles.spinner}></div>
                </div>
            )}
        </>
    );
};

export default LoadingSpinner;
