import React from 'react';
import { Phrase } from '../../../types/api_response';
import AudioInterface from '../Lesson/AudioInterface';
import plusButton from '../../../../public/Images/plus.svg';
import splitButton from '../../../../public/Images/split.svg';
import TimingEditorLine from './TimingEditorLine';
import styles from './_styles/EditPhraseWordList.module.scss';

interface Props {
    audioPlayer: AudioInterface | null;
    activePhrase: Phrase | null;
    activeIndex: number;
    addWordHandler: CallableFunction;
    splitPhraseHandler: CallableFunction;
    deleteWordHandler: CallableFunction;
    deletePhraseHandler: CallableFunction;
    setActivePhrase: CallableFunction;
}

interface Props {
    audioPlayer: AudioInterface | null;
    activePhrase: Phrase | null;
    activeIndex: number;
    addWordHandler: CallableFunction;
    splitPhraseHandler: CallableFunction;
    deleteWordHandler: CallableFunction;
    deletePhraseHandler: CallableFunction;
    setActivePhrase: CallableFunction;
}

// eslint-disable-next-line valid-jsdoc
/**
 * @fileoverview
 * This component contains the list of word in each phrase and displays them so that they can be edited
 *
 * @return {JSX.Element} - Rendered lesson phrase & word editor component
 */
const EditPhraseWordList = (props: Props): JSX.Element => {
    const {
        audioPlayer,
        activePhrase,
        activeIndex,
        addWordHandler,
        splitPhraseHandler,
        deleteWordHandler,
        deletePhraseHandler,
        setActivePhrase,
    } = props;

    return (
        // Wrapper div for the entire component
        <div className={styles.phraseWordsEditor}>
            {/* Check if audioPlayer and activePhrase are truthy */}
            {audioPlayer && activePhrase && (
                <div className={styles.timingContainer}>
                    {/* Mapping through words in activePhrase */}
                    {activePhrase.words.map((word, wordIndex) => {
                        // Functions for adding and splitting words
                        const add = () =>
                            addWordHandler(activePhrase, wordIndex, word);

                        const split = () => {
                            splitPhraseHandler(activeIndex, wordIndex);
                        };

                        return (
                            <>
                                {/* Container for buttons */}
                                <div className={styles.buttonContainer}>
                                    {/* Button for adding a word */}
                                    <img onClick={add} src={plusButton} />

                                    {/* Button for splitting a word if conditions are met */}
                                    {word.markdown === '' && wordIndex > 0 && (
                                        <img
                                            onClick={split}
                                            src={splitButton}
                                        />
                                    )}
                                </div>
                                {/* Container for editing the timing of the word */}
                                <div className={styles.editLine}>
                                    {/* Component for editing timing */}
                                    <TimingEditorLine
                                        key={wordIndex}
                                        word={word}
                                        phrase={activePhrase}
                                        wordIndex={wordIndex}
                                        activeIndex={activeIndex}
                                        audioPlayer={audioPlayer}
                                        // Callback to update activePhrase
                                        cbUpdate={(updatedWords) => {
                                            setActivePhrase((phrase) => ({
                                                words: updatedWords,
                                                translation: phrase.translation,
                                                uuid: phrase.uuid,
                                            }));
                                        }}
                                        /* Callback to handle deletion of word or phrase */
                                        cbDelete={() => {
                                            activePhrase.words.length > 1
                                                ? deleteWordHandler(
                                                      activePhrase,
                                                      wordIndex,
                                                  )
                                                : deletePhraseHandler(
                                                      activeIndex,
                                                  );
                                        }}
                                    />
                                </div>
                                {/* Container for additional buttons when it's the last word */}
                                {wordIndex ===
                                    activePhrase.words.length - 1 && (
                                    <div className={styles.buttonContainer}>
                                        {/* Button for adding a word */}
                                        <img onClick={add} src={plusButton} />
                                        {/* Commented out button for splitting a word */}
                                        {/* <img
                                        onClick={split}
                                        src={splitButton}
                                    /> */}
                                    </div>
                                )}
                            </>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default EditPhraseWordList;
