import React, { useState } from 'react';
import { UserWord, WordType } from '../../../types/api_response';
import useViewport from '../../../customhooks/useViewport';
import ButtonColor from '../../UI-Components/ButtonColor';
import FCPreferences from '../../Pages/FlashCards/FCPreferences';
import selector from '../../../../public/Images/selector.svg';
import WordListTypeToggle from './WordListTypeToggle';
import styles from './_styles/WordListFilter.module.scss';

interface Props {
    children: any;
    filterParam: string;
    modalPositionTop: string;
    modalStyle: boolean;
    reviewButton: boolean;
    userWords: UserWord[];
    wordType: WordType['wordType'];
    setWordType: CallableFunction;
}

const WordListFilter = (props: Props): JSX.Element => {
    const {
        children,
        filterParam,
        modalPositionTop,
        modalStyle,
        reviewButton,
        userWords,
        wordType,
        setWordType,
    } = props;
    const { isMobile } = useViewport();
    const [showList, setShowlist] = useState<boolean>(false);
    const [togglePreferences, setTogglePreferences] = useState<boolean>(false);

    const style = {
        top: modalPositionTop,
    };

    return (
        <>
            {!isMobile ? (
                <div className={styles.filterContainer}>
                    <div
                        className={
                            modalStyle
                                ? styles.filterOptions + ' ' + styles.modalStyle
                                : styles.filterOptions
                        }
                    >
                        {children}
                    </div>
                    {reviewButton && (
                        <ButtonColor
                            color={'blue'}
                            height={'44px'}
                            onClick={() => setTogglePreferences(true)}
                        >
                            Review Now
                        </ButtonColor>
                    )}
                </div>
            ) : (
                <div className={styles.filterOptionsMobile}>
                    <div
                        className={styles.filterDropdown}
                        onClick={() => setShowlist(!showList)}
                    >
                        <p>{filterParam}</p>
                        <img src={selector} />
                    </div>

                    <WordListTypeToggle
                        english={false}
                        wordType={wordType}
                        setWordType={setWordType}
                    />
                </div>
            )}
            {showList && (
                <div style={style} className={styles.filterDropdownModal}>
                    {children}
                </div>
            )}
            {togglePreferences && (
                <FCPreferences
                    toggleClose={() => setTogglePreferences(false)}
                    userWordsFetched={true}
                    weeklywordsFetched={false}
                    fetchedUserWords={userWords}
                />
            )}
        </>
    );
};

export default WordListFilter;
