import React from 'react';
import ButtonColor from '../../UI-Components/ButtonColor';
import styles from './_styles/WarningModal.module.scss';
import alertTriangle from '../../../../public/Images/alert-triangle.svg';

interface Props {
    show: boolean;
    messageType: string;
    toggleClose: CallableFunction | null;
    toggleContinue?: CallableFunction;
    toggleContinueLink?: string;
    singleButton?: boolean;
}

const DeleteModal = (props: Props): JSX.Element => {
    const {
        show,
        messageType,
        toggleClose,
        toggleContinue,
        toggleContinueLink,
        singleButton = false,
    } = props;

    /*
    Message Types:
    warning
    delete
    missingfields
    uploadsuccess
    */

    const type = (messageType, style) => {
        if (style === 'header') {
            if (messageType === 'warning') {
                return 'Warning';
            } else if (messageType === 'delete') {
                return 'Deleting Content';
            } else if (messageType === 'missingfields') {
                return 'Missing Fields';
            } else if (messageType === 'uploadsuccess') {
                return 'Success!';
            } else if (messageType === 'deletewarning') {
                return 'Permanently Deleting';
            }
        }
        if (style === 'message') {
            if (messageType === 'warning') {
                return 'Any unsaved progress will be deleted. Would you like to continue?';
            } else if (messageType === 'delete') {
                return 'Are you sure you want to delete this word/phrase/lesson？';
            } else if (messageType === 'missingfields') {
                return 'Some fields are missing. Please check and make sure they are all complete to continue';
            } else if (messageType === 'uploadsuccess') {
                return 'Lesson successfuly uploaded! It can be found in the Edit Lesson page';
            } else if (messageType === 'deletewarning') {
                return 'There is no reversing deleting this content.';
            }
        }
    };

    return (
        <>
            {show && (
                <aside
                    className={styles.backdrop}
                    onClick={() => toggleClose()}
                >
                    <aside
                        className={styles.deleteModal}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className={styles.warningMessage}>
                            <div>
                                {messageType !== 'uploadsuccess' && (
                                    <img src={alertTriangle} />
                                )}
                                <h4>{type(messageType, 'header')}</h4>
                            </div>
                            <p>{type(messageType, 'message')}</p>
                        </div>
                        <div className={styles.modalButtons}>
                            {!singleButton && (
                                <ButtonColor
                                    width={'252px'}
                                    height={'36px'}
                                    color={'orange'}
                                    onClick={() => toggleClose()}
                                >
                                    Cancel
                                </ButtonColor>
                            )}
                            <ButtonColor
                                width={!singleButton ? '252px' : '100%'}
                                height={'36px'}
                                color={'orangeClear'}
                                link={toggleContinueLink}
                                onClick={() => {
                                    toggleContinue && toggleContinue();
                                    toggleClose();
                                }}
                            >
                                {messageType === 'delete' ||
                                messageType === 'deletewarning'
                                    ? ' Delete'
                                    : messageType === 'missingfields'
                                    ? 'Return'
                                    : 'Continue'}
                            </ButtonColor>
                        </div>
                    </aside>
                </aside>
            )}
        </>
    );
};

export default DeleteModal;
