import React from 'react';
import styles from './_styles/MobileHeader.module.scss';

interface Props {
    children: string | JSX.Element | JSX.Element[];
}

/**
 * Component for rendering a mobile header with top and bottom sections.
 * @param {Props} props - The component props.
 * @return {JSX.Element} - The rendered component.
 */
const MobileHeader = ({ children }: Props) => {
    return (
        // Render the mobile header with top and bottom sections
        <header className={styles.topBar}>
            <div className={styles.top}>{children}</div>
            <div className={styles.bottom}>
                {/* Placeholder div for potential additional content in the bottom section */}
                <div></div>
            </div>
        </header>
    );
};

export default MobileHeader;
