import React, { useState, useContext } from 'react';
import UserContext from '../../../context/UserContext';
import useSetUserWeek from '../../../customhooks/useSetUserWeek';
import ButtonColor from '../../UI-Components/ButtonColor';
import CloseButton from '../../../../public/Images/ClearButtonX.svg';
import LessonDropdown from './LessonDropdown';
import styles from './_styles/LessonFilterModal.module.scss';

interface Props {
    close: CallableFunction;
}

/**
 * @fileoverview
 * Modal for selecting the users current week. Uses the <LessonDropDown/> to select the current quarter and week
 *
 * @param {Props} props
 * close: Close the modal
 *
 * @return {JSX.Element} - lesson filter for dashboard
 */
const LessonFilterDash = (props: Props): JSX.Element => {
    const { close } = props;

    /* sets the user context to update the current selected week */
    const { setUserObject } = useContext(UserContext);

    const {
        newWeek,
        selectedWeek,
        setSelectedWeek,
        selectedQuarter,
        setSelectedQuarter,
        quarterArr,
        weekArr,
    } = useSetUserWeek({ prefMode: false });

    const [isQuarterOpen, setIsQuarterOpen] = useState<boolean>(false);
    const [isWeekOpen, setIsWeekOpen] = useState<boolean>(false);

    return (
        <aside className={styles.backdrop} onClick={() => close()}>
            <aside
                className={styles.filter}
                onClick={(e) => e.stopPropagation()}
            >
                <img
                    src={CloseButton}
                    className={styles.closeButton}
                    onClick={() => close()}
                />
                <div className={styles.selection}>
                    <h3>Select your week</h3>
                    <p>to get matching curriculum and study resources</p>
                </div>

                <LessonDropdown
                    optionArr={quarterArr}
                    currentOption={selectedQuarter}
                    updateOption={setSelectedQuarter}
                    isDropdownOpen={isQuarterOpen}
                    setIsDropdownOpen={() => {
                        setIsQuarterOpen(!isQuarterOpen);
                        setIsWeekOpen(false);
                    }}
                />
                <LessonDropdown
                    optionArr={weekArr}
                    currentOption={selectedWeek}
                    updateOption={setSelectedWeek}
                    isDropdownOpen={isWeekOpen}
                    setIsDropdownOpen={() => {
                        setIsWeekOpen(!isWeekOpen);
                        setIsQuarterOpen(false);
                    }}
                />

                {/* Save the current week options and close the modal */}
                <ButtonColor
                    onClick={() => {
                        setUserObject((prev) => ({
                            ...prev,
                            currentClassAndUnit: newWeek,
                        }));
                        close();
                    }}
                    width={'100%'}
                    height={'56px'}
                    color={'orange'}
                >
                    Save and Learn
                </ButtonColor>
            </aside>
        </aside>
    );
};

export default LessonFilterDash;
