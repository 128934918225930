import React from 'react';
import { Lesson } from '../../../types/api_response';
import useSearch from '../../../customhooks/useSearch';
import useGetLessons from '../../../customhooks/useGetLessons';
import useFetch from '../../../useFetch';
import AdminTableList from './AdminTableList';
import ButtonColor from '../../UI-Components/ButtonColor';
import LoadingSpinner from '../../UI-Components/LoadingSpinner';
import SearchBar from '../../UI-Components/SearchBar';
import styles from './_styles/LessonList.module.scss';

/**
 *
 * @fileoverview
 * LessonList component for rendering a list of lessons in the admin interface
 *
 * @return {JSX.Element} - Rendered lesson list page component
 */
const LessonList = (): JSX.Element => {
    // Fetching deleteLesson function from useFetch custom hook
    const { deleteLesson } = useFetch();

    // Fetching lessonsData and setLessonsData functions from useGetLessons custom hook
    const { lessonsData, setLessonsData } = useGetLessons({
        getAdminLessons: true,
    });

    // Fetching searchLessons, setSearchQuery, searchQueary, and lessonTotal functions from useSearch custom hook
    const { searchLessons, setSearchQuery, searchQueary, lessonTotal } =
        useSearch({
            lessons: lessonsData,
        });

    // Function to sort lessons by updatedAt date in descending order
    const sortDate = (lessons: Lesson[]) => {
        return lessons.sort((a, b) =>
            b.lessonData.updatedAt.localeCompare(a.lessonData.updatedAt),
        );
    };

    // Function to handle lesson deletion
    const handleDelete = (lessonId) => {
        // Calling deleteLesson API and updating lessonsData state
        deleteLesson(`/admin/lessons/${lessonId}`).then((data: any) => {
            if (data.status == 'success') {
                console.log('deleted successfully', data);
            } else {
                console.log(data);
            }
        });

        // Filtering out the deleted lesson from lessonsData state
        setLessonsData((prev) => {
            return prev.filter((lesson) => {
                return lesson.lessonData.uuid != lessonId;
            });
        });
    };

    return (
        <>
            {/* LoadingSpinner component for indicating loading state */}
            <LoadingSpinner />

            {/* Search bar and Create Lesson button */}
            <div className={styles.searchBar}>
                <SearchBar
                    value={searchQueary}
                    handleChange={(e) => setSearchQuery(e.target.value)}
                    placeholder={'Input Title or Tag'}
                    name={'lessonsearch'}
                />
                <ButtonColor
                    color={'orangeClear'}
                    link={'/admin/create-lesson'}
                >
                    Create Lesson
                </ButtonColor>
            </div>

            {/* AdminTableList component for rendering lessons table */}
            <AdminTableList
                isLessonTable={true}
                tableData={sortDate(searchLessons(lessonsData))}
                resultsLength={lessonTotal}
                handleDelete={handleDelete}
            />
        </>
    );
};

export default LessonList;
