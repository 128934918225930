import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UserWord, fcStudyData } from '../../../types/api_response';
import useAutoPlay from '../../../customhooks/useAutoPlay';
import useFetch from '../../../useFetch';
import useFunction from '../../../useFunction';
import useViewport from '../../../customhooks/useViewport';
import useGetWordExamples from '../../../customhooks/useGetWordExamples';
import ButtonColor from '../../UI-Components/ButtonColor';
import ExampleSentences from '../../GlobalComponents/ExampleSentences';
import FCremoveword from '../../../../public/Images/FCremoveword.svg';
import FCcheck from '../../../../public/Images/FCcheck.svg';
import FCWword from './FCWord';
import keyDownEvent from '../../../utils/KeyDownEvent';
import MobileTopBar from '../../GlobalComponents/MobileTopBar';
import LoadingSpinner from '../../UI-Components/LoadingSpinner';
import PlayButton from '../../../../public/Images/AudioIcons/btn-play.svg';
import preferencesIcon from '../../../../public/Images/AudioIcons/preferences.svg';
import ProgressBar from '../../UI-Components/ProgressBar';
import ResultsPage from '../../GlobalComponents/ResultsPage';
import WordOptionsModal from './WordOptionsModal';
import WordBank from './WordBankInterface';
import WordSettingsModal from '../../GlobalComponents/WordSettingsModal';
import styles from './_styles/FCStudy.module.scss';

type defaultSettings = {
    block: boolean;
    mastered: boolean;
    settings: boolean;
};

/**
 *
 * @fileOverview
 * This is the flashcard study component. It handles displaying the flashcard session and logic for updating the words that have been studied. When the session ends it will display the results page where the user can return to the dashboard or start another flascards session
 *
 * @dataOrigin
 * The word data comes from useLocation and recieves the data passed from FcPreferences
 *
 * @return {JSX.Element} - Rendered FC study page component
 */
const FCStudy = (): JSX.Element => {
    const defaultSettings: defaultSettings = {
        block: false,
        mastered: false,
        settings: false,
    };

    const [wordBank, setWordBank] = useState<WordBank>();
    const [wordIndex, setWordIndex] = useState<number>(0);
    const [checkWord, setCheckWord] = useState<boolean>(false);

    /* state object to control popup options like blocking, mastering, and word settings */
    const [settings, setSettings] = useState<defaultSettings>(defaultSettings);

    /* locations holds the data passed from the preferences page */
    const location = useLocation();
    const fcdata: fcStudyData = location.state;
    const { fcWords, fcWordType } = fcdata;
    const { putReq } = useFetch();
    const { viewportWidth, mediaQuery } = useViewport();
    const { calcPercentage, cleanTrans } = useFunction();
    const wordTotal: number = fcWords.length;

    /* Initialize the wordbank */
    useEffect(() => {
        const words = new WordBank(fcWords);
        setWordBank(words);
        setWordIndex(0);
        /* if a user starts a new session in results page it will reset the data and set wordIndex to 0 */
    }, [fcdata]);

    const wordList = (words) => {
        return words.map((word) => {
            return word.word;
        });
    };

    /* This hook gets all the example sentences for each word prepared */
    const exampleSentances = useGetWordExamples({
        phraseNum: 2,
        wordList: wordList([...fcWords]),
        reset: fcdata,
    });

    const isLoaded = !wordBank || exampleSentances.length === 0;

    /* Custom hook for handling autoplay */
    const { isFinished } = useAutoPlay({
        playAudio: isLoaded ? null : () => playAudio(),
        autoPlayVar: [wordIndex],
        lessonFinish: wordIndex === wordTotal,
        lessonFinishVar: [wordIndex],
    });
    console.log(isFinished);

    /* show loading screen till wordbank & example sentences have loaded */
    if (isLoaded) {
        return <LoadingSpinner />;
    }
    const currentWord: UserWord = wordBank.showCurrentWord(wordIndex);
    const currentSimp: string = currentWord.word.simplified;
    const currentTrans: string = currentWord.word.translation;
    const percentage = calcPercentage(wordIndex, wordTotal);
    const translationsArr = cleanTrans(currentTrans);
    const buttonHeight = '56px';

    /* Handle playing the audio */
    const playAudio = () => {
        /* Audio is fetched from storage and requires the simplifed words */
        const audio = new Audio(
            `https://storage.googleapis.com/production_word_audio_files/${currentSimp}.wav`,
        );
        if (audio.paused) {
            audio.play();
        } else {
            audio.pause();
            audio.currentTime = 0; // Reset audio playback position
        }
        // Since audio is an objects we do not need a cleanup function
        audio.onended = () => {
            audio.pause();
            audio.currentTime = 0; // Reset audio playback position
            audio.src = '';
        };
    };

    const submitWord = (levelUp: boolean) => {
        /* stops submit funtionality when it is the last word. this is to prevent overclicking because it will break the logic if the word index tries to display a word that is out of bounds from the word array*/
        if (wordIndex == wordTotal) {
            return;
        }

        /* true is for level up and false is for level down */
        if (levelUp) {
            wordBank.levelUp(currentWord, fcWordType);
        } else {
            wordBank.levelDown(currentWord, fcWordType);
        }
        /* increase word index */
        setWordIndex((prev) => prev + 1);
        /* reset displaying the translation & definition */
        setCheckWord(!checkWord);
        const wordUpdate = wordBank.fcWords[wordIndex];
        /* update each word individually */
        putReq(`/user/words`, {
            words: [
                {
                    simplified: wordUpdate.word.simplified,
                    srsLevel: wordUpdate.studyData.srsLevel,
                    levelFailed: wordUpdate.studyData.levelFailed,
                    isBlocked: wordUpdate.studyData.isBlocked,
                },
            ],
        });
    };

    /* Logic for removing words from blocking or mastering */
    const removeWord = (type: string) => {
        const isBlock: boolean = type === 'block';
        const isUserWords: boolean = fcWordType === 'userwords';
        const removeWord: UserWord = wordBank.fcWords[wordIndex];
        const removeWordLevel: number = removeWord.studyData.srsLevel;
        /* if you are blocking a word and the word to be removed is level 0 then we will set it to level 1 otherwise it cannot be removed. 
        if you are mastering the word we set it to level 7 */
        const wordUpdate = [
            {
                simplified: removeWord.word.simplified,
                srsLevel: isBlock
                    ? removeWordLevel < 1
                        ? 1
                        : removeWordLevel
                    : 7,
                levelFailed: false,
                isBlocked: isBlock ? true : removeWord.studyData.isBlocked,
            },
        ];

        const newWord = [
            {
                simplified: removeWord.word.simplified,
                srsLevel: 0,
                levelFailed: false,
                isBlocked: false,
            },
        ];

        /* update word that user already has */
        if (isUserWords) {
            putReq(`/user/words`, {
                words: wordUpdate,
            });
        }
        /* create a word in the user deck and update it */
        if (!isUserWords) {
            putReq(`/user/words`, {
                words: newWord,
            });
            putReq(`/user/words`, {
                words: wordUpdate,
            });
        }
        /* removeWord sets the word as correct for the results page */
        wordBank.removeWord(currentWord);
        setWordIndex((prev) => prev + 1);
        setCheckWord(false);
    };

    /* Custom hook to handle hotkey press */
    const { handleKeyPress } = keyDownEvent({
        upArrow: playAudio,
        leftArrow: () => {
            if (checkWord) {
                submitWord(false);
                setCheckWord(false);
            }
        },
        rightArrow: () => {
            if (!checkWord) {
                setCheckWord(true);
            } else {
                submitWord(true);
                setCheckWord(false);
            }
        },
    });

    /* Function for displaying translations */
    const translations = (check, transArr) => {
        return (
            <div className={styles.definition}>
                {check &&
                    transArr.map((translation, i) => {
                        if (i >= 3) {
                            return;
                        } else {
                            return (
                                <div key={i} className={styles.translation}>
                                    <div>{i + 1}.</div>
                                    <p>{translation}</p>
                                </div>
                            );
                        }
                    })}
            </div>
        );
    };

    return (
        <>
            {/* This input is to focus the on the page so the keydown event functionality
                can work immediatly as the page renders */}
            <input autoFocus className={styles.pageRef} />

            {/* After the word index === the wordtotal the session will be finished */}
            {wordIndex !== wordTotal ? (
                <div
                    className={
                        viewportWidth > mediaQuery.mobile
                            ? styles.pageGrid
                            : styles.fcGridMobile
                    }
                    onKeyDown={handleKeyPress}
                    tabIndex={0}
                >
                    {/* Mobile topbar only appears in mobile screen size */}
                    <MobileTopBar fcMode={true} fcSettings={setSettings} />
                    <main className={styles.flashcardContainer}>
                        <div className={styles.progressBar}>
                            <ProgressBar
                                height={8}
                                color={'#E98B2A'}
                                percentage={percentage}
                            />
                        </div>
                        <div className={styles.settings}>
                            {/* This is the counter for the study session */}
                            <p className={styles.wordCounter}>
                                {Math.min(wordIndex + 1, wordTotal)}/{wordTotal}
                            </p>

                            {/* opens ups settings */}
                            <img
                                src={preferencesIcon}
                                alt="settings Icon"
                                onClick={() =>
                                    setSettings((prev) => ({
                                        ...prev,
                                        settings: true,
                                    }))
                                }
                            />
                            {settings.settings && (
                                <WordSettingsModal
                                    mobileStyle={false}
                                    fcMode={true}
                                    right={0}
                                    top={41}
                                    toggleClose={() =>
                                        setSettings(defaultSettings)
                                    }
                                />
                            )}
                        </div>

                        <div className={styles.flashcardContent}>
                            {/* This is the current word with the audio button */}
                            {currentWord !== undefined && (
                                <div className={styles.wordContainer}>
                                    <img
                                        src={PlayButton}
                                        onClick={() => playAudio()}
                                    />
                                    <FCWword word={currentWord.word} />
                                </div>
                            )}

                            <div className={styles.wordBottom}>
                                {/* displays all the translations */}
                                {translations(checkWord, translationsArr)}

                                {/* Displays the example sentences */}
                                <div className={styles.exampleContainer}>
                                    <ExampleSentences
                                        fcMode={true}
                                        fcCheck={checkWord}
                                        phraseNum={2}
                                        exampleSentences={
                                            exampleSentances[wordIndex]
                                        }
                                    />
                                </div>
                                <div className={styles.buttons}>
                                    <div className={styles.playButtonMobile}>
                                        <img
                                            src={PlayButton}
                                            onClick={() => playAudio()}
                                        />
                                    </div>
                                    {/* If check is false the translastions or definition will not appear */}
                                    {!checkWord ? (
                                        <div className={styles.checkButton}>
                                            <ButtonColor
                                                onClick={() =>
                                                    setCheckWord(!checkWord)
                                                }
                                                color={'orange'}
                                                width={'100%'}
                                                fontsize={'20px'}
                                                height={buttonHeight}
                                            >
                                                Check
                                            </ButtonColor>
                                        </div>
                                    ) : (
                                        /* Select if you understand the word */
                                        <div className={styles.controlButtons}>
                                            <ButtonColor
                                                onClick={() =>
                                                    submitWord(false)
                                                }
                                                color={'orangeClear'}
                                                fontsize={'20px'}
                                                height={buttonHeight}
                                            >
                                                That&apos;s New
                                            </ButtonColor>

                                            <ButtonColor
                                                onClick={() => submitWord(true)}
                                                color={'orange'}
                                                fontsize={'20px'}
                                                height={buttonHeight}
                                            >
                                                Got It
                                            </ButtonColor>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.wordOptions}>
                            {/* Open Block word */}
                            <img
                                src={FCremoveword}
                                onClick={() =>
                                    setSettings((prev) => ({
                                        ...prev,
                                        block: true,
                                    }))
                                }
                            />
                            {/* Open Master word */}
                            <img
                                src={FCcheck}
                                onClick={() =>
                                    setSettings((prev) => ({
                                        ...prev,
                                        mastered: true,
                                    }))
                                }
                            />
                        </div>
                    </main>
                    {/* Block or master word modal */}
                    <WordOptionsModal
                        block={settings.block}
                        isOpen={settings.block || settings.mastered}
                        toggleClose={() => setSettings(defaultSettings)}
                        confirm={() => {
                            setSettings(defaultSettings);
                            removeWord(settings.block ? 'block' : 'master');
                        }}
                    />
                </div>
            ) : (
                /* The results page appears after the index === total words  */
                <>
                    <ResultsPage
                        lessonType={'flashcard'}
                        correctResults={wordBank.correctTotal()}
                        totalResults={wordBank.fcWords.length}
                        wordList={wordBank.fcWords}
                        wordResultsFC={wordBank.finishWordList()}
                    />
                </>
            )}
        </>
    );
};

export default FCStudy;
