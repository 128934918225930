import React, { useState, useEffect, useRef } from 'react';
import { Lesson, UserWord } from '../../../types/api_response';
import useFetch from '../../../useFetch';
import useViewport from '../../../customhooks/useViewport';
import ButtonColor from '../../UI-Components/ButtonColor';
import chevronLeft from '../../../../public/Images/chevron-left.svg';
import filterLessons from '../../../globalUtilities';
import FilterButton from '../../UI-Components/FilterButton';
import LessonCard from './LessonCard';
import LessonFilter from './LessonFilter';
import LessonCardContainer from './LessonCardContainer';
import MobileHeader from '../../GlobalComponents/MobileHeader';
import Navbar from '../../BaseLayout/Navbar';
import gridStyles from '../PageGrid.module.scss';
import styles from './_styles/LessonStore.module.scss';

import LoadingSpinner from '../../UI-Components/LoadingSpinner';

const LessonStore = (): JSX.Element => {
    const filterObj = {
        name: '', // Takes a single string
        tags: ['EM'], // Each space separated tag should be its own array entry; ie, "EM Q1 W1" => ['EM', 'Q1', 'W1']
        onlyStudied: false, // If true, filter all lessons that have lesson.studyData.timesListened > 0
        onlyNotStudied: false,
        onlyTested: false,
        onlyNotTested: false,
    };
    const scrollRef = useRef(null);
    const { viewportWidth, mediaQuery } = useViewport();
    const [filters, setFilters] = useState(filterObj);
    const [lessons, setLessons] = useState<Lesson[]>();
    const [showMore, setShowMore] = useState<number>(21);
    const [userWords, setUserWords] = useState<UserWord[]>([]);
    const [userLessons, setUserLessons] = useState<boolean>(false);
    const [filteredLessons, setFilteredLessons] = useState<Lesson[]>([]);
    const [toggleSidebar, setToggleSidebar] = useState<boolean>(
        viewportWidth >= mediaQuery.xlarge,
    );

    const { getReq, loading } = useFetch();

    // The bottom two variables are a little dumb; Ideally we would just for-loop in line rather than map across these.

    useEffect(() => {
        getReq('/lessons').then((res: any) => {
            // console.log(res.data, 'emlessons');
            const lessons: Lesson[] = res.data;
            setLessons(lessons);
            setFilteredLessons(lessons);
        });

        getReq('/user/words').then((data: any) => {
            // filtering out ghost words
            const userWords = data.data.filter((word) => {
                if (word.word.simplified !== '') {
                    return word;
                }
            });
            setUserWords(userWords);
        });
    }, []);

    useEffect(() => {
        if (filters.tags.length > 1 && filteredLessons.length > 0) {
            // console.log('failure in if; lessons is: ', lessons);
            setFilteredLessons(filterLessons(lessons, filters));
        } else if (lessons && filteredLessons.length == 0) {
            // console.log('failure in else if');
            setFilteredLessons(filterLessons(lessons, filters));
        }
    }, [filters]);

    // this is used to hide the side bar when going to a smaller screen size
    useEffect(() => {
        if (viewportWidth >= 1200) {
            /* console.log('greater'); */
            setToggleSidebar(true);
        }
        if (viewportWidth < 1200) {
            /* console.log('greater'); */
            setToggleSidebar(false);
        }
    }, [viewportWidth]);

    const handleScrollToTop = () => {
        scrollRef.current.scrollIntoView({
            behavior: 'smooth',
        });
    };

    const clearAllFilters = () => {
        setUserLessons(false);
        setFilteredLessons(lessons);
        handleScrollToTop();
        setShowMore(21);
        setFilters(({ ...prev }) => {
            prev.tags = ['EM'];
            return prev;
        });
    };

    const lessonTitle = () => {
        if (userLessons) {
            return 'My Lessons';
        }
        if (filters.tags[1] === 'Q1') {
            return 'Quarter 1';
        }
        if (filters.tags[1] === 'Q2') {
            return 'Quarter 2';
        }
        if (filters.tags[1] === 'Q3') {
            return 'Quarter 3';
        }
    };

    if (loading || !lessons) {
        return <LoadingSpinner />;
    }

    /* Temproaray Filtering lesson the users lessons */
    const myLessons = filterLessons(lessons, {
        userAll: true,
    });

    const sortStudied = (lesson: Lesson[]) => {
        return lesson.sort((a, b) => {
            const date1 = new Date(a.studyData.dateLastSeen);
            const date2 = new Date(b.studyData.dateLastSeen);
            return date2 - date1;
        });
    };

    const tempFilter = (quarter) => {
        const copy = JSON.parse(JSON.stringify(filters));
        copy.tags.push(quarter);
        return copy;
    };
    const lessonsQ1 = filterLessons(lessons, tempFilter('Q1'));
    const lessonsQ2 = filterLessons(lessons, tempFilter('Q2'));
    const lessonsQ3 = filterLessons(lessons, tempFilter('Q3'));

    return (
        <>
            <div className={gridStyles.pageGrid}>
                <MobileHeader>
                    {lessonTitle() !== undefined ? (
                        <div
                            className={styles.headerBreadCrumb}
                            onClick={() => clearAllFilters()}
                        >
                            <img src={chevronLeft} />

                            <h3>{lessonTitle()}</h3>
                        </div>
                    ) : (
                        <div className={styles.headerBreadCrumb}>
                            <h3>All Lessons</h3>
                        </div>
                    )}
                    <FilterButton
                        style={'mobile'}
                        onClick={() => setToggleSidebar(!toggleSidebar)}
                    />
                </MobileHeader>
                <Navbar navType={'gridNavMobile'} />
                <div className={styles.lessonPageBody}>
                    {/* Div with ref is for scrolling to the top on page reset. might be kinda hacky but works!!! */}
                    <div ref={scrollRef} />
                    {filters.tags.length <= 1 && !userLessons ? (
                        <main className={styles.lessonCarousels}>
                            {sortStudied(myLessons).length > 0 && (
                                <LessonCardContainer
                                    title={'My Lessons'}
                                    lessonArr={sortStudied(myLessons)}
                                    userWords={userWords}
                                    featured={false}
                                    scrollToTop={() => handleScrollToTop()}
                                    setFilter={() => {
                                        setUserLessons(true);
                                    }}
                                />
                            )}

                            <LessonCardContainer
                                title={'Q1'}
                                lessonArr={lessonsQ1}
                                userWords={userWords}
                                scrollToTop={() => handleScrollToTop()}
                                setFilter={() => {
                                    setFilters(({ ...prev }) => {
                                        prev.tags.push('Q1');
                                        return prev;
                                    });
                                }}
                                featured={false}
                            />

                            <LessonCardContainer
                                title={'Q2'}
                                lessonArr={lessonsQ2}
                                userWords={userWords}
                                scrollToTop={() => handleScrollToTop()}
                                setFilter={() => {
                                    setFilters(({ ...prev }) => {
                                        prev.tags.push('Q2');
                                        return prev;
                                    });
                                }}
                                featured={false}
                            />
                            <LessonCardContainer
                                title={'Q3'}
                                lessonArr={lessonsQ3}
                                userWords={userWords}
                                scrollToTop={() => handleScrollToTop()}
                                setFilter={() => {
                                    setFilters(({ ...prev }) => {
                                        prev.tags.push('Q3');
                                        return prev;
                                    });
                                }}
                                featured={false}
                            />

                            {/* ============================================= */}

                            {/* {quarters.map((quarter) => {
                                return (
                                    <LessonCardContainer
                                        key={quarter}
                                        title={quarter}
                                        lessonArr={lessonsArr}
                                        userWords={userWords}
                                        setFilter={() => {
                                            setFilters(({ ...prev }) => {
                                                prev.tags.push(quarter);
                                                return prev;
                                            });
                                        }}
                                    />
                                );
                            })} */}
                        </main>
                    ) : (
                        /* ========================================================== */
                        // This is temporary till we have better filtering abilities

                        // This is where the lessons are cards are actually rendered outside of the containers
                        <>
                            {userLessons ? (
                                <>
                                    <main className={styles.lessonWrap}>
                                        <div>
                                            <h2 className={styles.sectionTitle}>
                                                My Lessons
                                            </h2>
                                            {viewportWidth <=
                                                mediaQuery.xlarge &&
                                                viewportWidth >=
                                                    mediaQuery.mobile && (
                                                    <>
                                                        <FilterButton
                                                            style={'desktop'}
                                                            onClick={() =>
                                                                setToggleSidebar(
                                                                    !toggleSidebar,
                                                                )
                                                            }
                                                        />
                                                    </>
                                                )}
                                        </div>
                                        <body>
                                            {myLessons.length == 0 && (
                                                <p
                                                    className={
                                                        styles.noLessonMessage
                                                    }
                                                >
                                                    No Lessons Available with
                                                    the Selected Tags
                                                </p>
                                            )}
                                            {sortStudied(myLessons).map(
                                                (lesson, i) => {
                                                    if (i < showMore)
                                                        return (
                                                            <LessonCard
                                                                key={
                                                                    lesson
                                                                        .lessonData
                                                                        .uuid
                                                                }
                                                                lesson={lesson}
                                                                cardSize={true}
                                                                userWords={
                                                                    userWords
                                                                }
                                                            />
                                                        );
                                                },
                                            )}
                                        </body>
                                    </main>
                                </>
                            ) : (
                                /* ====================================================== */
                                /* Filtering by quarter */
                                <main className={styles.lessonWrap}>
                                    <div>
                                        <h2 className={styles.sectionTitle}>
                                            {lessonTitle()}
                                        </h2>
                                        {viewportWidth <= mediaQuery.xlarge &&
                                            viewportWidth >=
                                                mediaQuery.mobile && (
                                                <>
                                                    <FilterButton
                                                        style={'desktop'}
                                                        onClick={() =>
                                                            setToggleSidebar(
                                                                !toggleSidebar,
                                                            )
                                                        }
                                                    />
                                                </>
                                            )}
                                    </div>
                                    {filteredLessons.length == 0 && (
                                        <p className={styles.noLessonMessage}>
                                            No Lessons Available with the
                                            Selected Tags
                                        </p>
                                    )}
                                    <body>
                                        {filteredLessons &&
                                            filteredLessons.map((lesson, i) => {
                                                if (i < showMore)
                                                    return (
                                                        <LessonCard
                                                            key={
                                                                lesson
                                                                    .lessonData
                                                                    .uuid
                                                            }
                                                            lesson={lesson}
                                                            cardSize={true}
                                                            userWords={
                                                                userWords
                                                            }
                                                        />
                                                    );
                                            })}
                                    </body>
                                    {filteredLessons.length > showMore && (
                                        <footer className={styles.showMore}>
                                            <ButtonColor
                                                height={'56px'}
                                                width={'150px'}
                                                color={'orange'}
                                                onClick={() =>
                                                    setShowMore(showMore + 21)
                                                }
                                            >
                                                show more
                                            </ButtonColor>
                                        </footer>
                                    )}
                                </main>
                            )}
                        </>
                    )}
                </div>
                <div className={styles.filterContainer}>
                    {/* ************FilterSide bar*********** */}
                    {toggleSidebar &&
                        (viewportWidth > mediaQuery.mobile ? (
                            <LessonFilter
                                clearFilters={() => clearAllFilters()}
                                lessonTitle={lessonTitle()}
                                filters={filters}
                                setFilters={(filter) => setFilters(filter)}
                                userLessons={userLessons}
                                setUserLessons={(lesson) =>
                                    setUserLessons(lesson)
                                }
                                scrollToTop={() => handleScrollToTop()}
                            />
                        ) : (
                            <aside
                                className={styles.backdrop}
                                onClick={() => setToggleSidebar(false)}
                            >
                                <LessonFilter
                                    clearFilters={() => clearAllFilters()}
                                    lessonTitle={lessonTitle()}
                                    filters={filters}
                                    setFilters={(filter) => setFilters(filter)}
                                    userLessons={userLessons}
                                    setUserLessons={(lesson) =>
                                        setUserLessons(lesson)
                                    }
                                    scrollToTop={() => handleScrollToTop()}
                                />
                            </aside>
                        ))}
                    {/* ************FilterSide bar*********** */}
                </div>
            </div>
        </>
    );
};

export default LessonStore;
