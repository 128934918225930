import React from 'react';
import FormInput from './FormInput';
import plus from '../../../../public/Images/plus.svg';
import trash from '../../../../public/Images/trash.svg';
import styles from './_styles/AddTag.module.scss';

interface Props {
    tagsArr: string[];
    setTagsArr: CallableFunction;
    label?: boolean;
    width?: number;
    lessonTag?: boolean;
    maxTags?: number;
    placeHolder?: string;
}

/**
 * @fileoverview
 * Used for creating and editing tag structures
 *
 * @param {Props} props
 * - tagsArr: list of tags
 * - setTagsArr: Used for saving tags
 * - label: show the tags label
 * - width: size fo the tag input
 * - lessonTag: Used to control the max amount of characters if it is a lesson tag
 * - maxTags: sets the cap on tags that can be made
 * - placeholder: tag placeholder
 * @return {JSX.Element} - Rendered add tag component
 */
const AddTag = (props: Props): JSX.Element => {
    const {
        tagsArr,
        setTagsArr,
        label = true,
        lessonTag = true,
        width,
        maxTags = 4,
        placeHolder = 'Ex: Q1',
    } = props;

    // Function to add or remove items from the tags array
    const addItemToMyArray = (newItem: any, add: boolean) => {
        if (add) {
            // Add a new item to the tags array
            setTagsArr((prevArray) => [...prevArray, newItem]);
        }
        if (!add) {
            // Remove the last item from the tags array
            setTagsArr((prev) => {
                const updatedTags = [...prev];
                updatedTags.pop(); // Remove the last element from the array
                return updatedTags;
            });
        }
    };

    return (
        <div className={styles.tags}>
            {/* Displaying a label if specified */}
            {label && <p>Tags</p>}
            <div>
                {/* Mapping over tags array to render FormInput components */}
                {tagsArr.map((tag, i) => {
                    return (
                        <>
                            <FormInput
                                tags={true}
                                id={'tags'}
                                label={'tags'}
                                value={tag}
                                maxHeight={36}
                                maxWidth={width ? width : 64}
                                maxLength={lessonTag ? 2 : undefined}
                                placeHolder={placeHolder}
                                updateValue={(e) => {
                                    // Update the specific tag in the array
                                    setTagsArr((prev) => {
                                        const updatedTags = [...prev];
                                        updatedTags[i] = e.target.value;
                                        return updatedTags;
                                    });
                                }}
                            />
                        </>
                    );
                })}
                {/* Display plus icon to add a new tag if maximum not reached */}
                {tagsArr.length < maxTags && (
                    <img
                        src={plus}
                        alt="plus"
                        onClick={() => addItemToMyArray('', true)}
                    />
                )}
                {/* Display trash icon to remove the last tag if more than one tag exists */}
                {tagsArr.length > 1 && (
                    <img
                        src={trash}
                        alt="trash"
                        onClick={() => addItemToMyArray('', false)}
                    />
                )}
            </div>
        </div>
    );
};

export default AddTag;
