import React, { useState } from 'react';
import { Lesson, UserWord } from '../../../types/api_response';
import LessonModal from './LessonModal';
import LessonModalMobile from './LessonModalMobile';
import headphonesIcon from '../../../../public/Images/headphones.svg';
import LearnIcon from '../../../../public/Images/LearnIcon.svg';
import tagParser from '../../../TagParser';
import useFunction from '../../../useFunction';
import useViewport from '../../../customhooks/useViewport';
import styles from './_styles/LessonCardListItem.module.scss';

interface Props {
    lesson: Lesson;
    userWords: UserWord[];
}

const LessonCardListItem = (props: Props): JSX.Element => {
    const [showLessonModal, setShowLessonModal] = useState<boolean>(false);
    const { viewportWidth, mediaQuery } = useViewport();
    const { secondsToMin, calcPercentage } = useFunction();
    const { lesson, userWords } = props;
    const { getTagString } = tagParser();

    const indicator = (lesson: Lesson) => {
        if (lesson.studyData.timesListened == 0) {
            return styles.listen;
        } else if (lesson.studyData.timesDrilled == 0) {
            return styles.learn;
        } else if (lesson.studyData.bestTestScore == 0) {
            return styles.test;
        } else return styles.review;
    };

    const indicatorMessage = (lesson: Lesson) => {
        if (lesson.studyData.timesListened == 0) {
            return viewportWidth > mediaQuery.medium ? (
                'Listen'
            ) : (
                <>
                    <img src={headphonesIcon} />
                </>
            );
        } else if (lesson.studyData.timesDrilled == 0) {
            return viewportWidth > mediaQuery.medium ? (
                'Learn'
            ) : (
                <>
                    <img src={LearnIcon} />
                </>
            );
        } else if (lesson.studyData.bestTestScore == 0) {
            return viewportWidth >= mediaQuery.medium ? 'Test' : '!';
        } else return viewportWidth >= mediaQuery.medium ? 'Review' : 'R';
    };

    const duplicate = [
        ...new Set(lesson.lessonData.transcript.split(' ')),
    ].slice(0, -1);

    const matchingWords = (userWords: UserWord[], lessonWords: string[]) => {
        const matching: UserWord[] = [];
        if (userWords) {
            userWords.some((word) => {
                if (
                    lessonWords.includes(word.word.simplified) &&
                    word.studyData.srsLevel >= 3
                ) {
                    matching.push(word);
                }
            });
        }
        return matching;
    };

    const matchingPercent = calcPercentage(
        matchingWords(userWords, duplicate).length,
        duplicate.length,
    );

    const day = getTagString(lesson.lessonData.tags, 'D', 'full');

    return (
        <>
            <div
                className={styles.listItem}
                onClick={() => setShowLessonModal(true)}
            >
                <div className={styles.listItemInfo}>
                    <img src={lesson.lessonData.image} />
                    <div>
                        <h5>{lesson.lessonData.name}</h5>
                        <div className={styles.listItemInfoBottom}>
                            {day && <p className={styles.lessonTag}>{day}</p>}
                            <p>{secondsToMin(lesson.lessonData.length)}</p>
                        </div>
                    </div>
                </div>
                <div className={styles.indicator + ' ' + indicator(lesson)}>
                    <p>{indicatorMessage(lesson)}</p>
                </div>
            </div>
            {showLessonModal && viewportWidth > mediaQuery.mobile ? (
                <LessonModal
                    open={showLessonModal}
                    close={() => setShowLessonModal(false)}
                    lessonData={lesson.lessonData}
                    studyData={lesson.studyData}
                    wordPercent={matchingPercent}
                />
            ) : (
                <LessonModalMobile
                    open={showLessonModal}
                    close={() => setShowLessonModal(false)}
                    lessonData={lesson.lessonData}
                    studyData={lesson.studyData}
                    wordPercent={matchingPercent}
                />
            )}
        </>
    );
};

export default LessonCardListItem;
