// This component is a style wrapper that renders all children words in a predefined "phrase" style
import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../../../context/UserContext';
import useViewport from '../../../customhooks/useViewport';
import PlayBtn from '../../../../public/Images/AudioIcons/btn-play.svg';
import styles from './_styles/PhraseBlock.module.scss';

interface Props {
    dimmed?: boolean;
    children: JSX.Element[];
    playButton?: boolean;
    togglePlayAudio?: () => void;
}

/**
 * @fileoverview
 * A JSX container for holding all the wordcards that are in a phrase and providing styling
 *
 * @param {Props} props
 * - dimmed: If phrase hasned been listened to yet it will have dim styling
 * - playButton: boolean for displaying the playbutton
 * - togglePlayAudio: Audio playback logic
 *
 * @return {JSX.Element} - Rendered phrase block component
 */
const PhraseBlock = (props: Props): JSX.Element => {
    // dimmed is true
    const { dimmed, children, togglePlayAudio, playButton = false } = props;
    const [notdim, setNotdim] = useState(false);
    const { viewportWidth, mediaQuery } = useViewport();
    const { bothTypes, pinyin } = useContext(UserContext);

    /* This use effect, state and prop assure that the phrase block will stay undimmed after it has been listened too */
    useEffect(() => {
        if (!dimmed) {
            setNotdim(true);
        }
    }, [dimmed]);

    const dimmedStyle = dimmed && !notdim ? styles.notReadYet : '';

    const stylesPhrase = () => {
        return (
            playButton &&
            viewportWidth < mediaQuery.mobile &&
            styles.phraseMobile
        );
    };

    const playButtonStyle = () => {
        if (bothTypes) {
            return styles.bothTypes;
        } else if (pinyin) {
            return styles.pinyin;
        } else {
            return styles.hanzi;
        }
    };

    return (
        <>
            <div
                className={`${styles.phrase} ${stylesPhrase()} ${dimmedStyle}`}
            >
                {playButton && viewportWidth < mediaQuery.mobile && (
                    <img
                        src={PlayBtn}
                        onClick={togglePlayAudio}
                        className={` 
                        ${styles.mobilePlayBtn}
                        ${playButtonStyle()}`}
                    />
                )}
                {children}
            </div>
        </>
    );
};

export default PhraseBlock;
