/* eslint-disable valid-jsdoc */
/* eslint-disable require-jsdoc */
import { Lesson, LessonStudyData, Phrase } from '../../../types/api_response';

class LessonInterface {
    name: string;
    description: string;
    audioSrc: string;
    phrases: Phrase[];
    totalTime: number;
    studyData: LessonStudyData;
    lastWordTime: string;

    /**
     * @fileOverview
     * - Handles and stores all the logic and information about a lesson
     *
     * @param {Lesson} lesson - The lesson data.
     * @param {Map<string, number>} userWordsMap - A map of user words with their SRS levels.
     */
    constructor(lesson: Lesson, userWordsMap: Map<string, number>) {
        /* phraseSorted adds the user's srs level for each word to the words in the lessons. Necessary for word blurring */
        const phraseSorted = () => {
            lesson.phrases.forEach((phrase) => {
                phrase.words.forEach((phraseWord) => {
                    const wordSrsLevel = userWordsMap.get(phraseWord.word.uuid);
                    phraseWord.word.srsLevel = wordSrsLevel ? wordSrsLevel : 1;
                });
            });
            return lesson.phrases;
        };
        this.name = lesson.lessonData.name;
        this.description = lesson.lessonData.description;
        this.audioSrc = lesson.lessonData.audio;
        this.phrases = phraseSorted();
        this.totalTime = lesson.lessonData.length;
        this.studyData = lesson.studyData;
        this.lastWordTime = this.phrases.slice(-1)[0].words.slice(-2)[0].time;
    }

    /**
     * Checks if there is a next phrase in the lesson based on the provided phrase index.
     * @param phraseIndex - The index of the current phrase.
     * @return {boolean} - Returns true if there is a next phrase, false otherwise.
     */
    hasNextPhrase = (phraseIndex: number): boolean => {
        // Check if the provided phraseIndex is the index of the last phrase in the lesson.
        if (phraseIndex === this.phrases.length - 1) {
            // If the provided phraseIndex is the index of the last phrase, there is no next phrase.
            return false;
        } else {
            // If the provided phraseIndex is not the index of the last phrase, there is a next phrase.
            return true;
        }
    };

    /**
     * Checks if there is a next word in the current phrase based on the provided phrase and word indices.
     * @param phraseIndex - The index of the current phrase.
     * @param wordIndex - The index of the current word within the current phrase.
     * @returns {boolean} - Returns true if there is a next word, false otherwise.
     */
    hasNextWord = (phraseIndex: number, wordIndex: number): boolean => {
        // Check if the provided wordIndex is the index of the last word in the current phrase.
        if (wordIndex === this.phrases[phraseIndex].words.length - 1) {
            // If the provided wordIndex is the index of the last word, there is no next word in the current phrase.
            return false;
        } else {
            // If the provided wordIndex is not the index of the last word, there is a next word in the current phrase.
            return true;
        }
    };

    /**
     * Returns the start and end time of a phrase for playback.
     * @param phraseIndex - The index of the target phrase.
     * @returns {[number, number]} - An array containing the start time and end time of the phrase.
     */
    getPhrasePlayTime = (phraseIndex: number): [number, number] => {
        // Get the start time of the first word in the target phrase.
        const startTime: number = parseFloat(
            this.phrases[phraseIndex].words[0].time,
        );
        let endTime: number;
        // Check if there is no next phrase (current phrase is the last one).
        if (!this.hasNextPhrase(phraseIndex)) {
            // If there is no next phrase, set the end time to the second-to-last word's time in the current phrase.
            endTime = parseFloat(
                this.phrases[phraseIndex].words[
                    this.phrases[phraseIndex].words.length - 2
                ].time,
            );
            // Add a small duration (2 seconds) to allow the last word time to play through.
            endTime += 2;
        } else {
            // If there is a next phrase, set the end time to the start time of the first word in the next phrase.
            endTime = parseFloat(this.phrases[phraseIndex + 1].words[0].time);
        }
        // Return an array containing the start time and end time of the phrase.
        return [startTime, endTime];
    };

    /**
     * Returns the start and end time of a word for playback.
     * @param phraseIndex - The index of the phrase containing the target word.
     * @param wordIndex - The index of the target word within the phrase.
     * @returns {[number, number]} - An array containing the start time and end time of the word.
     */
    getWordPlayTime = (
        phraseIndex: number,
        wordIndex: number,
    ): [number, number] => {
        // Get the start time of the target word.
        const startTime: string =
            this.phrases[phraseIndex].words[wordIndex].time;

        // Initialize endTime variable.
        let endTime: string;

        // Check if there is no next word or if the next word is marked as markdown and there is no word after it.
        if (
            !this.hasNextWord(phraseIndex, wordIndex) ||
            (this.phrases[phraseIndex].words[wordIndex + 1].markdown &&
                !this.phrases[phraseIndex].words[wordIndex + 2])
        ) {
            // If there is no next word or the next word is marked as markdown with no following word,
            // set the end time to the target word's time plus a small duration (2 seconds).
            if (!this.hasNextPhrase(phraseIndex)) {
                endTime = (parseFloat(startTime) + 2).toString();
            } else {
                // If there is a next phrase, set the end time to the start time of the first word in the next phrase.
                endTime = this.phrases[phraseIndex + 1].words[0].time;
            }
        } else if (this.phrases[phraseIndex].words[wordIndex + 1].markdown) {
            // If the next word is marked as markdown, set the end time to the time of the word following the markdown.
            endTime = this.phrases[phraseIndex].words[wordIndex + 2].time;
        } else {
            // If none of the special cases apply, set the end time to the time of the next word.
            endTime = this.phrases[phraseIndex].words[wordIndex + 1].time;
        }

        // Return an array containing the start time and end time of the word, parsed as numbers.
        return [parseFloat(startTime), parseFloat(endTime)];
    };

    /**
     * Calculates the end time for audio playback of a phrase.
     * @param phraseIndex - The index of the phrase for which the end time is calculated.
     * @returns {number} - The calculated end time for audio playback of the phrase.
     */
    phraseEndAudio = (phraseIndex: number): number => {
        // Get the last two words in the target phrase.
        const lastTwoWords = this.phrases[phraseIndex].words.slice(-2);

        // Calculate the end time for audio playback.
        return lastTwoWords[0].time === ''
            ? parseFloat(lastTwoWords[1].time) + 1
            : parseFloat(lastTwoWords[0].time) + 1;
    };
}

export default LessonInterface;
