import React from 'react';
import { WordType } from '../../../types/api_response';
import styles from './_styles/WordListTypeToggle.module.scss';

interface Props {
    english?: boolean;
    wordType: WordType['wordType'];
    setWordType: CallableFunction;
}

const WordListTypeToggle = (props: Props): JSX.Element => {
    const { english = false, setWordType, wordType } = props;

    const wordtypeStyle = (type: WordType['wordType']) => {
        return wordType == type && styles.highlight;
    };

    return (
        <div className={styles.wordTypeSwitch}>
            <p
                onClick={() => setWordType('hanzi')}
                className={styles.hanzi + ' ' + wordtypeStyle('hanzi')}
            >
                汉字
            </p>
            {english && (
                <p
                    onClick={() => setWordType('english')}
                    className={styles.english + ' ' + wordtypeStyle('english')}
                >
                    English
                </p>
            )}
            <p
                onClick={() => setWordType('pinyin')}
                className={styles.pinyin + ' ' + wordtypeStyle('pinyin')}
            >
                Pinyin
            </p>
        </div>
    );
};

export default WordListTypeToggle;
