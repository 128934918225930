/* eslint-disable require-jsdoc */
import { UserWord } from '../../../types/api_response';

/**
 * potential problems:
 *
 * 1. Single origin (both userWords and originalWord data returning data at different places in logic)
 * 2. Shallow copy of word data --- any changes to "userWords" WILL potentially change "originalData"
 * 3. We do not need three copies of the UserWord data -- we can extend the UserWord[] interface, adding property like "result", "studied", etc
 *
 */

class WordBank {
    fcWords: UserWord[];

    constructor(fcWords) {
        this.fcWords = fcWords;
    }

    levelUp = (
        currentWord: UserWord,
        studyType: 'userwords' | 'weeklywords',
    ) => {
        const studyData = currentWord.studyData;

        // check if the word is due
        const isDue =
            new Date().getTime() >= new Date(studyData.dateDue).getTime();

        // if leveling up reset levelFailed
        studyData.levelFailed = false;

        // result is for results page styling
        studyData.result = true;

        // double check due date so that weekly words can be updated too
        if (studyType === 'userwords' && isDue) {
            return studyData.srsLevel++;
        }
        // Weekly words have to be added at level 0
        // the user will have to level them up past one in userwords to increase their level
        if (studyType !== 'userwords' && studyData.srsLevel > 0 && isDue) {
            return studyData.srsLevel++;

            // if it is a new word in weekly study back out
        } else {
            return;
        }
    };

    levelDown = (
        currentWord: UserWord,
        studyType: 'userwords' | 'weeklywords',
    ) => {
        const studyData = currentWord.studyData;

        // Check if the word is due
        const isDue =
            new Date().getTime() >= new Date(studyData.dateDue).getTime();

        // result is for results page styling
        studyData.result = false;

        // Weekly words have to be added at srsLevel 0
        // Doesn't check due date because we need to add word
        if (studyType !== 'userwords' && studyData.srsLevel === 0) {
            return;
        }
        if (isDue) {
            // Word is only level 0 from the start
            if (studyType === 'userwords' && studyData.srsLevel === 0) {
                return (studyData.srsLevel = 1);
            }
            // set level failed to false on the first mistake
            if (studyData.levelFailed === false) {
                console.log('level failed');
                return (studyData.levelFailed = true);
            }
            if (studyData.levelFailed === true && studyData.srsLevel === 1) {
                // Do nothing if srsLevel is already 1
                return;
            } else {
                // Decrease srsLevel by 1 and reset levelFailed to false
                studyData.srsLevel--;
                studyData.levelFailed = false;
            }
        }
    };

    // Remove word is used when mastering or blocking a word. It is for the results page styling only
    removeWord = (currentWord: UserWord) => {
        currentWord.studyData.result = true;
    };

    // This is for displaying the current word. If undefined we display a blank word to insure if its out of bounds it doesn't break
    showCurrentWord = (index: number): UserWord => {
        if (this.fcWords[index] === undefined) {
            return this.blankWord();
        } else {
            return this.fcWords[index];
        }
    };

    /* This is for calculating the amount of words missed */
    correctTotal = () => {
        return this.fcWords.filter((word) => {
            if (word.studyData.result === true) {
                return word;
            }
        }).length;
    };

    // This returns a word list for the results page to display
    finishWordList = () => {
        const wordResults: boolean[] = [];
        this.fcWords.forEach((word) => {
            if (word.studyData.result === true) {
                wordResults.push(true);
            } else {
                wordResults.push(false);
            }
        });
        return wordResults;
    };

    // blank word with UserWord data for preventing the app from crashing if the index goes out of bounds of the array
    blankWord = () => {
        const blank: UserWord = {
            word: {
                simplified: '',
                translation: '',
                frequency: 0,
                hskv2_level: 0,
                hskv3_level: 0,
                markdown: '',
                pinyin: '',
                newPinyin: '',
                srsLevel: 0,
                tags: '',
                time: '',
                traditional: '',
                uuid: '',
            },
            studyData: {
                dateDue: '',
                isBlocked: false,
                levelFailed: false,
                srsLevel: 0,
                result: false,
            },
        };
        return blank;
    };
}

export default WordBank;
