import React from 'react';
import styles from './_styles/Header.module.scss';
import BreadCrumbs from './BreadCrumbs';
import navListButton from '../../../public/Images/nav-list-button.svg';
import UserDropdownMenu from '../GlobalComponents/UserDropdownMenu';

interface Props {
    toggleNav: () => void;
}

/**
 * TopBar component for rendering the top header of the application.
 * @param {Props} props - The properties passed to the TopBar component.
 * @return {JSX.Element} - The rendered TopBar component.
 */
const TopBar = (props: Props): JSX.Element => {
    return (
        <>
            {/* TopBar header section */}
            <header className={styles.gridHeader}>
                <div className={styles.header}>
                    {/* Navigation list button */}
                    <img
                        className={styles.navlistButton}
                        src={navListButton}
                        onClick={props.toggleNav}
                    />

                    {/* Breadcrumbs component for displaying navigation path */}
                    <BreadCrumbs />

                    {/* UserMenu component for user-related actions */}
                    <UserDropdownMenu />
                </div>
            </header>
        </>
    );
};

export default TopBar;
