import React, { useState, useEffect } from 'react';
import styles from './_styles/ResultsPage.module.scss';
import useFunction from '../../useFunction';
import CircleBar from '../UI-Components/CircleBar';
import { LessonStudyData, UserWord } from '../../types/api_response';
import WordList from './WordList/WordList';
import PageNav from './PageNav';
import ButtonColor from '../UI-Components/ButtonColor';
import FCPreferences from '../Pages/FlashCards/FCPreferences';
import MobileTopBar from './MobileTopBar';
import useUpdateLessonData from '../../customhooks/useUpdateLessonData';

interface Props {
    /* used to calculate the score and percentage */
    totalResults: number;
    correctResults: number;

    /* Recieves FC words to display*/
    wordList?: UserWord[];
    wordResultsFC?: boolean[];

    /* Determines what mode will be updated and displayed */
    lessonType: 'learn' | 'test' | 'flashcard';

    /* necessary a request and update user score data */
    lessonName?: string;
    studyData?: LessonStudyData;
    lessonId?: string;

    /* Allows any kind of list to be added */
    children?: JSX.Element;
}

/**
 * @fileoverview - Display the users results at the end of any study mode
 * @param {Props} props
 * @return {JSX.Element} - Rendered Results page component
 */
const ResultsPage = (props: Props): JSX.Element => {
    const {
        wordList = [],
        wordResultsFC,
        correctResults,
        totalResults,
        lessonId,
        lessonName,
        lessonType,
        studyData,
    } = props;

    const { lessonUrlName } = useFunction();
    const [togglePref, setTogglePref] = useState<boolean>(false);
    const { calcPercentage } = useFunction();
    const LessonUrlName = lessonName ? lessonUrlName(lessonName) : '';

    const scorePercentage = calcPercentage(correctResults, totalResults);
    const { updateLesson, updatedStudyData } = useUpdateLessonData({
        studyData: studyData,
        lessonType: lessonType,
        lessonId: lessonId,
        testScore: scorePercentage,
    });

    useEffect(() => {
        updateLesson(lessonType);
    }, []);

    const resultsMessage = (score) => {
        // This is an edge case for learnmode. It is purposely set to go below 0
        if (score < 0) {
            return (
                <>
                    <h1>Great Job!</h1>
                    <h2>Learn again or continue to test mode</h2>
                </>
            );
        } else if (score >= 90) {
            return (
                <>
                    <h1>Great Job!</h1>
                    <h2>You really understood it!</h2>
                </>
            );
        } else if (score >= 70) {
            return (
                <>
                    <h1>Almost perfect!</h1>
                    <h2>You understood so much, be really proud!</h2>
                </>
            );
        } else if (score >= 50) {
            return (
                <>
                    <h1>Good job!</h1>
                    <h2>And there is still room for improvement!</h2>
                </>
            );
        } else {
            return (
                <>
                    <h1>That&apos;s okay!</h1>
                    <h2>Study more and you&apos;ll get it!</h2>
                </>
            );
        }
    };

    const buttonStyle = {
        height: '44px',
        width: '110px',
    };

    return (
        <>
            <div className={styles.pageGrid}>
                <MobileTopBar
                    fcMode={lessonType === 'flashcard'}
                    lessonName={lessonName}
                    studyData={studyData}
                    lessonId={lessonId}
                    color={'orange'}
                />
                <main className={styles.results}>
                    <section className={styles.resultsTop}>
                        <div className={styles.resultsContainer}>
                            <div className={styles.resultsMessage}>
                                {resultsMessage(scorePercentage)}
                            </div>

                            {lessonType != 'learn' && (
                                <div className={styles.resultsData}>
                                    <CircleBar
                                        color={'#13C2C2'}
                                        trackColor={'#D1D1D1'}
                                        percentage={scorePercentage}
                                        height={120}
                                        fontsize={32}
                                        stroke={10}
                                    />
                                    <div className={styles.resultScore}>
                                        <div>
                                            {lessonType == 'flashcard' && (
                                                <>
                                                    <h4>Reviewed Words</h4>
                                                    <h4>/</h4>
                                                    <h4>Total Words</h4>
                                                </>
                                            )}
                                            {lessonType == 'test' && (
                                                <>
                                                    <h4>Reviewed Sentences</h4>
                                                    <h4>/</h4>
                                                    <h4>Total Sentences</h4>
                                                </>
                                            )}
                                        </div>
                                        <div className={styles.scoreContainer}>
                                            <h3 className={styles.score}>
                                                {correctResults}
                                            </h3>
                                            <h3>/</h3>
                                            <h3 className={styles.score}>
                                                {totalResults}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {lessonType != 'flashcard' && (
                            <PageNav
                                studyData={updatedStudyData}
                                lessonName={LessonUrlName}
                                lessonId={lessonId ? lessonId : ''}
                                testResults={true}
                                margin={'1rem 0 1.5rem'}
                                mobileCentered={true}
                            />
                        )}
                        {lessonType == 'flashcard' && (
                            <div className={styles.flashcardNav}>
                                <ButtonColor
                                    color={'blueClear'}
                                    width={buttonStyle.width}
                                    height={buttonStyle.height}
                                    link={'/userwords'}
                                >
                                    Home
                                </ButtonColor>

                                <ButtonColor
                                    color={'blue'}
                                    width={buttonStyle.width}
                                    height={buttonStyle.height}
                                    onClick={() => setTogglePref(!togglePref)}
                                >
                                    Study Again
                                </ButtonColor>
                            </div>
                        )}
                        {togglePref && (
                            <FCPreferences
                                toggleClose={() => setTogglePref(false)}
                                userWordsFetched={false}
                                weeklywordsFetched={false}
                            />
                        )}
                    </section>
                    {wordList.length > 0 ? (
                        <>
                            {/* Word list is for flashcards */}
                            <WordList
                                userWords={wordList}
                                lessonResultsData={wordResultsFC}
                            />
                        </>
                    ) : (
                        <div className={styles.resultsBottom}>
                            {props.children}
                        </div>
                    )}
                </main>
            </div>
        </>
    );
};

export default ResultsPage;
