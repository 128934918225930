import { useEffect, useState } from 'react';
import { LessonStudyData } from '../types/api_response';
import useFetch from '../useFetch';

interface Props {
    lessonType?: 'listen' | 'learn' | 'test' | 'flashcard';
    studyData: LessonStudyData;
    lessonId?: string;
    timesListened?: number;
    testScore?: number;
}

const useUpdateLessonData = (props: Props) => {
    const {
        studyData,
        lessonType,
        lessonId,
        timesListened = 0,
        testScore,
    } = props;
    const { putReq } = useFetch();
    const [firstRender, setFirstRender] = useState<boolean>(false);
    const [updatedStudyData, setUpdatedStudyData] =
        useState<LessonStudyData>(studyData);

    useEffect(() => {
        if (firstRender && lessonType === 'listen') {
            if (timesListened <= 2) {
                putReq(`/user/words`, { lessonUUID: lessonId, words: [] });
            }
            putReq(`/user/lessons/${lessonId}`, updatedStudyData);
            console.log('listen updated');
        }
        setFirstRender(true);
    }, [updatedStudyData]);

    useEffect(() => {
        if (firstRender && lessonType === 'learn') {
            putReq(`/user/lessons/${lessonId}`, updatedStudyData);
            console.log('learn updated');
        }

        if (firstRender && lessonType === 'test') {
            putReq(`/user/lessons/${lessonId}`, updatedStudyData);
            console.log('test updated');
        }

        setFirstRender(true);
    }, [updatedStudyData]);

    const updateLesson = (lessonType) => {
        if (lessonType === 'listen') {
            if (updatedStudyData.timesListened === 0) {
                setUpdatedStudyData((prev) => ({
                    ...prev,
                    timesListened: prev.timesListened + 1,
                    timesDrilled: 0,
                    bestTestScore: 0,
                }));
            } else {
                setUpdatedStudyData((prev) => ({
                    ...prev,
                    timesListened: prev.timesListened + 1,
                }));
            }
        }
        if (lessonType === 'learn') {
            setUpdatedStudyData((prev) => ({
                ...prev,
                timesDrilled: prev.timesDrilled + 1,
            }));
            // console.log('learn updated', updatedStudyData);
        }
        if (lessonType === 'test' && testScore) {
            setUpdatedStudyData((prev) => ({
                ...prev,
                bestTestScore:
                    prev.bestTestScore < testScore / 100
                        ? (prev.bestTestScore = testScore / 100)
                        : prev.bestTestScore,
            }));
        }
    };

    return { updateLesson, updatedStudyData };
};

export default useUpdateLessonData;
