import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserWord, WordType } from '../../../types/api_response';
import AlertMessage from '../../GlobalComponents/AlertMessage';
import ButtonColor from '../../UI-Components/ButtonColor';
import SearchBar from '../../UI-Components/SearchBar';
import WordFilterOption from '../../GlobalComponents/WordList/WordFilterOption';
import WordListFilter from '../../GlobalComponents/WordList/WordListFilter';
import WordList from '../../GlobalComponents/WordList/WordList';
import styles from './_styles/WordStats.module.scss';

interface Props {
    userWords: UserWord[];
}

/**
 *
 * @fileoverview
 * This component displays the word list for user words page
 *
 *  @param {Props} props
 * - userWords: the users words for displaying in the list
 *
 * @return {JSX.Element} - Rendered words stats component
 */
const WordStats = (props: Props): JSX.Element => {
    const { userWords } = props;
    const history = useHistory();
    const [wordType, setWordType] = useState<WordType['wordType']>('hanzi');

    // State to manage the filter parameter
    const [filterParam, setFilterParam] = useState<string>('All');

    // State to manage the search query
    const [query, setQuery] = useState<string>('');

    // Array of search parameters
    const [searchParam] = useState<string[]>([
        'newPinyin',
        'translation',
        'traditional',
        'simplified',
    ]);

    // Filtering out words with an empty simplified field
    const userWordsArr: UserWord[] = userWords.filter(
        (word) => word.word.simplified !== '',
    );

    // Function to remove diacritics from a string
    const removeDiacritics = (str: string): string => {
        if (str == undefined || str == null) {
            return str;
        }
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };

    // Array of new pinyin values after removing diacritics
    const newPinyin: string[] = userWords.map((word) =>
        removeDiacritics(word.word.pinyin),
    );

    // Creating a new array of user words with additional newPinyin field
    const newUserWords = userWords.map((word, index) => ({
        ...word,
        word: { ...word.word, newPinyin: newPinyin[index] },
    }));

    // Function to filter words based on SRS level, blocked status, or both
    const wordsFilter = (
        blocked?: boolean,
        start?: number,
        end?: number,
    ): UserWord[] =>
        newUserWords.filter((word) => {
            const wordLevel = word.studyData.srsLevel;
            if (blocked && word.studyData.isBlocked) {
                return true;
            }
            if (
                start !== undefined &&
                end !== undefined &&
                wordLevel >= start &&
                wordLevel <= end
            ) {
                return true;
            }
            if (
                start !== undefined &&
                end === undefined &&
                wordLevel >= start
            ) {
                return true;
            }
            return false; // Default case: don't include the word
        });

    // Function to search and filter words based on filterParam and query
    const search = (): UserWord[] => {
        let wordArr: UserWord[] = [];
        if (filterParam === 'New') {
            wordArr = wordsFilter(false, 0, 2);
        }
        if (filterParam === 'Learn') {
            wordArr = wordsFilter(false, 3, 6);
        }
        if (filterParam === 'Master') {
            wordArr = wordsFilter(false, 7);
        }
        if (filterParam === 'Blocked') {
            wordArr = wordsFilter(true);
        }
        if (filterParam === 'All') {
            wordArr = newUserWords;
        }
        return wordArr.filter((word) =>
            searchParam.some((newWord) => {
                if (word.word[newWord] === undefined) {
                    return ''; // What do you want to do when it's undefined?
                }
                return (
                    word.word[newWord]
                        .toString()
                        .toLowerCase()
                        .indexOf(query.toLowerCase()) > -1
                );
            }),
        );
    };

    // Render nothing if userWords is null
    if (!newUserWords) {
        return <></>;
    }

    return (
        <>
            {/* Render if user has no words */}
            {userWordsArr === null ? (
                <div className={styles.noWordsMessage}>
                    <h4>You have no words</h4>
                    <p>Start studying lessons and flashcards to add words</p>
                    <ButtonColor color={'blue'} onClick={() => history.go(-1)}>
                        Go Back
                    </ButtonColor>
                </div>
            ) : (
                // Render word list section
                <section className={styles.wordList}>
                    <div className={styles.filterList}>
                        {/* Filter dipsplay*/}
                        <WordListFilter
                            filterParam={filterParam}
                            modalPositionTop={'160px'}
                            reviewButton={true}
                            userWords={userWords}
                            wordType={wordType}
                            setWordType={setWordType}
                        >
                            {/* filter options */}
                            <WordFilterOption
                                value="All"
                                topStyle={true}
                                filterParam={filterParam}
                                setParam={() => setFilterParam('All')}
                                quantity={userWordsArr.length}
                            />
                            <WordFilterOption
                                value="New"
                                filterParam={filterParam}
                                setParam={() => setFilterParam('New')}
                                quantity={
                                    undefined
                                        ? 0
                                        : wordsFilter(false, 0, 2).length
                                }
                            />
                            <WordFilterOption
                                value="Learn"
                                filterParam={filterParam}
                                setParam={() => setFilterParam('Learn')}
                                quantity={wordsFilter(false, 3, 6).length}
                            />
                            <WordFilterOption
                                value="Master"
                                filterParam={filterParam}
                                setParam={() => setFilterParam('Master')}
                                quantity={wordsFilter(false, 7).length}
                            />
                            <WordFilterOption
                                value="Blocked"
                                bottomStyle={true}
                                blockedWords={true}
                                filterParam={filterParam}
                                setParam={() => setFilterParam('Blocked')}
                                quantity={wordsFilter(true)?.length}
                            />
                        </WordListFilter>

                        {/* SearchBar component */}
                        <SearchBar
                            value={query}
                            handleChange={(e) => setQuery(e.target.value)}
                            placeholder={
                                'Input English or Characters to search word"'
                            }
                            padding={'0 0 1rem'}
                        />
                    </div>

                    {/* Render WordList or AlertMessage based on search results */}
                    {search(newUserWords).length > 0 ? (
                        <WordList userWords={search()} wordType={wordType} />
                    ) : (
                        <AlertMessage
                            header={'You have no words'}
                            message={
                                'Add words by doing lessons and increase your level studying flashcards!'
                            }
                        />
                    )}
                </section>
            )}
        </>
    );
};

export default WordStats;
