import React from 'react';
import styles from './_styles/FilterButton.module.scss';
import filterPlus from '../../../public/Images/plus.svg';

interface Props {
    onClick: () => void;
    style: 'mobile' | 'desktop';
}

/**
 * FilterButton component for rendering a button with a filter icon
 * @param {Props} props - Component props including onClick function and style
 * @return {JSX.Element} - Rendered FilterButton component
 */
const FilterButton = ({ style, ...props }: Props): JSX.Element => {
    return (
        <button
            className={`${styles.filterButton} ${styles[style]}`}
            {...props}
        >
            <img src={filterPlus} alt="Filter Icon" /> Filters
        </button>
    );
};

export default FilterButton;
