import React from 'react';
import styles from './_styles/TagList.module.scss';

interface Props {
    tags: string[];
}

/**
 * TagList component for rendering a list of tags
 * @param {Props} props - Component props including an array of tags
 * @return {JSX.Element} - Rendered TagList component
 */
const TagList = (props: Props): JSX.Element => {
    return (
        <div className={styles.tags}>
            {/* Mapping through the array of tags to render each tag */}
            {props.tags.map((tag, i) => {
                return <p key={i}>{tag}</p>;
            })}
        </div>
    );
};

export default TagList;
