import React, { useRef, useEffect } from 'react';
import styles from './_styles/VideoModal.module.scss';
import CloseButton from '../../../public/Images/close-video.svg'; // Close button icon
import Modal from '../GlobalComponents/Modal'; // Modal component for the video modal

interface Props {
    isOpen: boolean; // Flag indicating whether the modal is open
    toggleClose: CallableFunction; // Function to toggle the modal closed
    isTutorial?: boolean; // Flag indicating whether it's a tutorial video
    isYoutube?: boolean; // Flag indicating whether the video is from YouTube
    src: string; // Source URL of the video
}

/**
 * @fileoverview - Displays a video element as a modal. Used for the tutorial videos
 * @param {Props} props
 * @return {JSX.Element} - Rendered Video modal component
 */
const VideoModal = (props: Props) => {
    const {
        isOpen,
        toggleClose,
        src,
        isYoutube = false,
        isTutorial = false,
    } = props;

    const videoRef = useRef<HTMLVideoElement>(null); // Reference to the video element

    // Cleanup function when the component unmounts
    useEffect(() => {
        return () => {
            videoRef.current?.pause(); // Pause the video
            videoRef.current?.removeAttribute('src'); // Remove the video source attribute
            videoRef.current?.load(); // Load the video again
        };
    }, []);

    return (
        <Modal
            toggleClose={toggleClose}
            isOpen={isOpen}
            className={
                isTutorial ? styles.videoModalTutorial : styles.videoModal
            }
        >
            {/* Close button for the video modal */}
            <img
                src={CloseButton}
                className={styles.closeButton}
                onClick={() => toggleClose()}
            />

            {/* Video content - YouTube iframe or HTML5 video element */}
            {isYoutube ? (
                <iframe
                    className={styles.video}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    title="YouTube video player"
                    allowFullScreen
                    src={`https://www.youtube.com/embed/${src}`}
                ></iframe>
            ) : (
                <video
                    className={isTutorial ? styles.videoTutorial : styles.video}
                    ref={videoRef}
                    src={src}
                    title="YouTube video player"
                    controls // Show video controls (play, pause, etc.)
                ></video>
            )}
        </Modal>
    );
};

export default VideoModal;
