import React, { useState } from 'react';
import styles from './_styles/LessonCard.module.scss';
import ProgressBar from '../../UI-Components/ProgressBar';
import useFunction from '../../../useFunction';
import LessonModal from './LessonModal';
import LessonModalMobile from './LessonModalMobile';
import { Lesson, UserWord } from '../../../types/api_response';
import useViewport from '../../../customhooks/useViewport';

interface Props {
    lesson: Lesson;
    cardSize: boolean;
    userWords: UserWord[];
    minWidth?: number;
    featured?: boolean;
}

const LessonCard = (props: Props): JSX.Element => {
    const { lesson, cardSize, userWords, featured, minWidth } = props;
    const { lessonData, studyData } = lesson;
    const { name, description, length, tags, image } = lessonData;
    const { calcPercentage, secondsToMin, cleanTrans, cleanTag } =
        useFunction();
    const [show, setShow] = useState(false);
    const { viewportWidth, mediaQuery } = useViewport();

    const lessonTag = cleanTag(cleanTrans(tags));

    const duplicate = [...new Set(lessonData.transcript.split(' '))].slice(
        0,
        -1,
    );

    const matchingWords = (userWords: UserWord[], lessonWords: string[]) => {
        const matching: UserWord[] = [];
        if (userWords) {
            userWords.some((word) => {
                if (
                    lessonWords.includes(word.word.simplified) &&
                    word.studyData.srsLevel >= 3
                ) {
                    matching.push(word);
                }
            });
        }
        return matching;
    };

    const matchingPercent = calcPercentage(
        matchingWords(userWords, duplicate).length,
        duplicate.length,
    );

    const descriptionShort = (description) => {
        if (description.split('').length >= 50) {
            return description.slice(0, 50) + '...';
        } else {
            return description;
        }
    };

    const testScore = parseFloat((studyData.bestTestScore * 100).toFixed(2));
    const featuredStyles = { minWidth: minWidth, maxWidth: minWidth };

    return (
        <>
            {!featured && viewportWidth > mediaQuery.mobile ? (
                <div className={styles.card} onClick={() => setShow(!show)}>
                    <div>
                        <div
                            className={cardSize ? styles.top : styles.topLarge}
                        >
                            <img src={image} />
                            {studyData.bestTestScore !== 0 && (
                                <aside>
                                    tested:
                                    {testScore}%
                                </aside>
                            )}
                            {/* <div>part 1/5</div> */}
                        </div>
                        <div className={styles.cardDescription}>
                            <h3>{name}</h3>
                            <p className={styles.lessonTime}>
                                {secondsToMin(length)}
                            </p>
                            <p className={styles.description}>
                                {descriptionShort(description)}
                            </p>
                        </div>
                    </div>
                    <div className={styles.bottom}>
                        <p className={styles.tag}>{lessonTag}</p>
                        <div className={styles.progressBar}>
                            <ProgressBar
                                color={'#13C2C2'}
                                percentage={matchingPercent}
                                height={8}
                            />
                            <p className={styles.matchingPercent}>
                                {matchingPercent}% matching
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                /* This is the card for featured lessons */
                <div
                    className={styles.featuredCard}
                    style={
                        viewportWidth > mediaQuery.mobile
                            ? featuredStyles
                            : { minWidth: '0px' }
                    }
                    onClick={() => setShow(!show)}
                >
                    <div className={styles.featuredLeft}>
                        <img src={lesson.lessonData.image} />
                        {studyData.bestTestScore !== 0 && (
                            <aside>tested:{testScore}%</aside>
                        )}
                    </div>

                    <div className={styles.featuredRight}>
                        <div>
                            <h3 className={styles.featuredTitle}>
                                {lesson.lessonData.name}
                            </h3>
                            <p className={styles.featuredLength}>
                                {secondsToMin(length)}
                            </p>
                            <p className={styles.featuredDescription}>
                                {lesson.lessonData.description}
                            </p>
                        </div>
                        <div className={styles.rightBottom}>
                            <p className={styles.tag}>{lessonTag}</p>
                            <div className={styles.progressBar}>
                                <ProgressBar
                                    color={'#13C2C2'}
                                    percentage={matchingPercent}
                                    height={8}
                                />
                                <p className={styles.matchingPercent}>
                                    {matchingPercent}% matching
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {viewportWidth > mediaQuery.mobile ? (
                <LessonModal
                    open={show}
                    close={() => setShow(!show)}
                    lessonData={lessonData}
                    studyData={studyData}
                    wordPercent={matchingPercent}
                />
            ) : (
                <LessonModalMobile
                    open={show}
                    close={() => setShow(!show)}
                    lessonData={lessonData}
                    studyData={studyData}
                    wordPercent={matchingPercent}
                />
            )}
        </>
    );
};

export default LessonCard;
