import React, { useState, useEffect } from 'react';
import { Worksheet } from '../../../types/components';
import AddTag from './AddTag';
import ButtonColor from '../../UI-Components/ButtonColor';
import FilesUploader from './FilesUploader';
import FormInput from './FormInput';
import WorksheetResource from '../Resources/WorksheetResource';
import styles from './_styles/CreateResourceForm.module.scss';

/**
 *
 * @fileoverview
 * Component for creating worksheet resources
 *
 * @return {JSX.Element} - Rendered create worksheet form
 *
 */
const CreateWorksheet = () => {
    // Default worksheet object structure
    const DefaultObj: Worksheet = {
        title: '',
        type: '',
        pageQuantity: '',
        worksheetURI: '',
        tags: '',
        imageFile: null,
    };

    // State variables
    const [worksheetObj, setWorksheetObj] = useState(DefaultObj);
    const [tagsArr, setTagsArr] = useState<string[]>(['EM', '']);
    const [coverImage, setCoverImage] = useState<File | null>(null);

    // Update worksheet object when tagsArr or coverImage change
    useEffect(() => {
        setWorksheetObj((prev) => ({
            ...prev,
            tags: tagsArr.join(' '), // Join tags array into a space-separated string
        }));
        setWorksheetObj((prev) => ({
            ...prev,
            imageFile: coverImage, // Set imageFile to the selected cover image
        }));
    }, [tagsArr, coverImage]);

    return (
        <div className={styles.resourcesPage}>
            <form className={styles.resourceForm}>
                {/* Input fields for worksheet details */}
                <FormInput
                    id={'Title'}
                    label={'Title'}
                    value={worksheetObj.title}
                    maxHeight={58}
                    placeHolder="Enter a title of the Worksheet"
                    updateValue={(e) => {
                        setWorksheetObj((prev) => ({
                            ...prev,
                            title: e.target.value,
                        }));
                    }}
                />
                {/* File uploader and tag input */}
                <FormInput
                    id={'Number of pages'}
                    label={'Number of pages'}
                    value={worksheetObj.pageQuantity}
                    maxHeight={36}
                    maxWidth={54}
                    maxLength={2}
                    type={'number'}
                    updateValue={(e) => {
                        setWorksheetObj((prev) => ({
                            ...prev,
                            pageQuantity: e.target.value,
                        }));
                    }}
                />
                <FormInput
                    id={'Link'}
                    label={'Link'}
                    value={worksheetObj.worksheetURI}
                    maxHeight={58}
                    placeHolder="Canvas link to the page of this worksheet"
                    updateValue={(e) => {
                        setWorksheetObj((prev) => ({
                            ...prev,
                            worksheetURI: e.target.value,
                        }));
                    }}
                />
                <div className={styles.bottomForm}>
                    <FilesUploader
                        imageFile={coverImage}
                        handleImageFile={(e) => setCoverImage(e)}
                    />
                    <AddTag tagsArr={tagsArr} setTagsArr={setTagsArr} />
                </div>
            </form>
            {/* Displaying worksheet preview */}
            <div className={styles.resourcePreview}>
                <p>Preview</p>
                <WorksheetResource workSheetObj={worksheetObj} />
            </div>
            {/* Buttons for canceling and submitting the form */}
            <div className={styles.buttons}>
                <ButtonColor
                    width={'49%'}
                    height={'56px'}
                    color={'orangeClear'}
                    fontsize={'20px'}
                    disable={status === 'loading'}
                    link={'/admin/resources-list'}
                >
                    Cancel
                </ButtonColor>
                <ButtonColor
                    width={'49%'}
                    height={'56px'}
                    color={'orange'}
                    fontsize={'20px'}
                    type={'submit'}
                    disable={status === 'loading'}
                    // onClick={(e) => handleSubmit(e)}
                >
                    {status === 'loading' ? 'Submitting' : 'Submit'}
                </ButtonColor>
            </div>
        </div>
    );
};

export default CreateWorksheet;
