import React from 'react';
import fcmaster from '../../../public/Images/fc-master.svg';
import fcblock from '../../../public/Images/fc-block.svg';
import styles from './_styles/MasterButton.module.scss';

interface Props {
    isBlock: boolean;
    toggleOpen: CallableFunction;
}

/**
 * MasterButton component for rendering a button with an icon based on the isBlock prop
 * @param {Props} props - Component props including isBlock and toggleOpen function
 * @return {JSX.Element} - Rendered MasterButton component
 */
const MasterButton = (props: Props): JSX.Element => {
    const { isBlock, toggleOpen } = props;

    return (
        <button className={styles.button} onClick={() => toggleOpen()}>
            {/* Render either fcblock or fcmaster icon based on the isBlock prop */}
            <img
                src={isBlock ? fcblock : fcmaster}
                alt={isBlock ? 'block' : 'master'}
            />
            {/* Render 'Block' or 'Master' text based on the isBlock prop */}
            <p>{isBlock ? 'Block' : 'Master'}</p>
        </button>
    );
};

export default MasterButton;
