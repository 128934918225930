import { useState, useEffect, useContext } from 'react';
import UserContext from '../context/UserContext';
import AudioInterface from '../components/Pages/Lesson/AudioInterface';

interface Props {
    playDependency?: boolean;
    playAudio?: CallableFunction | null;
    autoPlayVar?: any[];
    audioPlayer?: AudioInterface;
    lessonFinish?: boolean;
    lessonFinishVar?: any[];
}

const useAutoPlay = (props: Props) => {
    const [firstPlay, setFirstPlay] = useState<boolean>(false);
    const [isFinished, setIsFinished] = useState<boolean>(false);
    const { autoPlay } = useContext(UserContext);

    const {
        playDependency = true,
        playAudio,
        autoPlayVar = [],
        audioPlayer,
        lessonFinish = false,
        lessonFinishVar = [],
    } = props;

    useEffect(() => {
        if (autoPlay && firstPlay && playDependency) {
            playAudio && playAudio();
        }
        setFirstPlay(true);

        return () => {
            if (audioPlayer) {
                audioPlayer.stop();
            }
        };
    }, autoPlayVar);

    useEffect(() => {
        if (lessonFinish) {
            const timeoutId = setTimeout(() => {
                setIsFinished(true);
            }, 250);

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, lessonFinishVar);

    return { isFinished };
};

export default useAutoPlay;
