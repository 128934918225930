import React from 'react';
import checkBox from '../../../public/Images/check-box.svg';
import checkedBox from '../../../public/Images/checked-box.svg';

interface Props {
    isChecked: boolean;
}

/**
 * CheckBox component for rendering an unchecked or checked checkbox
 * @param {Props} props - Component props including whether the checkbox is checked
 * @return {JSX.Element} - Rendered CheckBox component
 */
const CheckBox = (props: Props): JSX.Element => {
    return (
        <>
            {!props.isChecked ? (
                <>
                    <img src={checkBox} alt="Unchecked checkbox" />
                </>
            ) : (
                <>
                    <img src={checkedBox} alt="Checked checkbox" />
                </>
            )}
        </>
    );
};

export default CheckBox;
