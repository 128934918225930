import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import './App.module.scss';
import { LoadingProvider } from '../../customhooks/useLoading';
import { LogoutButton } from '../Auth/logout-button';
import { UserProvider } from '../../context/UserContext';
import Admin from '../Pages/Admin/Admin';
import BaseLayout from '../BaseLayout/BaseLayout';
import Dashboard from '../Pages/Dashboard/Dashboard';
import FCStudy from '../Pages/FlashCards/FCStudy';
import LessonPage from '../Pages/Lesson/LessonPage';
import LessonStore from '../Pages/LessonStore/LessonStore';
import Resources from '../Pages/Resources/Resources';
import UserLogin from '../Pages/UserLogin';
import UserWordsPage from '../Pages/UserWords/UserWordsPage';
import DEV from '../Pages/DEV';

const App = (): JSX.Element => {
    // FOR EVAN: Create a keep-alive / empty token-check route for users to ping on app creation (ie, here).
    //      If response is "unauthorized" then push /login directly to history, bypassing the dashboard page.
    //      Otherwise, the app will route like normal.

    return (
        <>
            <Router>
                <Switch>
                    <LoadingProvider>
                        <Route path="/login">
                            <UserLogin />
                        </Route>
                        <Route path="/logout">
                            <LogoutButton />
                        </Route>
                        <UserProvider>
                            <BaseLayout>
                                <Route
                                    exact
                                    path="/"
                                    render={() => <Redirect to="/dashboard" />}
                                />
                                <Route path="/dashboard">
                                    <Dashboard />
                                </Route>
                                <Route path="/userwords">
                                    <UserWordsPage />
                                </Route>
                                <Route path="/lesson/:lessonId">
                                    <LessonPage />
                                </Route>
                                <Route path="/lessons">
                                    <LessonStore />
                                </Route>
                                <Route path="/Study">
                                    <FCStudy />
                                </Route>
                                <Route path="/admin">
                                    <Admin />
                                </Route>
                                <Route path="/resources">
                                    <Resources />
                                </Route>
                                <Route path="/dev">
                                    <DEV />
                                </Route>
                                {/* <Route path="/404">
                                <PageNotFound />
                            </Route>
                            <Route path="*">{<Redirect to="/404" />}</Route> */}
                            </BaseLayout>
                        </UserProvider>
                    </LoadingProvider>
                </Switch>
            </Router>
        </>
    );
};

export default App;
