import React, { useContext, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import UserContext from '../../context/UserContext';
import useViewport from '../../customhooks/useViewport';
import useOutsideClick from '../../customhooks/onOutsideClick';
import HomeIcon from '../../../public/Images/NavIcons/nav-home.svg';
import SettingsIcon from '../../../public/Images/NavIcons/nav-settings.svg';
import LessonsIcon from '../../../public/Images/NavIcons/nav-book-open.svg';
import FlashcardsIcon from '../../../public/Images/NavIcons/nav-flashcard.svg';
import Logo from '../../../public/Images/em-logo.svg';
import styles from './_styles/Navbar.module.scss';
import ResouresIcon from '../../../public/Images/NavIcons/nav-resources.svg';

interface Props {
    navType: string;
    isNavOpen: boolean;
    setNavOpen: CallableFunction;
}

/**
 * Navbar component for rendering the navigation menu.
 * @param {Props} props - The properties passed to the Navbar component.
 * @return {JSX.Element} - The rendered Navbar component.
 */
const Navbar = (props: Props): JSX.Element => {
    const { userData } = useContext(UserContext);
    const { viewportWidth, mediaQuery } = useViewport();
    const { navType, isNavOpen, setNavOpen } = props;
    const navRef = useRef(null);

    const viewWidth =
        viewportWidth > mediaQuery.large || viewportWidth <= mediaQuery.mobile;

    // Close the navigation menu when clicking outside of it
    useOutsideClick(navRef, () => {
        if (isNavOpen) setNavOpen(false);
    });

    return (
        <>
            {(viewWidth || isNavOpen) && (
                // Render navigation menu
                <nav className={`${styles[navType]}`} ref={navRef}>
                    <div className={styles.logo}>
                        {/* Logo */}
                        <img src={Logo} />
                    </div>

                    <div className={styles.navLinks}>
                        {/* Dashboard Link */}
                        <NavLink
                            exact
                            to="/dashboard"
                            alt="dashboard-icon"
                            className={(isActive) =>
                                isActive ? styles.active : styles.notActive
                            }
                        >
                            <img src={HomeIcon} className={styles.icon} />
                            <span>Dashboard</span>
                        </NavLink>

                        {/* Lessons Link */}
                        <NavLink
                            to="/lessons"
                            alt="lessons-icon"
                            className={(isActive) =>
                                isActive ? styles.active : styles.notActive
                            }
                        >
                            <img src={LessonsIcon} className={styles.icon} />
                            <span>Lessons</span>
                        </NavLink>

                        {/* Flashcards Link */}
                        <NavLink
                            to="/userwords"
                            className={(isActive) =>
                                isActive ? styles.active : styles.notActive
                            }
                        >
                            <img
                                src={FlashcardsIcon}
                                alt="flashcard-icon"
                                className={styles.icon}
                            />
                            <span>Flashcards</span>
                        </NavLink>

                        {/* Resources Link */}
                        {/* Uncomment and customize as needed */}
                        <NavLink
                            to="/resources"
                            className={(isActive) =>
                                isActive ? styles.active : styles.notActive
                            }
                        >
                            <img
                                src={ResouresIcon}
                                className={styles.icon}
                                alt="resources-icon"
                            />
                            <span>Resources</span>
                        </NavLink>

                        {/* Admin Link (conditionally rendered for specific user) */}
                        {/* Uncomment and customize as needed */}
                        {/* <NavLink
                            to="/admin"
                            alt="admin-icon"
                            className={(isActive) =>
                                isActive ? styles.active : styles.notActive
                            }
                        >
                            <img src={SettingsIcon} className={styles.icon} />
                        </NavLink> */}
                        {userData && userData.username === 'evan' && (
                            <NavLink
                                to="/admin"
                                alt="admin-icon"
                                className={(isActive) =>
                                    isActive ? styles.active : styles.notActive
                                }
                            >
                                <img
                                    src={SettingsIcon}
                                    className={styles.icon}
                                />
                            </NavLink>
                        )}
                    </div>
                </nav>
            )}
        </>
    );
};

export default Navbar;
