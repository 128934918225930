import React, { useState } from 'react';
import { Route, Link } from 'react-router-dom';
import CreateLesson from './CreateLesson';
import EditWords from './EditWords';
import LessonList from './LessonList';
import EditLessonTool from './EditLessonTool';
import EditResources from './EditResources';
import gridStyles from '../PageGrid.module.scss';
import styles from './_styles/Admin.module.scss';
import ResourceList from './ResourceList';
import CreateMultimedia from './CreateMultimedia';
import CreateWorksheet from './CreateWorksheet';

const Admin = (): JSX.Element => {
    const [option, setOption] = useState(window.location.href);

    const activeFilter = (param) => {
        return option.includes(param)
            ? styles.activeOption
            : styles.unactiveOption;
    };

    return (
        <>
            <main className={gridStyles.pageGrid}>
                <div className={styles.adminPage}>
                    <h2 className={styles.header}>Manage Contents</h2>
                    <div className={styles.selector}>
                        <Link
                            to="/admin/edit-lesson"
                            className={activeFilter('/admin/edit-lesson')}
                            onClick={() => setOption('/admin/edit-lesson')}
                        >
                            Lessons
                        </Link>
                        <Link
                            to="/admin/resources-list"
                            className={activeFilter('/admin/resources-list')}
                            onClick={() => setOption('/admin/resources-list')}
                        >
                            Grammar Points
                        </Link>

                        <Link
                            to="/admin/edit-multimedia"
                            className={activeFilter('/admin/edit-multimedia')}
                            onClick={() => setOption('/admin/edit-multimedia')}
                        >
                            Multimedia
                        </Link>
                        <Link
                            to="/admin/work-sheets"
                            className={activeFilter('/admin/work-sheets')}
                            onClick={() => setOption('/admin/work-sheets')}
                        >
                            Work Sheets
                        </Link>
                        <Link
                            to="/admin/edit-words"
                            className={activeFilter('/admin/edit-words')}
                            onClick={() => setOption('/admin/edit-words')}
                        >
                            Edit Words
                        </Link>
                    </div>

                    <div>
                        <Route path="/admin/create-lesson">
                            <CreateLesson />
                        </Route>
                        <Route path="/admin/edit-lesson">
                            <LessonList />
                        </Route>
                        <Route path="/admin/edit-words">
                            <EditWords />
                        </Route>
                        <Route path="/admin/edit-timing/:lessonName">
                            <EditLessonTool />
                        </Route>
                        <Route path="/admin/resources-list">
                            <ResourceList />
                        </Route>
                        <Route path="/admin/edit-grammar">
                            <EditResources />
                        </Route>
                        <Route path="/admin/edit-multimedia">
                            <CreateMultimedia />
                        </Route>
                        <Route path="/admin/work-sheets">
                            <CreateWorksheet />
                        </Route>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Admin;
