import React, { useState } from 'react';
import tagParser from '../../TagParser';
import checkBox from '../../../public/Images/check-box.svg';
import checkedBox from '../../../public/Images/checked-box.svg';
import styles from './_styles/FilterSidebar.module.scss';
import Modal from './Modal';
import ButtonColor from '../UI-Components/ButtonColor';

interface Props {
    isOpen: boolean;
    toggleClose: CallableFunction;
    setFilter: CallableFunction;
}

/**
 * Component for the filter sidebar with options to select quarters and weeks.
 * @param {Props} props - The component props.
 * @return {JSX.Element} - The rendered component.
 */
const FilterSidebar = (props: Props): JSX.Element => {
    const { isOpen, toggleClose, setFilter } = props;
    const {
        weeks,
        quarters,
        getSubTagLetter,
        getTagString,
        currentWeekSubTag,
    } = tagParser();
    const [filterSelect, setFilterSelect] = useState<string[]>(['EM']);

    // Add a tag to the filter selection based on type (quarter or week) and index
    const addTag = (type: 'quarter' | 'week' | 'ZJ', index: number) => {
        if (type === 'ZJ') {
            setFilterSelect((prev) => [...prev, 'ZJ']);
        }
        if (type === 'quarter') {
            const tag = `${getSubTagLetter(quarters[0])}${index + 1}`;
            setFilterSelect((prev) => [...prev, tag]);
        }
        if (type === 'week') {
            const tag = `${getSubTagLetter(weeks[0])}${index}`;
            setFilterSelect((prev) => [...prev, tag]);
        }
    };

    // Remove tags from the filter selection based on the quantity to remove
    const removeTag = (removeQuantity: number) => {
        setFilterSelect((prev) => prev.slice(0, removeQuantity));
    };

    // Calculate the length of the filter selection
    const tagLength = filterSelect.length;
    const quarterTag = getTagString(filterSelect[1], 'Q', 'full');
    const weekTag = getTagString(filterSelect[2], 'W', 'full');
    const ZJmode = filterSelect[1] === 'ZJ';

    // console.log(filterSelect, ZJmode, 'testings');

    return (
        <Modal
            className={styles.filterSidebar}
            toggleClose={toggleClose}
            isOpen={isOpen}
        >
            <h4 className={styles.filterHeader}>Filter</h4>

            <div className={styles.filterList}>
                {/* Render checkboxes for quarters when only one tag is selected */}
                {tagLength === 1 && (
                    <>
                        {quarters.slice(0, 3).map((quarter, i) => (
                            <CheckBoxLine
                                key={i}
                                text={quarter}
                                isChecked={false}
                                handleClick={() => addTag('quarter', i)}
                            />
                        ))}
                        <CheckBoxLine
                            text={'Intermediate'}
                            isChecked={false}
                            handleClick={() => addTag('ZJ', 0)}
                        />
                    </>
                )}

                {ZJmode && (
                    <>
                        <CheckBoxLine
                            text={'Intermediate'}
                            isChecked={true}
                            handleClick={() => removeTag(1)}
                        />
                    </>
                )}

                {/* Render checkboxes for quarters and weeks when two tags are selected */}
                {tagLength === 2 && !ZJmode && (
                    <>
                        <CheckBoxLine
                            text={quarterTag}
                            isChecked={true}
                            handleClick={() => removeTag(1)}
                        />
                        <div className={styles.weekList}>
                            {weeks.slice(0, 6).map((week, i) => (
                                <CheckBoxLine
                                    key={i}
                                    text={week}
                                    isChecked={false}
                                    handleClick={() =>
                                        addTag(
                                            'week',
                                            Number(currentWeekSubTag(week)),
                                        )
                                    }
                                />
                            ))}
                        </div>
                    </>
                )}

                {/* Render checkboxes for quarters and selected week when three tags are selected */}
                {tagLength === 3 && (
                    <>
                        <CheckBoxLine
                            text={quarterTag}
                            isChecked={true}
                            handleClick={() => removeTag(1)}
                        />
                        <div className={styles.weekList}>
                            <CheckBoxLine
                                text={weekTag}
                                isChecked={true}
                                handleClick={() => removeTag(2)}
                            />
                        </div>
                    </>
                )}
            </div>
            {/* Apply button to apply the selected filter */}
            <ButtonColor
                width={'110px'}
                height={'36px'}
                color={'orange'}
                onClick={() => setFilter(filterSelect.join(' '))}
            >
                Apply
            </ButtonColor>
        </Modal>
    );
};

interface PropsCheckBox {
    text: string;
    isChecked: boolean;
    handleClick: CallableFunction;
}

/**
 * Component for rendering a checkbox line with text.
 * @param {PropsCheckBox} props - The component props.
 * @return {JSX.Element} - The rendered component.
 */
const CheckBoxLine = (props: PropsCheckBox): JSX.Element => {
    const { text, handleClick, isChecked } = props;

    return (
        <div className={styles.checkBoxLine} onClick={() => handleClick()}>
            {/* Render checkbox based on whether it is checked or not */}
            {isChecked ? (
                <img src={checkedBox} alt="checked-box" />
            ) : (
                <img src={checkBox} alt="unchecked-box" />
            )}
            {/* Render text with a selected style if the checkbox is checked */}
            <p className={isChecked && styles.selected}>{text}</p>
        </div>
    );
};

export default FilterSidebar;
