import React, { useState, useRef, useEffect } from 'react';
import LessonCard from './LessonCard';
import leftBtn from '../../../../public/Images/left-button.svg';
import rightBtn from '../../../../public/Images/right-button.svg';
import styles from './_styles/LessonCardContainer.module.scss';
import chevronRight from '../../../../public/Images/icon-chevron-right.svg';
import { Lesson, UserWord } from '../../../types/api_response';
import useViewport from '../../../customhooks/useViewport';

interface Props {
    lessonArr: Lesson[];
    userWords: UserWord[];
    featured: boolean;
    title?: string;
    setFilter?: CallableFunction;
    scrollToTop: CallableFunction;
}

const LessonCardContainer = (props: Props): JSX.Element => {
    const { title, lessonArr, userWords, featured, setFilter, scrollToTop } =
        props;
    const [scrollX, setScrollX] = useState<number>(0);
    const [width, setWidth] = useState<number>(840); /* temporary width 850 */
    const scrollRef = useRef<HTMLInputElement>(null);
    const { viewportWidth, mediaQuery } = useViewport();

    const scrollVal = featured ? width + 16 : 290;

    const scroll = (scrollOffset) => {
        scrollRef.current!.scrollLeft += scrollOffset;
        setScrollX(scrollX + scrollOffset);
    };

    useEffect(() => {
        const handleWindowResize = () => {
            setWidth(scrollRef.current!.offsetWidth - 20);
        };
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <div className={styles.cardContainerBody}>
            {title !== undefined && !featured && (
                <div className={styles.quarterHeader}>
                    <div className={styles.title}>
                        <h2>{title}</h2>
                        <p>{lessonArr.length} lessons</p>
                    </div>
                    <div className={styles.moreButton}>
                        <span
                            onClick={() => {
                                setFilter();
                                scrollToTop();
                            }}
                        >
                            more
                        </span>
                        <img src={chevronRight} />
                    </div>
                    <p
                        onClick={() => {
                            setFilter();
                            scrollToTop();
                        }}
                    >
                        See all
                    </p>
                </div>
            )}
            <div className={styles.cardContainerSlider}>
                <div ref={scrollRef} className={styles.contentContainer}>
                    {lessonArr.map((lesson, index) => {
                        if (index < 6) {
                            return (
                                <LessonCard
                                    key={lesson.lessonData.uuid}
                                    lesson={lesson}
                                    cardSize={true}
                                    userWords={userWords}
                                    featured={featured}
                                    minWidth={width}
                                />
                            );
                        }
                    })}
                </div>
                {lessonArr.length > 3 && viewportWidth > mediaQuery.mobile && (
                    <img
                        src={leftBtn}
                        className={styles.slideLeft}
                        onClick={() => scroll(-scrollVal)}
                    />
                )}
                {lessonArr.length > 3 && viewportWidth > mediaQuery.mobile && (
                    <img
                        src={rightBtn}
                        className={styles.slideRight}
                        onClick={() => scroll(+scrollVal)}
                    />
                )}
            </div>
        </div>
    );
};

export default LessonCardContainer;
